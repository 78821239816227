// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-badge-content {
  background: red;
}

.notify {
  color: grey;
}

.notify:hover {
  color: #5ea1d8;
}

.notify:active {
  color: #5ea1d8;
}

.notify:focus {
  color: #5ea1d8;
}

p {
  font-size: 28px;
  font-family: Lato, arial, sans-serif;
  color: rgb(60, 159, 194);
  font-weight: 400;
}

.div1 {
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  overflow: hidden;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-container {
  padding: 2rem 3rem;
}

@media (max-width: 767px) {
  .page-container {
    padding: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/bulb-guide/bulb-guide.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,WAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,cAAA;AAGF;;AADA;EACE,cAAA;AAIF;;AADA;EACE,eAAA;EACA,oCAAA;EACA,wBAAA;EACA,gBAAA;AAIF;;AADA;EACE,YAAA;EAEA,eAAA;EACA,OAAA;EACA,SAAA;EACA,WAAA;EACA,uBAAA;EACA,gBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAGF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE;IACE,aAAA;EAGF;AACF","sourcesContent":[".mat-badge-content {\n  background: red;\n}\n\n.notify {\n  color: grey;\n}\n.notify:hover {\n  color: #5ea1d8;\n}\n.notify:active {\n  color: #5ea1d8;\n}\n.notify:focus {\n  color: #5ea1d8;\n}\n\np {\n  font-size: 28px;\n  font-family: Lato, arial, sans-serif;\n  color: rgb(60, 159, 194);\n  font-weight: 400;\n}\n\n.div1 {\n  height: 50px;\n  //border: 1px solid blue;\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  background-color: white;\n  overflow: hidden;\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.page-container {\n  padding: 2rem 3rem;\n}\n\n@media (max-width: 767px) {\n  .page-container {\n    padding: 15px;\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
