import { Component, OnInit, HostListener } from "@angular/core";
import { Observable, of } from "rxjs";
import { FlowServiceService } from "../../Services/flow-service.service";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/Services/dataservice.service";
import { UserserviceService } from "src/app/Services/userservice.service";

import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-viewbyid",
  templateUrl: "./viewbyid.component.html",
  styleUrls: ["./viewbyid.component.scss"],
})
export class ViewbyidComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;

  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  showDefaultImage: boolean;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  profilePicture: string;

  UserName: string;
  userDisplayName: any;

  userData: any;

  scrHeight: any;
  scrWidth: any;
  SlideOptions: any;
  flowData: any;
  showvideo: boolean;
  showimage: boolean;
  imageURI: any;
  videoURI: any;
  displayedColumns: string[] = ["position", "name"];
  dataSource = [];
  dataSourceSteps = [];
  id: number;
  private sub: any;

  constructor(
    private flowServiceService: FlowServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private userserviceService: UserserviceService,
    private dataService: DataService,
    
  ) {
    // tslint:disable-next-line:no-string-literal
    this.flowData = this.route.snapshot.data["resolvedata"].Flows;
    this.dataSource = this.flowData.Components;
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.loadSteps(this.flowData.Steps);
  }

  ngOnInit() {
    // this.route.paramMap.subscribe(params => {
    //   this.id = +params.get('id');
    //   this.getFlowbyId(this.id);
    // });
    this.getProfileUser();
  }

  getFlowbyId(id): void {
    
    var email = "";

    if (this.userData != null) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      FlowId: id,
      userEmail: email
    });

    this.flowServiceService.getFlowById(flowQueryModel).subscribe((flows) => {
      this.flowData = flows.flows;
      this.dataSource = this.flowData.components;
      this.loadSteps(this.flowData.steps);
    });
  }

  loadSteps(steps) {
    steps.forEach((element) => {
      this.showVideoFromFile(element.Url);
      this.dataSourceSteps.push({
        title: element.title,
        url: element.url,
        description: element.description,
        id: element.rowKey,
        ShowImage: this.showimage,
      });
    });
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }

  showVideoFromFile(filePath: string) {
    if (filePath.includes(".mp4")) {
      this.showimage = false;
      this.showvideo = true;
      this.videoURI = filePath;
      this.imageURI = "";
    } else {
      this.showvideo = false;
      this.showimage = true;
      this.imageURI = filePath;
      this.videoURI = "";
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth >= 900) {
      this.SlideOptions = { items: 7, dots: false, nav: true };
    }
    if (this.scrWidth >= 700 && this.scrWidth < 900) {
      this.SlideOptions = { items: 4, dots: false, nav: true };
    }
    if (this.scrWidth >= 600 && this.scrWidth < 700) {
      this.SlideOptions = { items: 3, dots: false, nav: true };
    }
    if (this.scrWidth >= 479 && this.scrWidth < 600) {
      this.SlideOptions = { items: 2, dots: false, nav: true };
    }
    if (this.scrWidth < 479) {
      this.SlideOptions = { items: 2, dots: false, nav: true };
    }
  }
}
