import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import Chart from "chart.js/auto";
@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.scss"],
})
export class AdminDashboardComponent implements AfterViewInit {
  @ViewChild("likeCanvas") likeCanvas: ElementRef | undefined;
  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;
  inappropriateflow: any;
  flow: any;
  @ViewChild("pieCanvas") pieCanvas!: { nativeElement: any };
  tabIndex: any;
  FlowCount: any;
  noApprovedFlows: any;
  pieChart: any;
  doubleLineChart: any;
  @ViewChild("commentCanvas") commentCanvas!: { nativeElement: any };
  @ViewChild("bookmarkCanvas") bookmarkCanvas!: { nativeElement: any };
  @ViewChild("saleCanvas") saleCanvas!: { nativeElement: any };

  commentChart: any;
  likeChart: any;
  bookmarkChart: any;
  saleChart: any;
  constructor() { }

  ngAfterViewInit(): void {
    this.likesChartMethod();
    this.commentsChartBrowser();
    this.bookmarkChartMethod();
    this.saleChartMethod();
  }

  tabChanged(event){}

  likesChartMethod(): void {
    this.likeChart = new Chart(this.likeCanvas?.nativeElement, {
      type: "bar",
      data: {
        labels: ["FlowA ", "FlowB", "FlowC", "FLowD"],
        datasets: [
          {
            label: "Flow",
            data: [150, 250, 145, 100],


          },
        ],
      },

      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            position: "top",
            text: "LIKES",
            color: "#666",
            font: {
              size: 12,
            },
          },

          legend: {
            display: true,
            position: "bottom",
            labels: {
              color: "#999",
              font: {
                size: 14,
              },
            },
          },
        },
      },
    });
  }

  commentsChartBrowser(): void {
    this.canvas = this.commentCanvas.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    this.commentChart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: ["FlowA ", "FlowB", "FlowC", "FLowD"],
        datasets: [
          {
            backgroundColor: ["#2ecc71", "#3498db", "#95a5a6", "#9b59b6"],
            data: [124, 191, 33, 13],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            position: "top",
            text: "COMMENTS",
            color: "#666",
            font: {
              size: 12,
            },
          },

          legend: {
            display: true,
            position: "bottom",
            labels: {
              color: "#999",
              font: {
                size: 14,
              },
            },
          },
        },
      },
    });
  }

  bookmarkChartMethod(): void {

    this.canvas1 = this.bookmarkCanvas.nativeElement;
    this.ctx1 = this.canvas1.getContext("2d");

    this.bookmarkChart = new Chart(this.ctx1, {
      type: "bar",
      data: {
        labels: ["FlowA ", "FlowB", "FlowC", "FLowD"],
        datasets: [
          {
            backgroundColor: ["#2ecc71", "#3498db", "#95a5a6", "#9b59b6"],
            data: [57, 19, 3, 87],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            position: "top",
            text: "BOOKMARKS",
            color: "#666",
            font: {
              size: 12,
            },
          },

          legend: {
            display: true,
            position: "bottom",
            labels: {
              color: "#999",
              font: {
                size: 14,
              },
            },
          },
        },
      },
    });
  }

  saleChartMethod(): void {

    this.canvas1 = this.saleCanvas.nativeElement;
    this.ctx1 = this.canvas1.getContext("2d");

    this.saleChart = new Chart(this.ctx1, {
      type: "bar",
      data: {
        labels: ["FlowA ", "FlowB", "FlowC", "FLowD"],
        datasets: [
          {
            backgroundColor: ["#2ecc71", "#3498db", "#95a5a6", "#9b59b6"],
            data: [102, 89, 63, 97],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            position: "top",
            text: "SALES",
            color: "#666",
            font: {
              size: 12,
            },
          },

          legend: {
            display: true,
            position: "bottom",
            labels: {
              color: "#999",
              font: {
                size: 14,
              },
            },
          },
        },
      },
    });
  }

}
