// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  padding: 0rem 0rem;
  max-width: 1000px;
  margin: 0px auto;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: -2px 2px 7px rgba(200, 193, 193, 0.5);
}
.page-container .title {
  padding: 20px;
  flex: 2;
}
.page-container .des {
  padding: 20px;
  flex: 4;
  border-left: 1px solid #ddd;
  min-height: 85px;
}
.page-container .des p {
  margin: 0px !important;
}
.page-container .row {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: nowrap;
  padding: 0px 15px;
  margin: 0px auto;
  border-bottom: 1px solid #ddd;
}
.page-container .row:last-child {
  border: none;
}

@media (max-width: 767px) {
  .page-container .row {
    display: block !important;
  }
  .page-container .des {
    border: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdpartyliabraries/thirdpartyliabraries.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;EACA,iDAAA;AACJ;AAAI;EACI,aAAA;EAEA,OAAA;AACR;AAEI;EACI,aAAA;EACA,OAAA;EACA,2BAAA;EACA,gBAAA;AAAR;AAEQ;EACI,sBAAA;AAAZ;AAGI;EACI,aAAA;EACA,uBAAA;EAEA,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,6BAAA;AAFR;AAGQ;EACI,YAAA;AADZ;;AAMA;EAEQ;IACI,yBAAA;EAJV;EAMM;IACI,WAAA;EAJV;AACF","sourcesContent":[".page-container{\n    padding: 0rem 0rem;\n    max-width: 1000px;\n    margin: 0px auto;\n    border: 1px solid #ddd;\n    background: #fff;\n    box-shadow:-2px 2px 7px rgb(200 193 193 / 50%);\n    .title{\n        padding:20px;\n        \n        flex:2;\n        // border-bottom:1px solid #ddd;\n    }\n    .des{\n        padding:20px;\n        flex:4;\n        border-left:1px solid #ddd;\n        min-height:85px;\n        // border-bottom:1px solid #ddd;\n        p{\n            margin:0px !important;\n        }\n    }\n    .row{\n        display:flex;\n        align-items:flex-start;\n       \n        width:100%;\n        flex-wrap: nowrap;\n        padding:0px 15px;\n        margin:0px auto;\n        border-bottom:1px solid #ddd;\n        &:last-child{\n            border:none;\n        }\n    }\n}\n\n@media(max-width:767px){\n    .page-container{\n        .row{\n            display:block !important;\n        }\n        .des{\n            border:0px;\n        }\n    }  \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
