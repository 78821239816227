import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DataService {
  private isLoginSource = new BehaviorSubject(false);
  currentMessage = this.isLoginSource.asObservable();
  private currencySource = new BehaviorSubject("");
  currencyMessage = this.currencySource.asObservable();
  private databaseandcollection = new BehaviorSubject({});
  databaseandcollectionObject = this.databaseandcollection.asObservable();

  constructor() {}

  changeMessage(val: boolean) {
    this.isLoginSource.next(val);
  }
  changeCurrency(val: any) {
    this.currencySource.next(val);
  }
  Setdatabaseandcollection(val: {}) {
    this.databaseandcollection.next(val);
  }
}
