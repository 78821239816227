import { DataService } from "src/app/Services/dataservice.service";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { FlowQueryService } from "src/app/shared/flowquery.helper";
import { FlowQueryModel } from "src/app/models/flowquerymodel";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-addflowsin-bundle",
  templateUrl: "./addflowsin-bundle.component.html",
  styleUrls: ["./addflowsin-bundle.component.scss"],
})
export class AddflowsinBundleComponent implements OnInit {
  approvedFlows: any = [];
  tempFlows: any = [];
  userData: any;
  userDisplayName: any;
  userDisplayEmail: any;
  carouselOptions: any;
  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  profilePicture: string;
  approvedFlowslength: number;
  flows: any = [];
  input: string;
  selectedcategory: any;
  noResultReturned: boolean;
  information: string = "Only your public flows are displayed, as bundles can have only public flows.";
  private searchTerms = new Subject<string>();

  constructor(
    private flowServiceService: FlowServiceService,
    private router: Router,
    private dataService: DataService,
    private flowQueryService: FlowQueryService
  ) {
    this.approvedFlowslength = 0;
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    this.searchTerms
      .pipe(debounceTime(2000)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        localStorage.removeItem("pageIndex");
        localStorage.setItem("pageIndex", "0");
        this.performSearch(0);
      });

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userDisplayEmail = this.userData.emailAddress;
      this.email = this.userData.emailAddress;
      this.phone = this.userData.phoneNumber;
      this.info = this.userData.info;
      this.website = this.userData.website;
      this.uname = this.userData.userName;
      this.name = this.userData.firstName;
      this.getApprovedFlows();
    } else {
      this.router.navigate(["/login"]);
    }
  }

  performSearch(pageIndex: number) {
    if (this.input == "" || this.input == undefined || this.input == null) {
      this.getApprovedFlows();
    } else {

      this.approvedFlows = [];
      this.tempFlows = [];
      localStorage.removeItem("addbundlePageIndex");
      localStorage.setItem("addbundlePageIndex", "0");

      this.noResultReturned = true;
      this.email = "";

      if (this.userData != null) {
        this.email = this.userData.emailAddress;
      }

      var flowQueryModel = new FlowQueryModel({
        category: this.selectedcategory,
        searchString: this.input,
        userEmail: this.email,
        pageIndex: pageIndex
      });

      this.flowServiceService
        .searchInFlows(flowQueryModel)
        .subscribe((data) => {
          this.tempFlows = [];
          this.noResultReturned = false;
          console.log(data.flows);  
          if (data.flows != null && data.flows != undefined) {
            data.flows.forEach((element) => {
              this.tempFlows.push({ flow: element, isSelected: false });
            });
          }
        });
    }
  }

  getApprovedFlows(): void {
    this.approvedFlows = [];
    this.tempFlows = [];
    localStorage.removeItem("addbundlePageIndex");
    localStorage.setItem("addbundlePageIndex", "0");
    this.callServiceForApprovedFlows("0");
  }

  callServiceForApprovedFlows(pageIndex) {
    this.noResultReturned = true;

    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      pageIndex: pageIndex,
    });

    this.flowServiceService
      .getAllUserFlows(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        if (data.flows.length > 0) {
          data.flows.forEach((element) => {
            this.tempFlows.push({ flow: element, isSelected: false });
          });

          this.approvedFlowslength = this.tempFlows.length;
          this.approvedFlows = [];
          var flowCount = data.flows[0].flowCount;
          this.flows = this.tempFlows;

          let selectedFlows = JSON.parse(
            localStorage.getItem("addbundleselectedflows")
          );


          if (selectedFlows != null) {
            selectedFlows.forEach((element) => {

              let flow = this.tempFlows.filter(
                (f) => f.flow.rowKey == element.flow.rowKey
              )[0];

              if (flow != null && flow != undefined) flow.isSelected = true;
            });
          }

        }
      });
  }

  closeaddbundle() {
    var selectedFlows = this.tempFlows.filter((f) => f.isSelected);

    if (selectedFlows.length > 0) {
      localStorage.setItem(
        "addbundleselectedflows",
        JSON.stringify(selectedFlows)
      );
    } else {
    }
    var naviagatedToAddFlowsFrom = localStorage.getItem(
      "naviagatedToAddFlowsFrom"
    );
    if (naviagatedToAddFlowsFrom == "createbundle") {
      this.router.navigate(["/createbundle"]);
    } else if (naviagatedToAddFlowsFrom == "editbundle") {
      var bundleModel = JSON.parse(localStorage.getItem("createbundledetails"));
      this.router.navigate(["/editbundle/" + bundleModel.rowKey]);
    }
    localStorage.removeItem("naviagatedToAddFlowsFrom");
  }

  viewUserProfile(selectedFlow) {
    if (this.userData != null) {
      if (this.userData.emailAddress === selectedFlow.customerEmail) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedFlow);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  searchFlows() {
    this.searchTerms.next('');
  }

  searchTempFlows() {
    let searchFlows = [];
    if (this.input != "" && this.input != undefined) {
      this.tempFlows.forEach((flow) => {
        if (
          flow.Title.toLowerCase().includes(this.input.toLowerCase()) ||
          flow.UniqueCode.toLowerCase().includes(this.input.toLowerCase()) ||
          flow.UniqueCodeWithoutDot.toLowerCase().includes(
            this.input.toLowerCase()
          )
        ) {
          searchFlows.push(flow);
        }
      });
      this.tempFlows = searchFlows;
    }
  }

  flowClicked(flow) {
    localStorage.setItem("notificationflowId", null);
    //localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.removeItem("flow"); //, JSON.stringify(flow));
    localStorage.setItem("navigateTo", "grid");

    this.router.navigate(["/view/" + flow.rowKey]);
  }

  onApprovedScroll() {

    var pageIndex = localStorage.getItem("addbundlePageIndex");
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem("addbundlePageIndex", pageIndex);

    this.callServiceForApprovedFlows(pageIndex);
  }
}
