// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
}
.category .icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.category .description {
  padding-left: 1em;
}
.category .description .title {
  font-size: 0.9em;
  word-wrap: break-word;
}
.category .description .sender {
  font-size: 0.8em;
  word-wrap: break-word;
}
.category .new {
  position: absolute;
  right: 1em;
  font-size: 1.1em;
  word-wrap: break-word;
}

.img-div-left {
  position: fixed;
  z-index: 999;
  height: 150px;
  width: 150px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user-mention/user-mention.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAEI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;AAAR;AAEI;EACI,iBAAA;AAAR;AACQ;EACI,gBAAA;EACA,qBAAA;AACZ;AACQ;EACI,gBAAA;EACA,qBAAA;AACZ;AAEI;EACI,kBAAA;EACA,UAAA;EACA,gBAAA;EACA,qBAAA;AAAR;;AAMA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;AAHF","sourcesContent":[".category{\n    position: relative;\n    display: flex;\n    align-items: center;\n    padding: 5px;\n\n\n    .icon{\n        height: 30px;\n        width: 30px;\n        border-radius: 50%;\n    }\n    .description{\n        padding-left: 1em;\n        .title{\n            font-size: .9em;\n            word-wrap: break-word;\n        }\n        .sender{\n            font-size: .8em;\n            word-wrap: break-word;\n        }\n    }\n    .new{\n        position: absolute;\n        right: 1em;\n        font-size: 1.1em;\n        word-wrap: break-word;\n    }\n}\n\n\n\n.img-div-left{\n  position: fixed;\n  z-index: 999;\n  height: 150px;\n  width: 150px;\n  overflow: visible;\n  margin: auto;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
