// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paratext-wrap p {
  padding: 0px;
  font-size: 18px !important;
  line-height: 26px !important;
  -webkit-line-clamp: unset !important;
  text-align: left;
}
.paratext-wrap p:last-child {
  margin-bottom: 0px;
}

p {
  text-align: left !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/blogs/blogpages/paragraph/paragraph.component.scss"],"names":[],"mappings":"AAEI;EAAE,YAAA;EACE,0BAAA;EACA,4BAAA;EACA,oCAAA;EACA,gBAAA;AAAR;AACQ;EACI,kBAAA;AACZ;;AAGA;EACI,2BAAA;AAAJ","sourcesContent":[".paratext-wrap{\n    // margin-bottom:25px;\n    p{padding:0px;\n        font-size: 18px !important;\n        line-height: 26px !important;\n        -webkit-line-clamp: unset !important;\n        text-align: left;\n        &:last-child{\n            margin-bottom:0px;\n        }\n    }\n}\np{\n    text-align: left !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
