import {
  Component,
  OnInit,
  AfterViewInit,
  Renderer2,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "src/app/Services/dataservice.service";

@Component({
  selector: "carousel-app",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit, AfterViewInit {
  slideIndex = 1;
  parent = document.getElementsByClassName("mySlides");
  isPrevButtonDisplay: boolean;
  isNextButtonDisplay: boolean;
  aiArray;
  arrayUpdated = false;
  public newsdata: any[];
  public newschunk: any = [[]];
  lastClickedIndexi: any;
  lastClickedIndexj: any;
  constructor(
    private renderer: Renderer2,
    private httpclient: HttpClient,
    private dataService: DataService
  ) {
    this.isPrevButtonDisplay = false;
    this.isNextButtonDisplay = false;
  }
  @Input() inputElement: any[];
  @Input() inputElementChunk: any = [[]];
  @Output() someEvent = new EventEmitter<{
    projectId: string;
    ProjectName: string;
    Id: string;
  }>();

  getVstsProjectRepoPullRequests(projectId, ProjectName, Id, Jd): void {
    // this.someEvent.next({ projectId, ProjectName, Id });
    this.lastClickedIndexi = Id;
    this.lastClickedIndexj = Jd;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.showSlides(this.slideIndex);
  }

  showSlides(n) {
    if (this.inputElementChunk.length === n) {
      this.isPrevButtonDisplay = true;
      this.isNextButtonDisplay = false;
    } else if (n === 1) {
      this.isPrevButtonDisplay = false;
      this.isNextButtonDisplay = true;
    } else {
      this.isPrevButtonDisplay = true;
      this.isNextButtonDisplay = true;
    }

    let i;
    if (n > this.parent.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = this.parent.length;
    }

    for (i = 0; i < this.inputElementChunk.length; i++) {
      this.renderer.setStyle(this.parent[i], "display", "none");
    }
    this.renderer.setStyle(this.parent[this.slideIndex - 1], "display", "flex");
  }

  plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  getChunks(arr, size) {
    const chunkarray = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkarray.push(arr.slice(i, i + size));
    }
    return chunkarray;
  }
}
