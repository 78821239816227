import { Component, OnInit } from '@angular/core';
import { GetContentService } from 'src/app/Services/getcontent.service';
import { testcmsconfig, prodcmsconfig } from 'src/app/shared/cms/cmsconfig';
import { ContentQueryModel } from 'src/app/shared/cms/contentquerymodel';
import { ContentTypes } from 'src/app/shared/enums/content.types';

@Component({
  selector: 'app-pricingplan',
  templateUrl: './pricingplan.component.html',
  styleUrls: ['./pricingplan.component.scss']
})
export class PricingplanComponent implements OnInit {

  pricing: any = [];
  pricingPageObj: any;
  isLoading!: any;
  pageHeading!: string;
  tables: any = [];
  mainheaderRow: any;
  noResultReturned: boolean;

  constructor(
    private getcontentService: GetContentService
  ) { }


  async getPricingPageDetails() {
    this.noResultReturned = true;
    this.isLoading = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.Pricing,
      recaptchaValue: prodcmsconfig.environmentKey,
    });

    (await this.getcontentService.getContentByContentQueryModel(
      contentQueryModel
    )).subscribe((data) => { 
      this.pricing = data;
      this.tables = data[0].tablesModels; 
      //this.maintableRowModels = data[0].tablesModels[0].tableRowModels.splice(1, data[0].tablesModels[0].tableRowModels.length - 1);
      this.noResultReturned = false;
    });
  }

  ngOnInit(): void { 
    this.getPricingPageDetails();
  }
}
