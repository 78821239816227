import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-show-article-dialog",
  templateUrl: "./show-article-dialog.component.html",
  styleUrls: ["./show-article-dialog.component.scss"],
})
export class ShowArticleDialogComponent implements OnInit {
  articleToShow: any;

  constructor() {}

  ngOnInit(): void {
    this.articleToShow = JSON.parse(localStorage.getItem("selectedArticle"));
    }

  close(){
    
  }
}
