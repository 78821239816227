import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { Endpoint } from "../shared/endpoints";
import { environment } from "src/environments/environment";
import { FlowQueryModel } from "../models/flowquerymodel";

@Injectable({
  providedIn: "root",
})
export class BundleService {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }

  PostBundle(bundleModel, value) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    header.append("emailAddress", value);
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.saveBundleUrl, bundleModel, {
      headers: header,
    });
  }

  getBundleById(flowQueryModel: FlowQueryModel): Observable<any> {

    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
 

    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.getBundleByIdUrl,
      flowQueryModel,
      {
        headers: header,
      }
    );


  }

  reportBundle(bundleModel, value) {
    const header = new HttpHeaders();
    header.append("Accept", "application/json");
    header.append("Content-Type", "application/json");
    header.append("emailAddress", value);

    return this.http.post<any>(
      environment.apiBaseUrl + Endpoint.inAppropriateUrl,
      bundleModel,
      {
        headers: header,
      }
    );
  }
 
  deleteBundle(bundleModel): Observable<any> {
    const header = new HttpHeaders(); 
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.deleteBundleUrl, bundleModel, {
      headers: header,
    });
  }
 
  getBundles(userEmail, inputCategory, pageIndex): Observable<any> {
    let header = new HttpHeaders();
    header = header.append("userName", userEmail);
    header = header.append("inputCategory", inputCategory);
    header = header.append("pageIndex", pageIndex);

    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getBundleUrl, {
      headers: header,
    });
  }

  getBundlesByFlowId(flowModel, pageIndex): Observable<any> {
    let header = new HttpHeaders();  
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.getBundleByFlowIdUrl, flowModel ,{
      headers: header,
    });
  }

  getAllBundleLinkClicks(bundleModel): Observable<any> {
    const header = new HttpHeaders(); 
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.GetAllBundleLinkClicks, bundleModel, {
      headers: header,
    });
  }
  LikeBundle(flowModel): Observable<any> {
    const header = new HttpHeaders();
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.likeBundleUrl, flowModel, {
      headers: header,
    });
  }
}
