// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-popup button {
  background: cyan !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/welcomedialog/welcomedialog.component.scss"],"names":[],"mappings":"AACI;EACI,2BAAA;AAAR","sourcesContent":[".main-popup{\n    button{\n        background: cyan !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
