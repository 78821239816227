import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-embeded",
  templateUrl: "./embeded.component.html",
  styleUrls: ["./embeded.component.scss"],
})
export class EmbededComponent {
  flowData: any;
  flowCoverImageUrl: any;
  codeToEmbed: any;
  flowlink: any;

  constructor(public dialogRef: MatDialogRef<EmbededComponent>, private toastr: ToastrService) {

  }

  ngOnInit() { 
    this.flowData = JSON.parse(localStorage.getItem("flowsData")); 
    this.codeToEmbed = this.flowData.codeToEmbed;
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
    this.flowlink = window.location.href;
  }

  close() {
    this.dialogRef.close();
  }
  showToast(message) {
    this.toastr.success(message);
  }
}
