import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[excludeTranslation]'
})
export class ExcludeTranslationDirective {
    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.setAttribute('translate', 'no');
    }
}
