import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-publish-dialog",
  templateUrl: "./publish-dialog.component.html",
  styleUrls: ["./publish-dialog.component.scss"],
})
export class PublishDialogComponent implements OnInit {
  accessType: string;
  constructor() {}

  ngOnInit(): void {}
  publishAsPublic() {
    this.accessType = "Public";
    localStorage.setItem("AccessType", this.accessType);
  }
  publishAsPrivate() {
    this.accessType = "Private";
    localStorage.setItem("AccessType", this.accessType);
  }
  publishAsRestricted() {
    this.accessType = "Restricted";
    localStorage.setItem("AccessType", this.accessType);
  }
}
