export const testcmsconfig = {
  environmentKey: '2516981073965299524',
  applicationKey: '2516981074292049204',
  apikey: 'E9EB2AA411FF477BBF03FD2F891BB2FC',
  apiUserName: "FlowchestTest",
  apiUserPassword: "X$7aKp2#rL!9qZ8",
};

export const prodcmsconfig = {
  environmentKey: '2516981073965299524',
  applicationKey: '2516981074292049204',
  apikey: '45BE2F7C804946F18EA413ADE863B8DB',
  apiUserName: "Flowchest",
  apiUserPassword: "jR8$eHm2*pL7!sX1",
};

export const cmsendpointconfig = {
  baseAPiUrl: "https://swashcmsapi.azurewebsites.net/",
  getContentByContentQueryModel: 'api/content/getContentByQueryModel',
  getContentByRowKey: 'api/content/getContentByRowKey?rowKey=',
  getAuthToken: 'api/Authentication/getAuthToken',
  getContentByContentTitleQueryModel: 'api/content/getContentByContentTitleQueryModel',
};

