import { ImageDialogComponent } from "./../image-dialog/image-dialog.component";
import { Component, OnInit, HostListener } from "@angular/core";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { DataService } from "src/app/Services/dataservice.service";

import { UserserviceService } from "src/app/Services/userservice.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { ViewUserProfileQrCodeComponent } from "../view-user-profile-qr-code/view-user-profile-qr-code.component";
import { FlowQueryService } from "src/app/shared/flowquery.helper";
import { FlowQueryModel, UserQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-view-profile",
  templateUrl: "./view-profile.component.html",
  styleUrls: ["./view-profile.component.scss"],
})
export class ViewProfileComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;

  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  profilePicture: string;
  flows: any = [];
  approvedFlows: any = [];
  userData: any;
  showDefaultImage: boolean;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  SlideOptions: any;
  followButtonText: any;
  followerCount: number;
  followingCount: number;
  flow: any;
  noResultReturned: boolean;
  tempBundles: any = [];

  UserName: string;
  userDisplayName: any;
  userEmailAddress: any;
  loggedInEmailAddress: any;
  emailAddress: any;
  isthereAnyBundles: boolean;
  noBundles: boolean;
  flowCount: any;
  bundleCount: any;
  showName: boolean;
  showWebsite: boolean;
  showInfo: boolean;
  showPhone: boolean;
  showEmail: boolean;

  // tslint:disable-next-line: max-line-length
  constructor(
    private flowServiceService: FlowServiceService,
    private dataService: DataService,

    private router: Router,
    private userserviceService: UserserviceService,
    public dialog: MatDialog,
    private activateRouter: ActivatedRoute,
    private titleandmetaserviceService: TitleandmetaserviceService,
    private flowQueryService: FlowQueryService
  ) {
    this.titleandmetaserviceService.setTitle("View Other User Profile");
    this.flows = [];
    this.emailAddress = "";
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null && this.userData != undefined) {
      this.activateRouter.paramMap.subscribe((params) => {
        var uname = params.get("uname");

        if (uname != null && uname != undefined && uname.toString() != "") {
          this.userserviceService.getUserByUserName(uname).subscribe((data) => {
            this.userEmailAddress = data.user.emailAddress;
            this.emailAddress = data.user.emailAddress;
            this.loggedInEmailAddress = data.user.emailAddress;
            this.getFlows();
            this.getBundles();
            this.viewUserProfile();
          });
        } else {
          this.flowServiceService.setScrollTop();
          this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
          this.showNotificationCount = false;
          this.notificationCount = 4;

          if (this.userData != null) {
            this.userDisplayName = this.userData.firstName;
            this.loggedInEmailAddress = this.userData.emailAddress;
            this.getBundles();
          }

          window.scrollTo(0, 0);

          this.dataService.setFlowByCategorieschangeMessage.subscribe(
            (flowData) => {
              this.flow = flowData;
              if (
                this.flow.customerEmail != undefined &&
                this.flow.customerEmail != ""
              ) {
                this.emailAddress = this.flow.customerEmail;
              } else if (
                this.flow.notifierUserEmail != undefined &&
                this.flow.notifierUserEmail != ""
              ) {
                this.emailAddress = this.flow.notifierUserEmail;
              } else if (
                this.flow.customerEmail != "" &&
                this.flow.customerEmail != undefined
              ) {
                this.emailAddress = this.flow.customerEmail;
              } else if (
                this.flow.emailAddress != undefined &&
                this.flow.emailAddress != ""
              ) {
                this.emailAddress = this.flow.emailAddress; 
              } else if (
                this.flow.PartitionKey != undefined &&
                this.flow.PartitionKey != ""
              ) {
                this.emailAddress = this.flow.PartitionKey;
              }


              this.userEmailAddress = this.emailAddress;

              this.getFlows();
              this.getBundles();
              this.viewUserProfile();
            }
          );
        }
      });
    } else {
      this.router.navigate(["/login"]);
    }
  }

  getFlows(): void {
    this.flows = [];
    this.noResultReturned = true;
    //
    localStorage.removeItem("pageIndex");
    localStorage.setItem("pageIndex", "0");

    var flowQueryModel = new FlowQueryModel({
      category: "",
      searchString: "",
      userEmail: this.emailAddress,
      pageIndex: 0,
    });

    this.flowServiceService
      .getAllUserFlows(flowQueryModel)
      .subscribe((data) => {
        data.flows.forEach((flow) => {
          this.flows.push(flow);
        });
        this.noResultReturned = true;
      });
    this.noResultReturned = false;
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  onScrollDown() {
    this.noResultReturned = true;
    this.delay(2000).then(() => {
      this.delayedServiceCall();
    });
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem("pageIndex");
    var pageIndx = (parseInt(pageIndex) + 1);
    localStorage.setItem("pageIndex", pageIndx.toString());
    this.noResultReturned = true;

    var flowQueryModel = new FlowQueryModel({
      category: "",
      searchString: "",
      userEmail: this.emailAddress,
      pageIndex: pageIndx,
    });

    this.flowServiceService
      .getAllUserFlows(flowQueryModel)
      .subscribe((data) => {
        if (data.flows.length == 0) {
          this.noResultReturned = false;
        } else {
          this.noResultReturned = true;
          data.flows.forEach((flow) => {
            this.flows.push(flow);
          });
        }
        this.noResultReturned = false;
      });
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  followUser() {
    if (this.userData != null) {
      //
      this.noResultReturned = true;
      const flowModel = {
        UserEmail: this.userEmailAddress,
        FollowerEmail: this.loggedInEmailAddress,
      };
      this.flowServiceService.FollowUser(flowModel).subscribe((data) => {
        if (data.isFollowing === "following") {
          this.followButtonText = "Following";
        } else {
          this.followButtonText = "Follow";
        }
        this.noResultReturned = false;
        // 
      });
    } else {
      this.router.navigate(["/login"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }

  getProfileUser(): void {
    if (this.userData != null) {
      //
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {

            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }

  // flowClicked(flow) {
  //   localStorage.setItem("flow", JSON.stringify(flow));
  //   localStorage.setItem("notificationflowId", null);
  //   this.router.navigate(["/view/" + flow.Id]);
  // }

  viewUserProfile(): void {
    //
    this.noResultReturned = true;

    const flowQueryModel = new UserQueryModel();
    flowQueryModel.userEmail = this.loggedInEmailAddress;
    flowQueryModel.otherUserEmail = this.userEmailAddress
    console.log(this.userEmailAddress);
console.log(flowQueryModel);
    this.userserviceService
      .viewUserProfile(flowQueryModel)
      .subscribe((data) => {
        if (
          data.userData.profilePicUrl == "" ||
          data.userData.profilePicUrl == null
        ) {
          this.showDefaultImage = true;
        } else {
          this.showDefaultImage = false;
          this.profilePicture = data.userData.profilePicUrl;
        }
        this.email = data.userData.emailAddress;
        this.phone = data.userData.phoneNumber;
        this.info = data.userData.info;
        this.website = data.userData.website;
        this.uname = data.userData.userName;
        this.name = data.userData.firstName;
        this.flowCount = data.userData.flowCount;
        this.bundleCount = data.userData.bundlecount;

        this.showName = data.userData.showName;
        this.showWebsite = data.userData.showWebsite;
        this.showInfo = data.userData.showAboutYou;
        this.showPhone = data.userData.showPhone;
        this.showEmail = data.userData.showEmail;
        this.followButtonText = data.userData.isFollowing
          ? "Following"
          : "Follow";
        this.noResultReturned = false;
        //
      });
  }

  openDialog() {
    localStorage.setItem("profilePicture", JSON.stringify(this.profilePicture));
    this.dialog.open(ImageDialogComponent);
  }

  gotoUserQrCode() {
    localStorage.removeItem("otherUserEmailAddress");
    localStorage.removeItem("otherUseruname");
    localStorage.setItem("otherUserEmailAddress", this.emailAddress);
    localStorage.setItem("otherUseruname", this.uname);
    const dialogRef = this.dialog.open(ViewUserProfileQrCodeComponent, {
      panelClass: "dialoguebox",
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth >= 900) {
      this.SlideOptions = { items: 5, dots: false, nav: true };
    }
    if (this.scrWidth >= 700 && this.scrWidth < 900) {
      this.SlideOptions = { items: 4, dots: false, nav: true };
    }
    if (this.scrWidth >= 600 && this.scrWidth < 700) {
      this.SlideOptions = { items: 3, dots: false, nav: true };
    }
    if (this.scrWidth >= 479 && this.scrWidth < 600) {
      this.SlideOptions = { items: 2, dots: false, nav: true };
    }
    if (this.scrWidth < 479) {
      this.SlideOptions = { items: 2, dots: false, nav: true };
    }
  }
  showImage(itemCategory, item, index) {
    itemCategory.FlowModels.forEach((element) => {
      if ((element.arrow! = undefined)) {
        element.arrow = false;
      }
    });
    if (!itemCategory.showImg || index !== this.previousIndex) {
      this.currentImage = item.Url;
      this.previousIndex = index;
      itemCategory.showImg = true;
      item.arrow = true;
    } else {
      itemCategory.showImg = false;
      item.arrow = false;
    }
  }

  getBundles() {
    this.noBundles = true;
 
    var flowQueryModel = new FlowQueryModel({
      category: "",
      searchString: "",
      userEmail: this.emailAddress,
      pageIndex: 0,
    });
    console.log(flowQueryModel);
    this.flowServiceService
      .getUserPublicBundles(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        data.bundles.forEach((element) => {
          this.tempBundles.push(element);
          if (this.tempBundles.length > 0) {
            this.isthereAnyBundles = true;
          } 
          console.log(this.tempBundles);
        });
      });
  }

  flowClicked(flow) {
    localStorage.setItem("flow", JSON.stringify(flow));

    localStorage.setItem("notificationflowId", null);
    //localStorage.setItem("flow", JSON.stringify(flow));
    //  localStorage.removeItem("flow"); //, JSON.stringify(flow));
    localStorage.setItem("navigateTo", "grid");
    if (flow.type == "Bundle") {
      //this.isItBundle = true;
      this.router.navigate(["/viewbundle/" + flow.rowKey]);
    } else {
      //this.isItBundle = false;
      this.router.navigate(["/view/" + flow.rowKey]);
    }
  }
}
