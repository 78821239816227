import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { TokenService } from "./token.service";
import { catchError, tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

const OAUTH_CLIENT = "099153c2625149bc8ecb3e85e03f0022";
const OAUTH_SECRET = "express-secret";
const API_URL = "https://timitimsorg.azurewebsites.net/";
//const API_URL = "http://localhost:21255/";
const redirectUrl = "";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  redirectUrl = "";

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    AuthService.toast.error("Invalid Email or Password");
    return throwError("Something bad happened; please try again later.");
  }

  private static log(message: string): any {
  
  }

  static toast: any;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private toastr: ToastrService
  ) {
    AuthService.toast = toastr;
   }

  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set("username", loginData.UserName)
      .set("password", loginData.Password)
      .set("grant_type", "password");

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        grant_type: "password",
        username: loginData.UserName,
        password: loginData.Password,
        Authorization: "Basic " + btoa(OAUTH_CLIENT + ":" + OAUTH_SECRET),
      }),
    };

    return this.http.post<any>(API_URL + "/token", body, HTTP_OPTIONS).pipe(
      tap((res) => {
        this.tokenService.saveToken(res.access_token);
        this.tokenService.saveRefreshToken(res.refresh_token);
      }),
      catchError(AuthService.handleError)
    );
  }

  refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set("refresh_token", refreshData.refresh_token)
      .set("grant_type", "refresh_token");

    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        grant_type: "password",
        Authorization: "Basic " + btoa(OAUTH_CLIENT + ":" + OAUTH_SECRET),
      }),
    };

    return this.http.post<any>(API_URL + "/token", body, HTTP_OPTIONS).pipe(
      tap((res) => {
        this.tokenService.saveToken(res.access_token);
        this.tokenService.saveRefreshToken(res.refresh_token);
      }),
      catchError(AuthService.handleError)
    );
  }

  logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }

  register(data: any): Observable<any> {
    return this.http.post<any>(API_URL + "/signup", data).pipe(
      tap((_) => AuthService.log("register")),
      catchError(AuthService.handleError)
    );
  }

  secured(): Observable<any> {
    return this.http
      .get<any>(API_URL + "secret")
      .pipe(catchError(AuthService.handleError));
  }
}
