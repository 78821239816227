import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Endpoint } from "src/app/shared/endpoints";

@Component({
  selector: "app-activateaccount",
  templateUrl: "./activateaccount.component.html",
  styleUrls: ["./activateaccount.component.scss"],
})
export class ActivateaccountComponent implements OnInit {
  Code: string;
  EmailAddress: string;
  changePasswordForm: any;
  getCodeEmailAddress: string;
  getCodeForm: any;
  noResultReturned: boolean;

  constructor(
    public formbuilder: UntypedFormBuilder,
    private userserviceService: UserserviceService,
    private toastr: ToastrService,
    private router: Router
  ) {
    // this.changePasswordForm = new FormGroup({
    //   Code: new FormControl("", [Validators.required]),
    //   EmailAddress: new FormControl("", [
    //     Validators.required,
    //     Validators.email,
    //   ]),
    // });

    this.EmailAddress = localStorage.getItem("activateaccountemailaddress");
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formbuilder.group({
      Code: new UntypedFormControl("", Validators.required),
    });

    this.getCodeForm = this.formbuilder.group({
      getCodeEmailAddress: new UntypedFormControl("", [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  getCode() {
    this.noResultReturned = true;
    this.userserviceService
      .getActivationCode(this.getCodeEmailAddress)
      .subscribe((data) => {
        this.noResultReturned = false;
        if (data.result) {
         
          this.toastr.success(
            "Email with activation code is sent to your email address"
          );
        } else {
          this.noResultReturned = false;
          this.toastr.error(
            "Account with provided email address is not found. Please verify the email address and try again"
          );
        }
      });
  }

  signIn() {
    this.noResultReturned = true;
    
    var loginModel = {
      EmailAddress: this.EmailAddress,
      ChangePasswordHash: this.Code,
    };

    this.userserviceService
      .ActivateAccount(Endpoint.activateAccount, loginModel)
      .subscribe((data) => {
        if (data==true) { 
          this.toastr.success("Account is activated. please login");
          this.router.navigate(["/login"]);
          this.noResultReturned = false;
        } else { 
          this.noResultReturned = false;
          this.toastr.error(
            "Account with provided email address and activation code is not found. Please try again with valid email address and activation code "
          );
        }
      });
  }
}
