// flow-query.service.ts

import { Injectable } from '@angular/core';
import { FlowQueryModel } from '../models/flowquerymodel';

@Injectable({
  providedIn: 'root',
})
export class FlowQueryService {
  constructor() {}

  createFlowQueryModel(
    category: string = '',
    searchString: string = '',
    userEmail: string = '',
    pageIndex: number = 0,
    flowId: string = '',
    bundleId: string = ''
  ): FlowQueryModel {
    return new FlowQueryModel({
      category,
      searchString,
      userEmail,
      pageIndex,
      FlowId: flowId,
      BundleId: bundleId,
    });
  }
}
