import { ValidationComponent } from "./../validation/validation.component";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Component, HostListener, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationExtras } from "@angular/router";
import { DataService } from "../service/data.service";
import { ApiService } from "../service/apiservice.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  FormControlName,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { CustomValidators } from "src/app/custom-validators";
import { environment } from "src/environments/environment";
import { Endpoint } from "src/app/shared/endpoints";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
  showNotificationCount: boolean;
  notificationCount: number;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  isCapsLockPressed: boolean;
  name: any;
  maxdesclenght = 500;
  currentdesclenght = 0;
  registerForm: UntypedFormGroup;
  personModel: any = [];
  UserName: string;
  FullName: string;
  recaptchaReactive: string;
  EmailAddress: string;
  Password: string;
  ConfirmPassword: string;
  storageService: any;
  userNameTaken: boolean;
  userEmailTaken: boolean;
  emailTaken: boolean;
  cannotContainSpace: any;
  showDotMsg: boolean;
  fullStop: any;
  isEmailValid: boolean;

  registrationModel: {
    UserName: string;
    FirstName: string;
    EmailAddress: string;
    Password: string;
    ConfirmPassword: string;
    UserType:string;
  };
  isMobileView: boolean;
  noResultReturned: boolean;
  selectedValue: any;
  source: string = '';
  sources: string[] = [];

  constructor(
    private data: DataService,
    //public translate: TranslateService,
    public route: Router,
    private apiService: ApiService,
    private http: HttpClient,
    
    public formBuilder: UntypedFormBuilder,
    private router: Router,
    private userserviceService: UserserviceService
  ) {
    this.sources.push("Business User");
    this.sources.push("Content Creator");
    this.sources.push("User");
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      FullName: new UntypedFormControl("", Validators.required),
      checkbox: new UntypedFormControl(false, Validators.requiredTrue),
      UserName: new UntypedFormControl("", [
        this.validateUserName("UserName"),
        Validators.required,
      ]),

      EmailAddress: new UntypedFormControl("", [
        Validators.required,
        this.validateEmail("EmailAddress"),
        Validators.email,
      ]),
      Password: new UntypedFormControl(
        "",
        Validators.compose([
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          CustomValidators.patternValidator(
            /[!,@,#,$,%,^,&,*,-,_,:,;,",',<,>,`,~,{,},/,?,|,(,)]/,
            { hasSpecialCharacters: true }
          ),
          Validators.minLength(8),
        ])
      ),
      ConfirmPassword: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
        this.equalto("Password"),
      ]),
    });
    this.getProfileUser();

    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  async onSelectionChange(event: MatSelectChange) {
    this.selectedValue = event.value;
    this.source = this.selectedValue;
    console.log(this.source);
  }

  validateEmail(field_name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;

      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var result = re.test(input);
    
      const isValid = (this.isEmailValid = result);
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }

  equalto(field_name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;

      const isValid = control.root.value[field_name] === input;
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }

  resolved(captchaResponse: string) {
  
  }

  IsUserEmailTaken() {
    if (this.EmailAddress != "" && this.EmailAddress != null) {
      this.noResultReturned = true;
      //
      const loginModel = {
        UserEmail: this.EmailAddress, 
      };
      this.userserviceService.checkUserWithEmailAddressExists(loginModel)
        .subscribe((data) => {
          if (data == true) {
            this.userEmailTaken = true;
          }
          if (data == false) {
            this.userEmailTaken = false;
          }
          this.noResultReturned = false;

          //
        });
    }
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.resize();
  }

  resize() {}

  onResize(event) {
    this.resize();

    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }

  userNameChanged() {
    const loginModel = {
      EmailAddress: this.EmailAddress,
      UserName: this.UserName,
    };
    this.noResultReturned = true;
    //

    this.userserviceService.userNameChanged(loginModel).subscribe((data) => {
      if (data.isTaken === "Taken") {
        this.userNameTaken = true;
      }
      if (data.isTaken === "NotTaken") {
        this.userNameTaken = false;
      }

      // tslint:disable-next-line: align
      this.noResultReturned = false;

      //   
    });
  }
 
  SignUp() {
    this.noResultReturned = true;
    // 
    this.registrationModel = {
      UserName: this.UserName,
      FirstName: this.FullName,
      EmailAddress: this.EmailAddress,
      Password: this.Password,
      ConfirmPassword: this.ConfirmPassword,
      UserType: this.source
    };

    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

  
    this.userserviceService 
      .saveUser(Endpoint.registerUser, this.registrationModel)
      .subscribe((data) => {
        localStorage.setItem("activateaccountemailaddress", this.EmailAddress);
        this.router.navigate(["/activateaccount"]);
      });
  }

  eventHandler(event) {
    this.UserName = this.UserName.toLowerCase();
    if (event.code == "CapsLock") {
      this.isCapsLockPressed = true;
    } else {
      this.isCapsLockPressed = false;
    }
  }

  validateUserName(field_name): ValidatorFn {
    if (!this.isCapsLockPressed) {
      return (control: AbstractControl): { [key: string]: any } => {
        this.UserName = control.value;
        var count = 0;
        let regExp = /^[a-zA-Z0-9-_.]+$/;
        let isValid = regExp.test(this.UserName);
        let alphabets = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z";
        let upperCasealphabets =
          "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z";

        if (isValid) {
          if (
            this.UserName.length == 1 &&
            !alphabets.toLowerCase().includes(this.UserName.toLowerCase())
          ) {
            isValid = false;
          }
        }

        if (isValid) {
          if (this.UserName != "" && this.UserName != undefined) {
            count = (this.UserName.match(/\./g) || []).length;
            if (count > 1) {
              isValid = false;
            }
          }
        }

        if (isValid) {
          if (this.UserName != "" && this.UserName != undefined) {
            var lastLetter = this.UserName.charAt(this.UserName.length - 1);
            if (lastLetter == ".") {
              isValid = false;
            }
          }

          this.UserName = this.UserName.toLowerCase();
        }

        if (isValid) {
          var numbers = /^[0-9]+$/;
          if (this.UserName.match(numbers)) {
            isValid = false;
          }
        }

        if (!isValid) return { validateUserName: { isValid } };
        else return null;
      };
    } else {
      this.isCapsLockPressed = false;
    }
  }
}
