import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetContentService } from 'src/app/Services/getcontent.service';
import { TitleandmetaserviceService } from 'src/app/Services/titleandmetaservice.service';
import { prodcmsconfig, testcmsconfig } from 'src/app/shared/cms/cmsconfig';
import { ContentQueryModel } from 'src/app/shared/cms/contentquerymodel';
import { ContentTypes } from 'src/app/shared/enums/content.types';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class PreviewBlogComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  selectedBlogContent!: any;
  articalModel!: any;
  isLoading!: boolean;
  contentTitle: string;

  constructor(
    private activateRouter: ActivatedRoute,
    private getContentService: GetContentService,
    private titleandmetaserviceService: TitleandmetaserviceService,
  ) { }
  onScrollDown() { }
  ngOnInit(): void { 
    this.activateRouter.paramMap.subscribe((params) => { 
      var title = params.get('title'); 
      this.titleandmetaserviceService.setTitle(title);
      if (title == null) { 
        this.selectedBlogContent = JSON.parse(localStorage.getItem('selectedBlogContent'));
      } else {
        this.contentTitle = this.createTitleFromUrl(title);
        this.getBlogDetails(this.contentTitle);
      }
    });
  }

  createTitleFromUrl(url: string) {
    // Replace hyphens with spaces and capitalize each word
    return url.replace(/-/g, ' ');//.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
  }

  onRightClick($event: any) { }
  loadMoreblogs() { }

  async getBlogDetails(title: any) {
    this.noResultReturned = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: testcmsconfig.applicationKey,
      environmentKey: testcmsconfig.environmentKey,
      contentType: ContentTypes.Blogs,
      contentTitle: title,
    });

    (await this.getContentService.
      getContentByContentTitleQueryModel(contentQueryModel)).subscribe((data) => { 
        this.selectedBlogContent = data;
        this.noResultReturned = false; 
        this.articalModel = this.selectedBlogContent.articleModels[0]; 
        localStorage.removeItem('selectedBlogContent');
        localStorage.setItem('selectedBlogContent', JSON.stringify(this.articalModel));
        this.noResultReturned = false;
      });
  }
}