import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-bundledialog",
  templateUrl: "./bundledialog.component.html",
  styleUrls: ["./bundledialog.component.scss"],
})
export class BundledialogComponent implements OnInit {
  userData: any;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<BundledialogComponent>
  ) {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
  }

  categories() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.dialogRef.close('flow'); 
    }
  }

  bundle() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.dialogRef.close('bundle'); 
    }
  }
}
