import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { TokenService } from "./Services/token.service";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "./Services/auth.service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    private toast: ToastrService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    const token = this.tokenService.getToken();
    const refreshToken = this.tokenService.getRefreshToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: "Bearer " + token,
        },
      });
    }

    // if (!request.headers.has("Content-Type")) {
    //   request = request.clone({
    //     setHeaders: {
    //       "content-type": "application/json",
    //     },
    //   });
    // }

    // request = request.clone({
    //   headers: request.headers.set("Accept", "application/json"),
    // });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse)
          return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 401) {
          this.toast.error("Please re-login as your session expired");
          localStorage.clear();
          this.router
            .navigate(["grid"])
            .then((_) => { });
        }
        return throwError(error);
      })
    );
  }
}
