// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.masonary-parent {
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  background: #efeeee !important;
}

.masonry-item {
  max-width: 30%;
  margin: 5px;
  border: 1px solid #dddddd;
  background: #fff;
}

.masonry-item img {
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
  background: #fff;
  padding-bottom: 0px;
  color: #212427 !important;
}

.des {
  padding: 0px 10px 10px 10px;
  background: #fff;
}
.des > p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .masonry-item {
    max-width: 43%;
  }
}
@media (max-width: 560px) {
  .masonary-parent {
    padding: 2rem 15px;
  }
  .masonry-item {
    max-width: 90% !important;
    width: 93% !important;
  }
}
@media (max-width: 767px) {
  .des {
    padding: 0px 10px 10px 10px;
    background: #fff;
  }
  .des > p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 92px;
    position: relative;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/article/article.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,8BAAA;AACF;;AACA;EACE,cAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AADA;EACE,eAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AAIF;;AAFA;EACE,2BAAA;EACA,gBAAA;AAKF;AAJE;EACE,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;AAMJ;;AAHA;EACE;IACE,cAAA;EAMF;AACF;AAJA;EACE;IACE,kBAAA;EAMF;EAJA;IACE,yBAAA;IACA,qBAAA;EAMF;AACF;AAJA;EACE;IACE,2BAAA;IACA,gBAAA;EAMF;EALE;IACE,oBAAA;IACA,qBAAA;IACA,4BAAA;IACA,gBAAA;IACA,gBAAA;IACA,kBAAA;EAOJ;AACF","sourcesContent":[".masonary-parent {\n  padding: 2rem 3rem;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  margin: 0px auto;\n  background: #efeeee !important;\n}\n.masonry-item {\n  max-width: 30%;\n  margin: 5px;\n  border: 1px solid #dddddd;\n  background: #fff;\n}\n.masonry-item img {\n  width: 100%;\n}\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  padding: 10px;\n  background: #fff;\n  padding-bottom: 0px;\n  color:#212427 !important;\n}\n.des {\n  padding: 0px 10px 10px 10px;\n  background: #fff;\n  & > p {\n    display: -webkit-box;\n    -webkit-line-clamp: 5;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n  }\n}\n@media (max-width: 1024px) {\n  .masonry-item {\n    max-width: 43%;\n  }\n}\n@media (max-width: 560px) {\n  .masonary-parent {\n    padding: 2rem 15px;\n  }\n  .masonry-item {\n    max-width: 90% !important;\n    width: 93% !important;\n  }\n}\n@media (max-width: 767px) {\n  .des {\n    padding: 0px 10px 10px 10px;\n    background: #fff;\n    & > p {\n      display: -webkit-box;\n      -webkit-line-clamp: 5;\n      -webkit-box-orient: vertical;\n      overflow: hidden;\n      max-height: 92px;\n      position: relative;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
