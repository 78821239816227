import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { partition } from 'rxjs';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { DataService } from "src/app/Services/dataservice.service";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SelectionRephraseAIComponent } from '../selection-rephraseAi-dialog/selection-rephraseAi-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
}) 

export class FaqComponent implements OnInit {
  @Input() flowCoverPage:any;
  groupName: string = '';
  question: string = '';
  answer: string = '';
  groups: any[] = [];
  flows: any;
  userData: any;
  userDisplayName: any;
  noResultReturned: boolean;
 // flowCoverPage: any;

  constructor(private flowServiceService : FlowServiceService,
    private toastr: ToastrService,
    private dataService: DataService,
    private router: Router,
    private dialog:MatDialog,
  ) { }
  ngOnInit(): void {  
    // this.flowCoverPage = JSON.parse(
    //   localStorage.getItem("flowCoverPageDetails")
    // );

    // console.log(this.flowCoverPage); 
    this.getSupportFaqs();
  }

  // Add a new group to the list and call the API
  addGroup() {
    const newGroup = {
      groupName: this.groupName,
      question: this.question,
      answer: this.answer,
      partitionKey : this.flowCoverPage.rowKey,
      userEmail : this.flowCoverPage.partitionKey
    };

    // Clear the fields after adding
    this.groupName = '';
    this.question = '';
    this.answer = '';

    // Add the new group to the list (for local display)
    this.groups.push(newGroup);

    // Call the API to save the group
   // this.saveFaq(newGroup);
  }

  // Save the FAQ group using the API
  saveFaq(group: any) {  

    this.flowServiceService
    .addSupportFaq(group)
    .subscribe((data) => {
       if(data != null){

       }else{

       }
    });
  }

  getSupportFaqs( ) { 
    var flowQueryModel = new FlowQueryModel({
      FlowId: this.flowCoverPage.rowKey,
    });
    this.flowServiceService.getSupportFaq(flowQueryModel).subscribe((data) => {
      this.noResultReturned = false;
      console.log(data); 
      data.forEach((element:any)=>{
        this.groups.push({
          groupName : element.groupName,
          answer : element.answer,
          question : element.question,
          flowRowKey : element.partitionKey,
          userEmail:element.userEmail
        });
      });
      console.log(this.groups);
    }); 
  }
  // Remove a group from the list
  removeGroup(index: number) {
    this.groups.splice(index, 1);
  }

  // Edit an existing group
  editGroup(index: number, group: any) {
    this.groupName = group.groupName;
    this.question = group.question;
    this.answer = group.answer;

    this.removeGroup(index); // Remove the old entry while editing
  }

  // Done with adding all groups
  async done() {
    await this.saveFaq(this.groups);
    this.toastr.show('All FAQs saved successfully!');
  }

  close() {
    localStorage.setItem("flowFaqPageDetails", JSON.stringify(this.groups));
    this.router.navigate(["/create-flow"]);
  }
  originalContentTitleText!: string;
  repharaseButtonText: string = "Rephrase text with AI";
  isRephrased: boolean = false;
  isRephraseInProgress: boolean = false;
 
  GoToOriginalText() {
    this.answer = this.originalContentTitleText;
  }

  rephraseDescriptionText() {
    const dialogRef = this.dialog.open(SelectionRephraseAIComponent, {
      width: '400px',
      data: { textToRephrase: this.answer }  // Pass the text to the dialog
    });
    if (!this.isRephrased) {
      this.originalContentTitleText = this.answer;
      console.log(this.answer);
    }
    this.isRephraseInProgress = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the title with the rephrased text and show the selected style  
        this.answer = result.rephrasedText;
        console.log('Selected style:', result.selectedStyle);
        this.isRephrased = true;
        this.isRephraseInProgress = false;
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.groups,
      event.previousIndex,
      event.currentIndex
    );
  }
}