import { Component, Input, OnInit } from '@angular/core';

// Helpers

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss'],
})
export class PreviewAuthorComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  authorName: any;
  timeToRead: any;
  authorContact: any;
  authors!: any[];
  @Input() rowKey!: string;

  constructor() { }
  ngOnInit(): void {
    this.blog = JSON.parse(localStorage.getItem('selectedBlogContent'));
    this.authors = this.blog.authors;
    this.timeToRead = this.blog.timeToRead;
  }
  onRightClick($event: any) { }
  loadMoreblogs() { }
}
