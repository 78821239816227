export enum ContentTypes {
    Testimonials_Page = 'Testimonials Page',
    Testimonials = 'Testimonials',
    User_Roles = 'User Roles',
    FAQ_Page = 'FAQs Page',
    FAQ_Category  = 'FAQ Category',
    DocsPageLeftMenu = 'Doc page left menu',
    Main_Page = "Main Page",
    Blogs = "Blogs",
    Classes = 'Classes',
    Pricing = 'Pricing',
    API_Details= 'API Details',
    Welcome = 'Welcome',
    User_Guide = 'User Guide',
    Authentication = 'Authentication',
    About_Us='About Us',
    TnC='TermsnConditions',
    Why='Why',
    What='What',
    How='How',
    ExtraSection='ExtraSection',
    Community='Community'
  }
  