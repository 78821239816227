import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-user-mention",
  templateUrl: "./user-mention.component.html",
  styleUrls: ["./user-mention.component.scss"],
})
export class UserMentionComponent implements OnInit {
  allUsers: any;
  tempUsers: any;
  userClicked: any;
  input: string;
  userData: any;
  userDisplayName: any;
  userEmail: any;
  noResultReturned: boolean;

  constructor(
    
    private flowServiceService: FlowServiceService,
    private userserviceService: UserserviceService,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.flowServiceService.setScrollTop();
   
    //
    this.allUsers = [];
    this.tempUsers = [];
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    var filterByUserName = localStorage.getItem("filterByUserName");

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userEmail = this.userData.emailAddress;
    }

    localStorage.removeItem("searchuserpageIndex");
    localStorage.setItem("searchuserpageIndex", "0");

    this.getPaginatedUsers(undefined, "0");

    // this.userserviceService.getAllUsers().subscribe((data) => {
    //   if (data.AllUsers.length > 0) {
    //     data.AllUsers.forEach((element) => {
    //       const user = {
    //         user: element,
    //         showFollow: false,
    //         followButtonText: "",
    //         ProfilePicUrl: element.ProfilePicUrl,
    //         UserName: element.UserName,
    //         FirstName: element.FirstName,
    //         EmailAddress: element.EmailAddress,
    //       };
    //       this.allUsers.push(user);
    //     });

    //     this.tempUsers = this.allUsers;
    //     this.input = filterByUserName;
    //     this.searchUsers();
    //     this.tempUsers.forEach((element) => {});
    //   }
    //   this.noResultReturned = false;

    //  // 
    // });
  }

  getPaginatedUsers(infiniteScroll, pageIndex) {
    var email = "";

    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }
    
    var flowQueryModel = new FlowQueryModel({
      category: "",
      searchString: "",
      userEmail: email,
      pageIndex: pageIndex,
     });

    this.userserviceService
      .getAllUsers(flowQueryModel)
      .subscribe((data) => {
        
        if (data.allUsers.length > 0) {
          data.allUsers.forEach((element) => {
            const user = {
              user: element,
              showFollow: false,
              followButtonText: element.followingText,
              ProfilePicUrl: element.profilePicUrl,
              UserName: element.userName,
              FirstName: element.firstName,
              EmailAddress: element.emailAddress,
            };
            this.allUsers.push(user);
          });

          this.tempUsers = this.allUsers;
          this.tempUsers.forEach((element) => {
            if (element.followButtonText === "Following") {
              element.followButtonText = "Following";
              element.followingcolor = "black";
              element.followingbgcolor = "white";
            } else {
              element.followButtonText = "Follow";
              element.followingcolor = "white";
              element.followingbgcolor = "#5ea1d8";
            }
          });
        }
      });
  }

  itemClicked(item) {
    this.userClicked = item;
  }

  CheckIsUserFollowing(user) {
    if (this.userData != null) {
      if (this.userData.emailAddress === user.user.emailAddress) {
        user.showFollow = false;
      } else {
        user.showFollow = true;

        const flowModel = {
          CustomerEmail: user.user.emailAddress,
          UserEmailAddress: this.userData.emailAddress,
        };

        this.flowServiceService
          .CheckIsUserFollowing(flowModel)
          .subscribe((data) => {
            if (data.messageText === "Following") {
              user.followButtonText = "Following";
              user.followingcolor = "black";
              user.followingbgcolor = "white";
            } else {
              user.followButtonText = "Follow";
              user.followingcolor = "white";
              user.followingbgcolor = "#5ea1d8";
            }
          });
      }
    }
  }

  searchUsers() {
    let users = [];
    this.tempUsers = [];
    if (this.input == "" || this.input == undefined) {
      this.tempUsers = this.allUsers;
    } else {
      this.allUsers.forEach((user) => {
        if (
          user.user.userName != null &&
          user.user.firstName != null &&
          (user.user.userName.toUpperCase().includes(
            this.input.toUpperCase()
          ) ||
            user.user.firstName.toUpperCase().includes(
              this.input.toUpperCase()
            ))
        ) {
          users.push(user);
        }
      });

      this.tempUsers = users;
    }
  }

  viewUserProfile(selectedUser) {
    if (this.userData != null) {
      if (this.userData.emailAddress === selectedUser.emailAddress) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedUser);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  close(userName) {
    if (userName) {
      userName = null;
    }
  }
}
