import { FlowServiceService } from "src/app/Services/flow-service.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Endpoint } from "../shared/endpoints";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ContactUsService {
  httpClient: any;


  constructor(
    private http: HttpClient
  ) {}

  postContactUs(constactUsModel) { 
    const headers = new HttpHeaders();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    return this.http.post<any>(environment.apiBaseUrl + Endpoint.postContactUsUrl, constactUsModel, {
      headers,
    });
  } 
   
  getContactUsRequests(): Observable<any> {
    return this.http.get<any>(environment.apiBaseUrl + Endpoint.getContactUsRequestsUrl);
  }
}