import { Router } from "@angular/router";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";

import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FlowQueryService } from "src/app/shared/flowquery.helper";

@Component({
  selector: "app-followers",
  templateUrl: "./followers.component.html",
  styleUrls: ["./followers.component.scss"],
})
export class FollowersComponent implements OnInit {
  userData: any;
  userDisplayEmail: any;
  userName: string;
  firstName: string;
  profilePicUrl: string;
  getFollowerUrl: string;
  _allFollowersUsers: any[] = [];
  tempFollowers: any[] = [];
  userDisplayName: any;
  email: any;
  phone: any;
  info: any;
  website: any;
  name: any;
  showDefaultImage: boolean;
  profilePicture: any;
  uname: any;
  getFollowersUrl: string;
  tempFollowing: any[];
  categories: any;
  _allFollowingUsers = [];
  getFollowingUrl: string;
  loggedInUsername: string;
  approvedFlows: any = [];
  flows: any = [];
  tempFlows: any = [];
  followerCount: number;
  followingCount: number;
  isthereAnyFollowers: boolean;
  isthereAnyFollowing: boolean;
  tabIndex: any;
  followersTab: boolean;
  noResultReturned: boolean;

  ShowAboutYou: boolean;
  ShowEmail: boolean;
  ShowName: boolean;
  ShowWebsite: boolean;
  ShowPhone: boolean;

  constructor(
    private flowServiceService: FlowServiceService,
    private router: Router,
    private userserviceService: UserserviceService,
    private changeDetectorRef: ChangeDetectorRef,
    private dataService: DataService,
    
    public dialog: MatDialog,
    private flowQueryService: FlowQueryService
  ) {
    this.getFollowingUrl = "v1/UserController/getFollowingUsers";
  }
  ngOnInit() {
    this.flowServiceService.setScrollTop();
    this.tabIndex = localStorage.getItem("tabIndex");
    if (this.tabIndex.value === 0) {
      this.followersTab = true;
    } else {
      this.followersTab = false;
    }

    this.followerCount = 0;
    this.followingCount = 0;

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.loggedInUsername = this.userData.UserName;
    if (this.userData != null) {
      var flowQueryModel={
        UserEmail:this.userData.emailAddress
      }
      this._allFollowersUsers = [];
      this.tempFollowers = [];
      
      this.flowServiceService
        .getFollowerUsers(flowQueryModel)
        .subscribe((data: any) => { 
          if (data!= null && data.length > 0) {
            this.isthereAnyFollowers = true;
            data.forEach((element) => {
              this._allFollowersUsers.push({
                ProfilePicUrl: element.profilePicUrl,
                UserName: element.userName,
                FirstName: element.firstName,
                customerEmail: element.emailAddress,
                followButtonText:
                  element.isFollowing == true ? "Following" : "Follow",
              }); 
            });
            this.tempFollowers = this._allFollowersUsers;
          } else {
            this.isthereAnyFollowers = false;
          }
        });
    }

    this.getProfileUser();
    this.followingUsers();
    this.getApprovedFlows();
  }

  openImgDialog() {
    localStorage.removeItem("profilePicture");
    this.dialog.open(ImageDialogComponent, {
      data: {
        animal: "panda",
      },
    });
  }

  gotoUserQrCode(){
    localStorage.setItem("userprofileqrCode","followers");
    this.router.navigate(["/userQrCode"]);
  }
  
  openDialog() {}

  viewUserProfile(selectedUser) {
    if (this.userData != null) {
      if (
        this.userData.emailAddress === selectedUser.customerEmail ||
        this.userData.emailAddress === selectedUser.EmailAddress
      ) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedUser);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  getProfileUser() {
    
    this.userserviceService
      .getProfileUser()
      .subscribe((data) => { 
        if (
          data.userData.profilePicUrl === "" ||
          data.userData.profilePicUrl == null
        ) {
          this.showDefaultImage = true;
        } else {
          this.showDefaultImage = false;
          this.profilePicture = data.userData.profilePicUrl;
        }
        this.email = data.userData.emailAddress;
        this.phone = data.userData.phoneNumber;
        this.info = data.userData.info;
        this.website = data.userData.website;
        this.uname = data.userData.userName;
        this.name = data.userData.firstName;
        this.followingCount = data.userData.followingCount;
        this.followerCount = data.userData.followerCount;

        // this.getFollowers();
      });
  }

  gotoProfile() {
    this.router.navigate(["/user-profile"]);
  }

  getApprovedFlows() {
    
    this.approvedFlows = [];
    this.tempFlows = [];

    const flowQueryModel = this.flowQueryService.createFlowQueryModel(
      '',
      this.userData.emailAddress,
      '',
      0,
      '',
      ''
    );

    this.flowServiceService
      .getApprovedFlows(flowQueryModel)
      .subscribe((data) => {
        if (data.flows.length > 0) {
          
          data.flows.forEach((element) => {
            this.approvedFlows.push(element);
          });

          this.flows = this.approvedFlows;
        }
      });
  }

  follow(user, isFollowing) {
    if (this.userData != null) {
      var flowModel = null; 
      // if (isFollowing) {
      //   flowModel = {
      //     FollowerEmail: user.customerEmail,
      //     UserEmailAddress: this.userData.emailAddress,
      //   };
      // } else {
      //   flowModel = {
      //     FollowerEmail: this.userData.emailAddress,
      //     UserEmailAddress: user.customerEmail,
      //   };
      // }

      flowModel = {
        FollowerEmail: this.userData.emailAddress,
        UserEmail: user.customerEmail,
      }; 
      this.flowServiceService.FollowUser(flowModel).subscribe((data) => {
        if (data.isFollowing == "following") {
          user.followButtonText = "Following";
        } else if (data.isFollowing == "follow") {
          user.followButtonText = "Follow";
        }
      });
    }
  }

  followingUsers() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    if (this.userData != null) {
      this._allFollowingUsers = [];
      this.tempFollowing = [];

      var flowQueryModel={
        UserEmail:this.userData.emailAddress
      }
      // 
      this.noResultReturned = true;
      this.flowServiceService
        .getFollowingUsers(flowQueryModel)
        .subscribe((data) => { 
          this.noResultReturned = false;
          if (data != null && data.length > 0) {
            this.isthereAnyFollowing = true;
            data.forEach((element) => {
              this._allFollowingUsers.push({
                showFollow: false,
                followButtonText: "Following",
                profilePicUrl: element.profilePicUrl,
                userName: element.userName,
                firstName: element.firstName,
                customerEmail: element.emailAddress,
              }); 
            });
            this.tempFollowing = this._allFollowingUsers; 
          } else {
            this.isthereAnyFollowing = false;
          }
        });
    }
  }
}
