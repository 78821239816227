import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VouchercodeComponent } from '../vouchercode/vouchercode.component';
import { Router } from '@angular/router';
import { SupportDocumentComponent } from '../support-document/support-document.component';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';

@Component({
  selector: 'app-coupon-code',
  templateUrl: './coupon-code.component.html',
  styleUrls: ['./coupon-code.component.scss']
})
export class CouponCodeComponent implements OnInit {
  flowData: any;
  vouchercodes: any;
  /**
   *
   */
  constructor(public dialog: MatDialog,
    public router: Router,
    private flowService: FlowServiceService) {

  }
  userData: any;
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser")); 
    this.flowData = JSON.parse(localStorage.getItem("flowData")); 
    this.getUsersVoucherCodes();
  }


  getUsersVoucherCodes() {
    var UserEmail = '';

    if (this.userData != null && this.userData != undefined) {
      UserEmail = this.userData.emailAddress;
    } 
 
    var loginModel = new FlowQueryModel({ 
      userEmail: UserEmail
    });

    this.flowService
      .getUsersVoucherCodes(loginModel)
      .subscribe((data:any) => {
        this.vouchercodes = data; 
      });
  } 
  onChatClick() {
    if (this.userData == null) {
      this.router.navigate(["/login"]);
    } else {
      //this.router.navigate(["/in-Appropriate"]);
      localStorage.setItem("flowData", JSON.stringify(this.flowData));
      const dialogRef = this.dialog.open(VouchercodeComponent, {
        panelClass: "dialoguebox",
      });
      dialogRef.afterClosed().subscribe((result) => { 
        dialogRef.close();
        this.getUsersVoucherCodes();
      });
    }
  }
  // onChatClick() { 
  //   if (this.userData != null) {
  //     localStorage.setItem("flow", JSON.stringify(this.flowData));
  //     const dialogRef = this.dialog.open(VouchercodeComponent, {
  //       panelClass: "dialoguebox",

  //     }); 
  //     dialogRef.afterClosed().subscribe((result) => { 
  //     });
  //   } else {
  //     this.router.navigate(["/login"]);
  //   }
  // }
}
