import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Console } from "console";
import { FlowServiceService } from "src/app/Services/flow-service.service";

@Component({
  selector: "app-support-faqs",
  templateUrl: "./support-faqs.component.html",
  styleUrls: ["./support-faqs.component.scss"],
})
export class SupportFaqComponent implements OnInit {
  flowCoverImageUrl: any;
  flowData: any;
  dataService: any;
  imageSource: any;
  description: any;
  isPurchaseRequired: any;
  userData: any;
  LoggedInUser: null;
  groupedFaqs: { groupName: string; faqs: any; }[];
  flowDataDetails: any;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<SupportFaqComponent>,
    private flowservice: FlowServiceService
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser")); 
    this.flowData = JSON.parse(localStorage.getItem("supportFaq")); 
    this.flowDataDetails = JSON.parse(localStorage.getItem("flowData")); 
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
    console.log(this.flowData);
    this.groupFaqsByGroupName();
  }

  groupFaqsByGroupName(): void {
    if (this.flowDataDetails.isPaid 
      && this.flowDataDetails.isFlowPurchased==false 
      &&this.userData.emailAddress !== this.flowDataDetails.customerEmail) { 
        localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
        this.router.navigate(["/checkout"]); 
    }else{
      const groups = this.flowData.reduce((acc:any, faq:any) => {
        const groupName = faq.groupName;
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(faq);
        return acc;
      }, {});
  
      this.groupedFaqs = Object.keys(groups).map(groupName => ({
        groupName: groupName,
        faqs: groups[groupName]
      }));
  
      console.log(this.groupedFaqs);
      console.log(groups);
    } 
  }
 
  close() {
    this.dialogRef.close();
  }
}
