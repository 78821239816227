import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { UserserviceService } from "src/app/Services/userservice.service";

import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { YesnodialogComponent } from "../yesnodialog/yesnodialog.component";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { ToastrService } from "ngx-toastr";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { environment } from "src/environments/environment";
import { Endpoint } from "src/app/shared/endpoints";
import { ShowFlowImageDialogComponent } from "../show-flow-image-dialog/show-flow-image-dialog.component";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  urllink: string = "assets/images/2.png";

  showNotificationCount: boolean;
  notificationCount: number;
  isdetailschange: boolean;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;

  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  loggedInUserEmail: string;
  loggedInUserUname: string;

  showAboutYou: boolean;
  showEmail: boolean;
  showName: boolean;
  showPhone: boolean;
  showWebsite: boolean;
  showUname: boolean;

  userNameTaken: boolean;
  userEmailTaken: boolean;

  fileData: File = null;
  selectedSource: string;
  flows: any = [];
  source: string = '';
  sources: string[] = [];
  editProfileForm: any;
  noResultReturned: boolean;
  selectedValue: any;
  userType: any;
  constructor(
    public formbuilder: UntypedFormBuilder,
    private userserviceService: UserserviceService,
    private http: HttpClient,

    public route: Router,
    private router: Router,
    public dialog: MatDialog,
    private flowServiceService: FlowServiceService,
    private toastr: ToastrService,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.titleandmetaserviceService.setTitle("Edit Profile");
    this.sources.push("Business User");
    this.sources.push("Content Creator");
    this.sources.push("User");
  }

  ngOnInit() {
    this.flowServiceService.setScrollTop();
    this.noResultReturned = true;
    // 
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showNotificationCount = false;
    this.notificationCount = 4;
    this.noResultReturned = false;
    // 
    if (this.userData != null) {
    }
    {
      this.userDisplayName = this.userData.firstName;
    }

    this.editProfileForm = this.formbuilder.group({
      Name: new UntypedFormControl("", Validators.required),
      UserName: new UntypedFormControl("", [
        this.validateUserName("UserName"),
        Validators.required,
      ]),
      EmailAddress: new UntypedFormControl("", [
        Validators.required,
        Validators.email,
      ]),
      AboutYou: new UntypedFormControl("", Validators.required),
      Website: new UntypedFormControl("", Validators.required),
      Phone: new UntypedFormControl("", Validators.required),
    });

    this.flows = [];
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    // if (this.userData != null) {
    //   this.getEditProfile();
    // }

    this.getProfileUser();
  }
  async onSelectionChange(event: MatSelectChange) {
    this.selectedValue = event.value;
    this.source = this.selectedValue;
    console.log('Selected source:', this.selectedSource);
    console.log(this.source);
  }
  userNameChanged() {
    if (this.uname != this.loggedInUserUname) {
      const loginModel = {
        EmailAddress: this.email,
        UserName: this.uname,
      };
      this.noResultReturned = true;

      // 

      this.userserviceService.userNameChanged(loginModel).subscribe((data) => {
        this.noResultReturned = false;
        if (data.messageCode === "Taken") {
          this.userNameTaken = true;
        }
        if (data.messageCode === "NotTaken") {
          this.userNameTaken = false;
        }

        // tslint:disable-next-line: align
        // 
      });
    }
  }

  IsUserEmailTaken() {
    if (this.email != this.loggedInUserEmail) {
      this.noResultReturned = true;
      //  
      this.userserviceService
        .getUserWithEmailAddressExists(this.email)
        .subscribe((data) => {
          this.noResultReturned = false;

          if (data.messageCode === "Taken") {
            this.userEmailTaken = true;
          }
          if (data.messageCode === "NotTaken") {
            this.userEmailTaken = false;
          }

          //  
        });
    }
  }

  validateUserName(field_name): ValidatorFn {
    //if (!this.isCapsLockPressed) {
    return (control: AbstractControl): { [key: string]: any } => {
      this.uname = control.value;
      var count = 0;
      let regExp = /^[a-zA-Z0-9-_.]+$/;
      let isValid = regExp.test(this.uname);
      let alphabets = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z";
      let upperCasealphabets =
        "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z";

      if (isValid) {
        if (
          this.uname.length == 1 &&
          !alphabets.toLowerCase().includes(this.uname.toLowerCase())
        ) {
          isValid = false;
        }
      }

      if (isValid) {
        if (this.uname != "" && this.uname != undefined) {
          count = (this.uname.match(/\./g) || []).length;
          if (count > 1) {
            isValid = false;
          }
        }
      }

      if (isValid) {
        if (this.uname != "" && this.uname != undefined) {
          var lastLetter = this.uname.charAt(this.uname.length - 1);
          if (lastLetter == ".") {
            isValid = false;
          }
        }

        this.uname = this.uname.toLowerCase();
      }

      if (isValid) {
        var numbers = /^[0-9]+$/;
        if (this.uname.match(numbers)) {
          isValid = false;
        }
      }

      if (!isValid) return { validateUserName: { isValid } };
      else return null;
    };
    // } else {
    //   this.isCapsLockPressed = false;
    // }
  }

  goToUserProfilePage(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.editProfileForm.dirty) {
        resolve(true);
        this.router.navigate(["/user-profile"]);
        return;
      }

      const dialogRef = this.dialog.open(YesnodialogComponent, {
        width: "250px",
        data: { name: this.name },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === "okclicked") {
        } else {
          this.router.navigate(["/user-profile"]);
        }
      });
    });
  }

  saveProfileChanges() {
    this.noResultReturned = true;
    //

    const customerModel = {
      EmailAddress: this.email,
      PhoneNumber: this.phone,
      Info: this.info,
      Website: this.website,
      UserName: "@" + this.uname,
      FirstName: this.name,
      ProfilePicUrl: this.profilePicture,
      ShowAboutYou: this.showAboutYou,
      ShowEmail: this.showEmail,
      ShowName: this.showName,
      ShowWebsite: this.showWebsite,
      ShowPhone: this.showPhone,
      ShowUname: this.showUname,
      UserType:this.source
    };
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    this.userserviceService
      .UpdateCustomerProfileDetails(Endpoint.customerModel, customerModel)
      .subscribe(
        (data) => {
          this.noResultReturned = false; 
          this.router.navigate(["/user-profile"]);
          this.showToast();
        },
        (error) => { }
      );
  }

  showToast() {
    this.toastr.success(
      "Profile updated successful. It might take few minutes to reflects across the site"
    );
  }

  savePreviewChanges() {
    //
    this.noResultReturned = false;
    const customerModel = {
      EmailAddress: this.email,
      PhoneNumber: this.phone, 
      Info: this.info,
      Website: this.website,
      UserName: this.uname,
      FirstName: this.name,
      ProfilePicUrl: this.profilePicture,
      ShowAboutYou: this.showAboutYou,
      ShowEmail: this.showEmail,
      ShowName: this.showName,
      ShowWebsite: this.showWebsite,
      ShowPhone: this.showPhone,
      ShowUname: this.showUname,
    };

    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    this.userserviceService
      .UpdateCustomerProfileDetails(
        Endpoint.customerModel,
        customerModel
      )
      .subscribe(
        (data) => {
          this.noResultReturned = false;
          //
          this.router.navigate(["/preview-page"]);
        },
        (error) => { }
      );
  }

  preview() {
    const customerModel = {
      EmailAddress: this.email,
      PhoneNumber: this.phone,
      Info: this.info,
      Website: this.website,
      UserName: this.uname,
      FirstName: this.name,
      ProfilePicUrl: this.profilePicture,
      ShowAboutYou: this.showAboutYou,
      ShowEmail: this.showEmail,
      ShowName: this.showName,
      ShowWebsite: this.showWebsite,
      ShowPhone: this.showPhone,
    };

    localStorage.setItem("customerModel", JSON.stringify(customerModel));
    this.router.navigate(["/preview-page"]);
    //this.router.navigate(["preview-page", customerModel]);
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    console.log("getProfileUser");
    if (this.userData != null) {
      this.noResultReturned = true;
      //
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          this.noResultReturned = false;
          //
          // var customerModel = JSON.parse(localStorage.getItem("customerModel"));
          console.log("getProfileUser", data);
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.loggedInUserEmail = this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.loggedInUserUname = this.uname =
            data.userData.userName.substring(
              1,
              data.userData.userName.length
            );

          this.name = data.userData.firstName;
          this.profilePicture = data.userData.profilePicUrl;
          this.source= data.userData.userType
          this.showAboutYou = data.userData.showAboutYou;
          this.showEmail = data.userData.showEmail;
          this.showName = data.userData.showName;
          this.showWebsite = data.userData.showWebsite;
          this.showPhone = data.userData.showPhone;
          console.log(this.source);
        });
    }
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/create-flow"]);
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }

  getEditProfile(): void {
    var customerModel = JSON.parse(localStorage.getItem("customerModel"));

    if (customerModel == null) {
      this.noResultReturned = true;
      //  
      this.userserviceService
        .getEditProfile()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl == null ||
            data.userData.profilePicUrl === ""
          ) {
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          //    
          this.noResultReturned = false;

          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;

          this.showAboutYou = data.userData.showAboutYou;
          this.showEmail = data.userData.showEmail;
          this.showName = data.userData.showName;
          this.showWebsite = data.userData.showWebsite;
          this.showPhone = data.userData.showPhone;
        });
    }
  }

  setProfileData(customerModel) {
    this.email = customerModel.emailAddress;
    this.phone = customerModel.phoneNumber;
    this.info = customerModel.info;
    this.website = customerModel.website;
    this.uname = customerModel.userName;
    this.name = customerModel.firstName;
    this.profilePicture = customerModel.profilePicUrl;
    this.showAboutYou = customerModel.showAboutYou;
    this.showEmail = customerModel.showEmail;
    this.showName = customerModel.showName;
    this.showWebsite = customerModel.showWebsite;
    this.showPhone = customerModel.showPhone;
    this.showUname = customerModel.showUname;
    localStorage.removeItem("customerModel");
  }

  removePicture() {
    this.profilePicture = null;
  }

  selectFiles(event) {
    if (event.target.files) {
      this.noResultReturned = true;
      //
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append("fileData", this.fileData);
      this.http
        .post(
          environment.apiBaseUrl + environment.uploadImageUrl,
          formData
        )
        .subscribe((res) => {
          this.profilePicture =
            "https://timitimsorg-staging.azurewebsites.net/UserImages/" +
            this.fileData.name;
          // 
          this.noResultReturned = false;
          var mediaUploadStatus = res as any;
          this.noResultReturned = false;
          //
          if (mediaUploadStatus != null) {
            this.profilePicture =
              "https://flowcheststorage.blob.core.windows.net/mediaimage/" +
              mediaUploadStatus.fileName;
          } else {
            alert(mediaUploadStatus.message);
          }
        });
    }
  }
  openImage(Url) {
    localStorage.setItem("itemsRequiredImageToShow", Url);

    this.dialog.open(ShowFlowImageDialogComponent, {});
  }
}
