import { EventEmitter, Injectable } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";

@Injectable({
  providedIn: "root",
})
export class EventEmitterService {
  invokeComponentFunction = new EventEmitter();
  loginCompleted: Subscription;

  setProfilePictureCompleted: Subscription;
  setProfilePictureFunction = new EventEmitter();

  setNotificationCompleted: Subscription;
  setNotificationFunction = new EventEmitter();
  setHomePageIconColorFunctionCompleted: Subscription;
  setHomePageIconColorFunction = new EventEmitter();

  setUserProfileCreateIconClickedFunction = new EventEmitter();
  setUserProfileCreateIconClickedEventCompleted:Subscription;
  constructor() {}

  callEvent() {
    this.invokeComponentFunction.emit();
  }

  setProfilePictureEvent(imgUrl) {
    this.setProfilePictureFunction.emit(imgUrl);
  }

  setNotificationDetailsEvent(notificationModel) {
    this.setNotificationFunction.emit(notificationModel);
  }

  setHomePageIconColorEvent(pagename) {
    this.setHomePageIconColorFunction.emit(pagename);
  }

  setUserProfileCreateIconClickedEvent(pagename) {
    this.setUserProfileCreateIconClickedFunction.emit(pagename);
  }
}
