import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8"
    })
  };
  apiUrl: string = "http://localhost:57100/api/";
  constructor(private http: HttpClient) {}


  ImportFile(
    fileName: string,
    databaseName: string,
    collectionName: string
  ): Observable<any> {
    return this.http.get<any>(
      this.apiUrl +
        "Api/Upload?fileName=" +
        fileName +
        "&databaseName=" +
        databaseName +
        "&collectionName=" +
        collectionName,
      this.httpOptions
    );
  }
}
