import { Component, OnInit } from "@angular/core";
// import { MycurrencyPipe } from "../pipes/mycurrency.pipe";
import { SessionService } from "../service/session.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-employee",
  templateUrl: "./employee.component.html",
  styleUrls: ["./employee.component.scss"]
})
export class EmployeeComponent implements OnInit {
  // today = new Date();
  // normalVal: number = 12345678912;
  // val: string = "1000000";

  emailFormControl = new UntypedFormControl("", [
    Validators.required,
    Validators.email
  ]);
  constructor(
    //public translate: TranslateService // public myCurrencyPipe: MycurrencyPipe,
  ) // private session: SessionService
  {}

  ngOnInit() {
    // this.translate.setDefaultLang('tr');
    // this.translate.use('tr');
  }

  // norway() {
  //   this.session.registerCulture("fr-CH");
  //   this.refreshValues();
  //   this.formateCurrency("CFP", "fr-CH");
  // }

  // sweden() {
  //   this.session.registerCulture("en-GB");
  //   this.refreshValues();
  //   this.formateCurrency("GBP", "en-GB");
  // }

  // private refreshValues() {
  //   this.today = new Date();
  //   // this.normalVal++;
  // }

  // formateCurrency(currencyCode: string, locale: string) {
  //   debugger;
  //   this.val = this.myCurrencyPipe.transform(
  //     this.normalVal,
  //     currencyCode,
  //     null,
  //     ".2-2",
  //     locale
  //   );
  // }
}
