import { BundleService } from "src/app/Services/bundleservice.service";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";

@Component({
  selector: "app-delete-bundle-dialog",
  templateUrl: "./delete-bundle-dialog.component.html",
  styleUrls: ["./delete-bundle-dialog.component.scss"],
})
export class DeleteBundleDialogComponent implements OnInit {
  userData: any;
  bundleData: any;
  noResultReturned: boolean;
  bundleId: any;
  constructor(
    private toastr: ToastrService,
    private flowServiceService: FlowServiceService,
    private bundleService: BundleService,
    public formbuilder: UntypedFormBuilder,
    private dataService: DataService,
    public router: Router,
    private activateRouter: ActivatedRoute,

  ) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.bundleId = localStorage.getItem("bundleIdToBeDeleted");
    this.bundleData = JSON.parse(localStorage.getItem("bundleData"));
  }

  presetUserSettings() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    if (this.userData.emailAddress != this.bundleData.customerEmail) {
      this.presentCurrentUserOptions();
    } else {
      this.deleteBundle();
    }
  }

  presentCurrentUserOptions() { }

  deleteBundle() {
    var bundleModel = {
      rowKey: this.bundleId,
      CustomerEmail: this.userData.emailAddress,
      BundleStatus: "Approved",
    };
    console.log(bundleModel);
    this.noResultReturned = true;
    var navigateTo = localStorage.getItem("navigateTo");
    if (this.userData.emailAddress == this.bundleData.customerEmail) {
      if (this.bundleId > 0) {
        this.bundleService
          .deleteBundle(bundleModel)
          .subscribe((data) => {
            this.noResultReturned = false;
            this.showToastForDelete(); 
            if (data) {
              this.router.navigate(["/user-profile"]);
            }
          });
      }
    } else {
      this.router.navigate(["/viewbundle/" + this.bundleData.rowKey]);
    }
  }

  showToastForDelete() {
    this.toastr.success("Bundle successfully Deleted");
  }
}
