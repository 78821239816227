import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-embedforbundles',
  templateUrl: './embedforbundles.component.html',
  styleUrls: ['./embedforbundles.component.scss']
})
export class EmbedforbundlesComponent {
  flowData: any;
  flowCoverImageUrl: any;
  codeToEmbed: any;
  flowlink: any;

  constructor(public dialogRef: MatDialogRef<EmbedforbundlesComponent>, private toastr: ToastrService) {}

  ngOnInit() { 
    this.flowData = JSON.parse(localStorage.getItem("flowsData")); 
    this.codeToEmbed = this.flowData.codeToEmbed;
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
    this.flowlink = window.location.href;
  }

  close() {
    this.dialogRef.close();
  }
  showToast(message) {
    this.toastr.success(message);
  }
}
