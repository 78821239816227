import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

import { DataService } from "../Services/dataservice.service";
import { FlowServiceService } from "../Services/flow-service.service";
import { UserserviceService } from "../Services/userservice.service";

@Component({
  selector: "app-dialog-example",
  templateUrl: "./dialog-example.component.html",
  styleUrls: ["./dialog-example.component.scss"],
})
export class DialogExampleComponent implements OnInit {
  flowData: any;
  userData: any;
  imageSource: any;
  description: any;
  nextCount: number;
  itemsRequired: number;
  stepCount: number;
  progressPercentage: number;
  progressBy: number;
  dataSource = [];
  dataSourceSteps = [];
  showimage: boolean;

  constructor(
    private userserviceService: UserserviceService,
    private flowServiceService: FlowServiceService,
    public formbuilder: UntypedFormBuilder,
    private dataService: DataService,
    public router: Router,
    
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.flowData = JSON.parse(localStorage.getItem("flow"));
    if (this.flowData != null) {
      this.imageSource = this.flowData.Url;
      this.description = this.flowData.Description;
      this.nextCount = 0;
      this.itemsRequired = 0;
      this.stepCount = 0;
      const steps = this.flowData.Steps.length + 2;
      this.progressBy = 100 / steps;
      this.progressPercentage = 100 / steps;

      if (this.flowData === "default message") {
        this.flowData = JSON.parse(localStorage.getItem("recentflow"));
      }

      this.dataSource = this.flowData.Components;
      this.loadSteps(this.flowData.Steps);
    }
  }

  presetUserSettings() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    if (this.userData.EmailAddress != this.flowData.CustomerEmail) {
      this.presentCurrentUserOptions();
    } else {
      this.deleteFlow();
    }
  }

  presentCurrentUserOptions() {}

  deleteFlow() {
    var flowModel = {
      rowKey: this.flowData.rowKey,
      CustomerEmail: this.userData.EmailAddress,
      FlowStatus: "Approved",
    };

    

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    var navigateTo = localStorage.getItem("navigateTo");
    if (this.userData != null) {
      if (this.userData.EmailAddress == this.flowData.CustomerEmail) { 
        this.flowServiceService
          .deleteFlow(flowModel)
          .subscribe((data) => {      
            if (data) {
              this.router.navigate(["/user-profile"]);
            }
          });
      }
    } else {
      this.router.navigate(["/" + navigateTo]);
    }
  }

  loadSteps(steps) {
    steps.forEach((element) => {
      this.dataSourceSteps.push({
        title: element.Title,
        url: element.Url,
        description: element.Description,
        id: element.Id,
        ShowImage: this.showimage,
      });
    });
  }
}
