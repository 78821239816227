import { BundleService } from "src/app/Services/bundleservice.service";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GetContentService } from "src/app/Services/getcontent.service";

@Component({
  selector: "app-selection-rephraseAi-dialog",
  templateUrl: "./selection-rephraseAi-dialog.component.html",
  styleUrls: ["./selection-rephraseAi-dialog.component.scss"],
})
export class SelectionRephraseAIComponent implements OnInit {
  selectedStyle: string = 'Professional'; // Default style
  isRephraseInProgress: boolean = false;
  rephrasedText: string = '';
  showConfirm = false;
  title: any;
  originalContentTitleText: string;
  isTitleRephrased: any;

  constructor(
    private getContentService: GetContentService,
    public dialogRef: MatDialogRef<SelectionRephraseAIComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { textToRephrase: string }  // Input text passed from main component
  ) { }

  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  async rephraseText() {
    this.isRephraseInProgress = true;
    this.rephrasedText = '';  // Clear previous rephrased text

    const aiModel = {
      textToRephrase: this.data.textToRephrase,
      userInput: this.selectedStyle
    };

    try {
      // const aiResponse = await this.getContentService.getContentFromAI(aiModel);
      if (!this.isTitleRephrased) {
        this.originalContentTitleText = this.data.textToRephrase;
      }
      const aiResponse = (await this.getContentService
        .getContentFromAI(aiModel))
        .subscribe((aiResponse) => {
          if (aiResponse) {
            console.log(aiResponse);
            console.log(aiResponse[0].completions);
            console.log('Rephrased Text:', this.rephrasedText); // Debugging log
            this.rephrasedText = aiResponse[0].completions;
            this.isRephraseInProgress = false;
            if (aiResponse && aiResponse[0].completions && aiResponse[0].completions != undefined) {
              if (this.rephrasedText != null) {
                this.showConfirm = true;
                this.isTitleRephrased = true;
                this.data.textToRephrase = this.rephrasedText;
              }
              console.log('Rephrased Text:', this.rephrasedText); // Debugging log
            } else {
              console.error('Unexpected AI response:', aiResponse);
            }
          }
        }); 
    } catch (error) {
      console.error('Error during rephrasing:', error);
    } finally {
      this.isRephraseInProgress = false;  // Ensure this runs regardless of success or error
    }
  }

  confirmRephrase() {
    console.log('Confirm Rephrase called'); // Debug log
    if (this.rephrasedText) {
      this.dialogRef.close({
        rephrasedText: this.rephrasedText,
        selectedStyle: this.selectedStyle
      });
    }
  }

  GoToOriginalText() {
    this.data.textToRephrase = this.originalContentTitleText;
  }


  cancel() {
    this.dialogRef.close();
  }
}
