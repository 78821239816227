import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-bundleqrcode",
  templateUrl: "./bundleqrcode.component.html",
  styleUrls: ["./bundleqrcode.component.scss"],
})
export class BundleqrcodeComponent implements OnInit ,AfterViewInit{
  qrcodename: string;
  title = "generate-qrcode";
  elementType: "url" | "canvas" | "img" = "url";
  value: string;
  display = false;
  href: string;

  // ename = 'Angular' +VERSION.major;
  //  elementType = NgxQrcodeElementTypes.URL;
  // correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  // value='https://flowchest.azurewebsites.net/grid';

  bundleData: any = [];
  imageSource: any;
  userData: any;
  flowCoverImageUrl: any;
  flowData: any;
  //  facebookBtn:any;

  @ViewChild('qrCodeImage') qrCodeImage: ElementRef;

  constructor(private router: Router,public dialogRef: MatDialogRef<BundleqrcodeComponent>) {
    this.bundleData = JSON.parse(localStorage.getItem("bundleData"));
  } 

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.bundleData = JSON.parse(localStorage.getItem("bundleData"));
    this.flowCoverImageUrl = "url(" + '"' + this.bundleData.url + '"' + ")";

    if (this.bundleData != null) {
      this.imageSource = this.bundleData.url;
      this.display = true;
      this.qrcodename =
        environment.webBaseUrl +"/viewbundle/"+ this.bundleData.rowKey;
      this.generateQRCode();
    }
  }
  ngAfterViewInit(): void {
    this.waitForQRCodeRender();
  }

  private waitForQRCodeRender() {
    const interval = setInterval(() => {
      const canvas = document.querySelector('#qrCodeImage canvas');
      if (canvas) {
        clearInterval(interval);
        console.log("clearinterval");
      }
    }, 100);
  }
  closedClicked() {
    this.router.navigate(["/viewbundle/" + this.bundleData.rowKey]);
  }
  close() {
    this.dialogRef.close();
  }
  generateQRCode() { 
    if (this.qrcodename == "") {
      this.display = false;
      return;
    } else {
      this.value = this.qrcodename;
    }
  }

  onDownloadQRCode() {
    this.downloadQRCode();
  }

  downloadQRCode() {
    const canvas = document.querySelector('#qrCodeImage canvas') as HTMLCanvasElement;
    console.log(canvas);
    if (!canvas) {
      console.error("QR code canvas not found!");
      return;
    }

    const imageUrl = canvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'qr_code.png';
    link.click();
  }

}
