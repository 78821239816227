import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { UserserviceService } from "src/app/Services/userservice.service";

@Component({
  selector: "app-image-dialog",
  templateUrl: "./image-dialog.component.html",
  styleUrls: ["./image-dialog.component.scss"],
})
export class ImageDialogComponent implements OnInit {
  userDisplayName: any;
  userDisplayEmail: any;
  carouselOptions: any;
  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  profilePicture: string;

  flows: any = [];
  savedFlows: any = [];
  userData: any;
  approvedFlows: any = [];
  draftFlows: any = [];
  profileUser: any = [];

  followerCount: number;
  followingCount: number;

  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  SlideOptions: any;
  showDefaultImage: boolean;
  isMobileView: boolean;
  categories: any;
  showNotificationCount: boolean;
  notificationCount: number;
  notificationtitle: any;
  notificationbody: any;
  notificationsarray: any = [];
  notificationscount: number;
  account_circle: any;

  ShowAboutYou: boolean;
  ShowEmail: boolean;
  ShowName: boolean;
  ShowWebsite: boolean;
  ShowPhone: boolean;

  tempFlows: any = [];
  istempFlows: any = [];
  itistempFlows: any = [];
  viewUserProfilePicture: string;

  constructor(
    
    private router: Router,
    private userserviceService: UserserviceService
  ) {}

  ngOnInit() {
    this.viewUserProfilePicture = JSON.parse(
      localStorage.getItem("profilePicture")
    );
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userDisplayEmail = this.userData.emailAddress;
      this.email = this.userData.emailAddress;
      this.phone = this.userData.phoneNumber;
      this.info = this.userData.info;
      this.website = this.userData.website;
      this.uname = this.userData.userName;
      this.name = this.userData.firstName;
      this.followerCount = 0;
      this.followingCount = 0;

      this.getProfileUser();
    }
  }

  getProfileUser() {
    this.showNotificationCount = true;
    this.userserviceService
      .getProfileUser()
      .subscribe((data) => {
        if (
          data.userData.profilePicUrl === "" ||
          data.userData.profilePicUrl == null
        ) {
          this.showDefaultImage = true;
        } else {
          this.showDefaultImage = false;
          this.profilePicture = data.userData.profilePicUrl;
        }
        this.email = data.userData.emailAddress;
        this.phone = data.userData.phoneNumber;
        this.info = data.userData.info;
        this.website = data.userData.website;
        this.uname = data.userData.userName;
        this.name = data.userData.firstName;

        this.ShowAboutYou = data.userData.showAboutYou;
        this.ShowEmail = data.userData.showEmail;
        this.ShowName = data.userData.showName;
        this.ShowWebsite = data.userData.showWebsite;
        this.ShowPhone = data.userData.showPhone;
      });
  }
}
