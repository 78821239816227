import { Component, OnInit, ChangeDetectorRef } from "@angular/core";

import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-following",
  templateUrl: "./following.component.html",
  styleUrls: ["./following.component.scss"],
})
export class FollowingComponent implements OnInit {
  _allFollowingUsers = [];
  userDisplayEmail: any;
  userName: string;
  firstName: string;
  profilePicUrl: string;
  getFollowerUrl: string;
  userDisplayName: any;
  email: any;
  phone: any;
  info: any;
  website: any;
  name: any;
  showDefaultImage: boolean;
  profilePicture: any;
  uname: any;
  getFollowingUrl: string;
  userData: any;
  tempFollowing: any[];
  noResultReturned: boolean;

  constructor(
    private flowServiceService: FlowServiceService,
    private userserviceService: UserserviceService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dataService: DataService,
    
  ) {
    this.getFollowingUrl = "v1/UserController/getFollowingUsers";
  }

  ngOnInit() {
    this.flowServiceService.setScrollTop();
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    if (this.userData != null) {
      this._allFollowingUsers = [];
      this.tempFollowing = [];
      
      var flowQueryModel={
        UserEmail:this.userData.EmailAddress
      }
      this.flowServiceService
        .getFollowingUsers(flowQueryModel)
        .subscribe((data) => { 
          if (data.FollowingUsers != null) {
            data.FollowingUsers.forEach((element) => {
              console.timeLog(element);
              this._allFollowingUsers.push({
                showFollow: false,
                followButtonText: "Following",
                ProfilePicUrl: element.profilePicUrl,
                UserName: element.userName,
                FirstName: element.firstName,
                customerEmail: element.emailAddress,
              });
            });
            this.tempFollowing = this._allFollowingUsers;
            this.tempFollowing.forEach((element) => {
              this.CheckIsUserFollowing(element);
            });
          }
        });
    }
  }

  CheckIsUserFollowing(user) {
    if (this.userData != null) {
      if (this.userData.emailAddress === user.user.emailAddress) {
        user.showFollow = false;
      } else {
        this.noResultReturned = true;

        // 

        user.showFollow = true;

        const flowModel = {
          CustomerEmail: user.user.emailAddress,
          UserEmailAddress: this.userData.emailAddress,
        };

        this.flowServiceService
          .CheckIsUserFollowing(flowModel)
          .subscribe((data) => {
            if (data.MessageText === "Following") {
              user.followButtonText = "Following";
              user.followingcolor = "black";
              user.followingbgcolor = "white";
            } else {
              user.followButtonText = "Follow";
              user.followingcolor = "white";
              user.followingbgcolor = "#5ea1d8";
            }
            //  
            this.noResultReturned = false;
          });
      }
    }
  }

  followUser(selectedUser) {
    if (this.userData != null) {
      // 
      this.noResultReturned = true;
      const flowModel = {
        UserEmail: selectedUser.emailAddress,
        FollowerEmail: this.userData.emailAddress,
      };

      this.flowServiceService.FollowUser(flowModel).subscribe((data) => {
        let user = this.tempFollowing.filter(
          (u) => u.user.emailAddress == selectedUser.emailAddress
        )[0];

        if (data.isFollowing === "following") {
          user.followButtonText = "Following";
          user.followingcolor = "black";
          user.followingbgcolor = "white";
        } else {
          user.followButtonText = "Follow";
          user.followingcolor = "white";
          user.followingbgcolor = "#5ea1d8";
        }
        this.changeDetectorRef.detectChanges();
        // 
        this.noResultReturned = false;
      });
    } else {
      this.router.navigate(["/login"]);
    }
  }
}
