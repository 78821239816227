
import { Component, OnInit } from '@angular/core';
import { UserserviceService } from '../Services/userservice.service';
import { Router } from '@angular/router';
import { FlowServiceService } from '../Services/flow-service.service';
import { GetContentService } from '../Services/getcontent.service';
import { ContentQueryModel } from '../shared/cms/contentquerymodel';
import { prodcmsconfig, testcmsconfig } from '../shared/cms/cmsconfig';
import { ContentTypes } from '../shared/enums/content.types';


@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent implements OnInit {
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  noResultReturned: boolean;
  isLoading: boolean;
  tnc: any;
  tncPageObj: any;
  paraPageObj: any;


  constructor( private userserviceService:UserserviceService,
     private router:Router,  private flowServiceService: FlowServiceService,
     private getcontentService: GetContentService) { 
    window.scrollTo(0,0);
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.getProfileUser();
    this.onActivate();
    this.getTnCPageDetails();
  } 
  async getTnCPageDetails() {
    this.noResultReturned = true;
    this.isLoading = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.TnC,
      recaptchaValue: prodcmsconfig.environmentKey,
    });
    (await this.getcontentService.getContentByContentQueryModel(
      contentQueryModel
    )).subscribe((data) => { 
      this.tncPageObj = data;
      this.paraPageObj = data[0].paragraphModels[0]; 
      //this.maintableRowModels = data[0].tablesModels[0].tableRowModels.splice(1, data[0].tablesModels[0].tableRowModels.length - 1);
      this.noResultReturned = false;
    });
    
  }
  createFlow(){
    this.router.navigate(['/create-flow']);

  }
  onActivate() {
    window.scroll(0,0);
     document.body.scrollTop = 0;
    document.querySelector('body').scrollTo(0,0)
  
}

 getProfileUser(): void {
    if (this.userData != null) {
      

      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.UserData.ProfilePicUrl === '' ||
            data.UserData.ProfilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.UserData.ProfilePicUrl;
          }
          this.email = data.UserData.EmailAddress;
          this.phone = data.UserData.PhoneNumber;
          this.info = data.UserData.Info;
          this.website = data.UserData.Website;
          this.uname = data.UserData.UserName;
          this.name = data.UserData.FirstName;

        });
    }
  }

 SignOut() {
    localStorage.setItem('LoggedInUser', null);
    this.userDisplayName = null;
  }

}
