import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  setFlowByCategorieschangeMessage = this.messageSource.asObservable();
  private isLoginSource = new BehaviorSubject(false);
  private currencySource = new BehaviorSubject("");
  currencyMessage = this.currencySource.asObservable();
  private databaseandcollection = new BehaviorSubject({});
  databaseandcollectionObject = this.databaseandcollection.asObservable();
  currentMessagebool = this.isLoginSource.asObservable();
  
  constructor() {}

  changeMessage(message) {
    this.messageSource.next(message);
    localStorage.setItem('recentflow', JSON.stringify(message));
  }

  flowByCategorieschangeMessage(message) {
    this.messageSource.next(message);
    localStorage.setItem('flowByCategory', JSON.stringify(message));
  }

  changeMessagebool(val: boolean) {
    this.isLoginSource.next(val);
  }
  changeCurrency(val: any) {
    this.currencySource.next(val);
  }
  Setdatabaseandcollection(val: {}) {
    this.databaseandcollection.next(val);
  }

}
