export class ContentQueryModel {
    userEmail: string = '';
    contentType: string = '';
    createdTime: string = '';
    applicationName: string = '';
    environmentName: string = '';
    applicationKey: string = '';
    environmentKey: string = '';
    createdBy: string = '';
    modifiedBy: string = '';
    coverImage: string = '';
    recaptchaValue: string = '';
    rowKey: string = '';
    statusToUpdate: string = '';
    contentTitle: string = '';
    constructor(data?: Partial<ContentQueryModel>) {
      if (data) {
        Object.assign(this, data);
      }
    }
  }
  