// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.author-details {
  text-align: left;
}
.author-details p {
  padding: 0px;
  margin-bottom: 15px !important;
}
.author-details p:first-child span {
  font-weight: bold;
}
.author-details p:last-child {
  margin-bottom: 0px !important;
  border-bottom: 1px solid #c4c5ca;
  padding: 10px 0px;
  display: inline-block;
  max-width: 400px;
  width: 100%;
}
.author-details a {
  text-align: left;
  display: block;
}
.author-details:last-child {
  margin-bottom: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/blogs/blogpages/author/author.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACE;EACE,YAAA;EACA,8BAAA;AACJ;AACQ;EACI,iBAAA;AACZ;AAEI;EACE,6BAAA;EAEA,gCAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;EACA,WAAA;AADN;AAIE;EACE,gBAAA;EAEA,cAAA;AAHJ;AAKE;EACE,8BAAA;AAHJ","sourcesContent":[".author-details {\n    text-align: left;\n    \n  p {\n    padding: 0px;\n    margin-bottom: 15px !important;\n    &:first-child{\n        span{\n            font-weight: bold;\n        }\n    }\n    &:last-child {\n      margin-bottom: 0px !important;\n      // border-top: 1px solid #c4c5ca;\n      border-bottom: 1px solid #c4c5ca;\n      padding: 10px 0px;\n      display:inline-block;\n      max-width:400px;\n      width:100%;\n    }\n  }\n  a {\n    text-align: left;\n\n    display: block;\n  }\n  &:last-child{\n    margin-bottom: 20px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
