import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class PreviewParagraphComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  paraText: any;
  @Input() rowKey!: string;

  constructor() {}
  ngOnInit(): void {
    this.blog = JSON.parse(localStorage.getItem('selectedBlogContent'));
    this.paraText = this.blog.articleText;
  }
  onRightClick($event: any) {}
  loadMoreblogs() {}
}
