import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thirdpartyliabraries',
  templateUrl: './thirdpartyliabraries.component.html',
  styleUrls: ['./thirdpartyliabraries.component.scss']
})
export class ThirdpartyliabrariesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
