// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textcolor {
  color: white;
}

#page-container {
  padding: 2rem 3rem;
}
#page-container a {
  color: #5ea1d8;
}

@media (max-width: 767px) {
  #page-container {
    padding: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/privacypolicy/privacypolicy.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,kBAAA;AAEF;AADE;EACE,cAAA;AAGJ;;AAAA;EACE;IACE,aAAA;EAGF;AACF","sourcesContent":[".textcolor {\n  color: white;\n}\n#page-container{\n  padding:2rem 3rem;\n  a{\n    color:#5ea1d8;\n  }\n}\n@media(max-width:767px){\n  #page-container{\n    padding:15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
