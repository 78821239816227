import { Component, OnInit } from "@angular/core";

import { UserserviceService } from "src/app/Services/userservice.service";
import { Router } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";

@Component({
  selector: "app-preview-page",
  templateUrl: "./preview-page.component.html",
  styleUrls: ["./preview-page.component.scss"],
})
export class PreviewPageComponent implements OnInit {
  userDisplayName: any;
  userDisplayEmail: any;

  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  profilePicture: string;
  userData: any;
  showDefaultImage: boolean;
  showAboutYou: any;
  showEmail: any;
  showName: any;
  showWebsite: any;
  showPhone: any;
  customerModel: any;
  Info: string;
  PhoneNumber: string;
  FirstName: string;
  EmailAddress: string;
  UserName: string;
  Website: string;
  ProfilePicUrl: string;

  constructor(
    public formbuilder: UntypedFormBuilder,
    private userserviceService: UserserviceService,

    private router: Router,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.titleandmetaserviceService.setTitle("Preview Page");
    this.customerModel = JSON.parse(localStorage.getItem("customerModel"));
  }

  ngOnInit() {
    this.customerModel = JSON.parse(localStorage.getItem("customerModel"));
    this.Info = this.customerModel.Info;
    this.UserName = this.customerModel.UserName;
    this.Website = this.customerModel.Website;
    this.EmailAddress = this.customerModel.EmailAddress;
    this.FirstName = this.customerModel.FirstName;
    this.ProfilePicUrl = this.customerModel.ProfilePicUrl;
    this.PhoneNumber = this.customerModel.PhoneNumber;

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    if (this.userData != null) {
      this.EmailAddress = this.customerModel.ShowEmail
        ? this.customerModel.EmailAddress
        : "";
      this.PhoneNumber = this.customerModel.ShowPhone
        ? this.customerModel.PhoneNumber
        : "";
      this.Info = this.customerModel.ShowAboutYou
        ? this.customerModel.Info
        : "";
      this.Website = this.customerModel.ShowWebsite
        ? this.customerModel.Website
        : "";
      this.UserName = this.customerModel.UserName;

      this.FirstName = this.customerModel.ShowName
        ? this.customerModel.FirstName
        : "";
      if (this.customerModel.profilePicUrl != null) {
        this.ProfilePicUrl = this.customerModel.ProfilePicUrl;
      }
    }
  }

  gobackeditprofile() {
    this.router.navigate(["/edit-profile"]);
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }

  signOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }
}
