import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GetContentService } from 'src/app/Services/getcontent.service';
import { testcmsconfig, prodcmsconfig } from 'src/app/shared/cms/cmsconfig';
import { ContentQueryModel } from 'src/app/shared/cms/contentquerymodel';
import { ContentTypes } from 'src/app/shared/enums/content.types';
import { BundledialogComponent } from '../bundledialog/bundledialog.component';
import { Location } from "@angular/common";
import { EventEmitterService } from 'src/app/Services/event-emitter.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  blogs: any = [];
  noResultReturned: boolean;
  noAllResultReturned:boolean=false;
  loadMore: boolean;
  pageSize: any;
  coverImageUrl: any;
  timeToRead: any;
  isLoading!: boolean;
  contents: any = [];
  add_circle_outlinebgColor: string;
  add_circle_outlineSize: string;
  userData: any;
  add_circle_outlineColor: string;
  // articlebgColor: string;
  // articleColor: string;
  // eventbgColor: string;
  // eventColor: string;
  // casestudybgColor: string;
  // casestudyColor: string;
  // reportbgColor: string;
  // reportColor: string;
  // tutorialsbgColor: string;
  // tutorialsColor: string;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private _cdf: ChangeDetectorRef,
    private getcontentService: GetContentService,
    public location: Location,
     private eventEmitterService: EventEmitterService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.pageSize = 12;
    this.noResultReturned = false;
    this.loadMore = false;
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.getBlogs();
    this.eventEmitterService.setUserProfileCreateIconClickedEvent("emoji_objects");
  }

  loadMoreTips() { }
  onScrollDown() { }

  async getBlogs() {
    this.noResultReturned = true;

    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.Blogs,
    });

    (await this.getcontentService.getContentByContentQueryModel(
      contentQueryModel
    )).subscribe((data) => {
      this.contents = data;
      this.noResultReturned = false;
    });

    this.isLoading = false;
  }

  setLoadMore(tips: any) {
    this.loadMore = !(tips.length < this.pageSize.Value);
  }

  onTabChanged(event: any) { }
  onRightClick(event: any) { }
  getTitle(title: any) {
    // Replace spaces with hyphens and convert to lowercase
    return title.replace(/\s+/g, '-');
  }
  viewblog(blog: any) {
    localStorage.setItem('selectedBlogContent', JSON.stringify(blog));
    this.router.navigate(['/blog/' + this.getTitle(blog.contentTitle)]);
  }

  openDialog() {
    if (this.userData != null) {
      this.eventEmitterService.setUserProfileCreateIconClickedEvent("add_circle_outline");
      this.add_circle_outlinebgColor = "white";
      this.add_circle_outlineColor = "#5ea1d8 !important";
      this.add_circle_outlineSize = "25px !important";
      localStorage.removeItem("ItemsRequired");
      localStorage.removeItem("flowCoverPageDetails");
      localStorage.removeItem("stepsCoverPageDetails");
      localStorage.removeItem("draftWord");
      localStorage.removeItem("editWord");
      const dialogRef = this.dialog.open(BundledialogComponent, {
        panelClass: "dialoguebox",
      });

      dialogRef.afterClosed().subscribe((result) => {
        
        if (result === "bundle") {
          localStorage.setItem(
            "navigatebackfromcreatebundle",
            this.location.path()
          );
          localStorage.removeItem("createbundledetails");
          localStorage.removeItem("addbundleselectedflows");
          this.router.navigate(["/createbundle"]);
        } else if (result === "flow"){
          localStorage.setItem(
            "navigatebackfromcreateflow",
            this.location.path()
          );
          this.router.navigate(["/create-flow"]);
        } else{ 
        this.eventEmitterService.setUserProfileCreateIconClickedEvent("emoji_objects");
        window.location.reload();
        }
      });
    } else {
      this.router.navigate(["/login"]);
    }
  }
  // articleCategory() {  this.setallgray('Article') }
  // caseStudyCategory() { this.setallgray('CaseStudy')}
  // eventCategory() {this.setallgray('Event') }
  // reportCategory() { this.setallgray('Report')}
  // tutorialsCategory() { this.setallgray('Tutorials')}

  // setallgray(selectedCategory) {
  //   this.articlebgColor = "white";
  //   this.articleColor = "gray";

  //   this.eventbgColor = "white";
  //   this.eventColor = "gray";

  //   this.casestudybgColor = "white";
  //   this.casestudyColor = "gray";

  //   this.reportbgColor = "white";
  //   this.reportColor = "gray";

  //   this.tutorialsbgColor = "white";
  //   this.tutorialsColor = "gray";

  //   this.setSelectedCategoryToBlack(selectedCategory);
  // }
  // setSelectedCategoryToBlack(selectedCategory) { 
  //   switch (selectedCategory) {
  //     case "All":
  //       this.articlebgColor = "gray";
  //       this.articleColor = "white";
  //       break;
  //     case "Users":
  //       this.eventbgColor = "gray";
  //       this.eventColor = "white";
  //       break;
  //     case "All":
  //       this.casestudybgColor = "gray";
  //       this.casestudyColor = "white";
  //       break;
  //     case "Users":
  //       this.reportbgColor = "gray";
  //       this.reportColor = "white";
  //       break;
  //     case "All":
  //       this.tutorialsbgColor = "gray";
  //       this.tutorialsColor = "white";
  //       break;
  //   }
  // }
}
