import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Injectable({ providedIn: "root" })
export class ViewByIdResolver  {
  userData: any;
  constructor(private service: FlowServiceService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    var email = "";

    if (this.userData != null) {
      email = this.userData.EmailAddress;
    }
           
    var flowQueryModel = new FlowQueryModel({
      FlowId: route.paramMap.get("rowKey"),
      userEmail: email
    });
    return this.service.getFlowById(flowQueryModel);
  }
}
