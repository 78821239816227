import { Component, OnInit } from '@angular/core';
import { FlowServiceService } from '../Services/flow-service.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacypolicyComponent implements OnInit {

  constructor(private flowServiceService: FlowServiceService) {  window.scrollTo(0,0);
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });}

  ngOnInit() {
    window.scrollTo(0,0)
    this.flowServiceService.setScrollTop();
  }

}
