import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Console } from "console";
import { FlowServiceService } from "src/app/Services/flow-service.service";

@Component({
  selector: "app-support-document",
  templateUrl: "./support-document.component.html",
  styleUrls: ["./support-document.component.scss"],
})
export class SupportDocumentComponent implements OnInit {
  flowCoverImageUrl: any;
  flowData: any;
  dataService: any;
  imageSource: any;
  description: any;
  isPurchaseRequired: any;
  userData: any;
  LoggedInUser: null;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<SupportDocumentComponent>,
    private flowservice: FlowServiceService
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser")); 
    this.flowData = JSON.parse(localStorage.getItem("flowData")); 
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
  }

  openLink(supportingDocument) {
    var url = supportingDocument.linkUrl;
    if (this.flowData.isPaid && this.flowData.isFlowPurchased==false ) {
      if (this.userData.emailAddress !== this.flowData.customerEmail) {
        localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
        this.router.navigate(["/checkout"]);
      }

      else {
        if (url.includes("https://")) {
          url = url; 
          window.open(url);
          this.recordLinkClick(supportingDocument, false);
        }
        else {
          url = "https://" + url; 
          window.open(url);
          this.recordLinkClick(supportingDocument, false);
        }
      }
    }
    else {
      if (url.includes("https://")) {
        url = url; 
        window.open(url);
        this.recordLinkClick(supportingDocument, false);

      } else if (this.userData == null) {
        if (url.includes("https://")) {
          url = url; 
          window.open(url);
          this.recordLinkClick(supportingDocument, false);
        }
        else {
          url = "https://" + url; 
          window.open(url);
          this.recordLinkClick(supportingDocument, false);
        }
      }
      else {
        url = "https://" + url; 
        window.open(url);
        this.recordLinkClick(supportingDocument, false);
      }
    }
  }

  recordLinkClick(supportingDocument, docClicked) {

    var linkClickDetails = {
      PartitionKey: this.flowData.rowKey,
      Link: supportingDocument.documentUrl,
      LinkTitle: supportingDocument.documentName,
      ContentTitle: this.flowData.title,
      UserEmail: this.userData.emailAddress,
      ContentType: this.flowData.type == "Bundle" ? "Bundle" : this.flowData.type,
      ContentUrl: this.flowData.url
    };

    if (docClicked) {
      linkClickDetails.Link = supportingDocument.documentUrl;
      linkClickDetails.LinkTitle = supportingDocument.documentName;
    } else {
      linkClickDetails.Link = supportingDocument.linkUrl;
      linkClickDetails.LinkTitle = supportingDocument.linkName;
    }
 
    this.flowservice.postLinkClick(linkClickDetails).subscribe(res => { 
    });
  }

  openDocument(supportingDocument) {  
    if (this.flowData.isPaid) {
      if (this.userData.emailAddress !== this.flowData.customerEmail) {
        localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
        this.router.navigate(["/checkout"]);
      }else if (this.userData == null) {
        localStorage.setItem("checkoutFlow", JSON.stringify(this.flowData));
        this.router.navigate(["/checkout"]);
      }
      else {
        this.recordLinkClick(supportingDocument, true);
        window.open(supportingDocument.documentUrl);
      }
    } else {
      if (this.userData == null) {
        //this.recordLinkClick(supportingDocument, true);
        window.open(supportingDocument.documentUrl);
      }
      else {
        this.recordLinkClick(supportingDocument, true);
        window.open(supportingDocument.documentUrl);
      }

    }
  }

  close() {
    this.dialogRef.close();
  }
}
