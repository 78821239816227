import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as braintree from "braintree-web";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { ToastrService } from "ngx-toastr";
import { ICreateOrderRequest } from "ngx-paypal";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  hostedFieldsInstance: braintree.HostedFields;
  public payPalConfig: any;
  public showPaypalButtons: boolean;
  cardholdersName: string;

  // Personal Info
  name: string;
  email: string;
  countryCode: string;
  postcode: string;

  bfname: string;
  baddressline2: string;
  baddressline1: string;
  bpostcode: string;
  bcity: string;
  billingCountryCode: any;

  flowData: any;
  userData: any;
  noResultReturned: boolean;
  couponCode: any;
  salePrice: any;
  contentType: string;
  ispPayPalPayment: boolean;
  isCardPayment: boolean;
  paymentType: any;
  cardOptions: any = [];

  constructor(
    public router: Router,
    public flowServiceService: FlowServiceService,
    private toastr: ToastrService
  ) {
    this.isCardPayment = true;
    this.ispPayPalPayment = false;
    this.cardOptions.push(
      {
        name: "Card",
        value: "Card",
        checked: true,
      },
      {
        name: "Paypal",
        value: "Paypal",
        checked: false,
      }
    );
  }

  ngOnInit(): void {
    this.countryCode = "GB";
    this.billingCountryCode = "GB";
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    this.flowData = JSON.parse(localStorage.getItem("checkoutFlow"));
    if (this.flowData != null) {
      this.setCustomerDetails();
      // this.createBraintreeUI();
      // this.setupPaypal();
      this.setupNewPaypal();
      this.contentType = this.flowData.Type == "Bundle" ? "Bundle" : "Flow";
    }
  }

  // Production
  //const string clientId = "AR5n85mQzY9uWPZrXTpax1FAeZ4gSFNCHuN450_PWB7UkkGM5NKcmRtLRG_tLVUnXmhaRjeAdv8yNUYB";
  //const string clientSecret = "EMJ3RyJd9hFjAnfpkPG55uEEx5UTmezyhYmFbpE5WK_MV0cmAAFaRo3I0QjTEIc69cHc0rhk-RBBQwKj";

  //Sandbox
  // const string clientId = "Aes4Is7vxJNETY3Dyv3yy20Q9NOfXLZbjtlxwTgkEzZ4kabHaCT8Fs_NPixT2wi35lFBoG46VVhFsIxY";
  // const string clientSecret = "ECwcwvndJkse8KhQsr_1hbZ60J6x6R0xgnggNpp4_nE6jZI4FnZdkypsC0TxW3DfUmyuIhZT-O2dBzyx";

  setupNewPaypal() {
    console.log(this.flowData);
    this.payPalConfig = {
      currency: "GBP",
      clientId:
        "Aes4Is7vxJNETY3Dyv3yy20Q9NOfXLZbjtlxwTgkEzZ4kabHaCT8Fs_NPixT2wi35lFBoG46VVhFsIxY",
      createOrderOnClient: (data) =>
        //createOrder: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "GBP",
                value: this.flowData.price,
                breakdown: {
                  item_total: {
                    currency_code: "GBP",
                    value: this.flowData.price,
                  },
                },
              },
              items: [
                {
                  name: "Enterprise Subscription",
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: "GBP",
                    value: this.flowData.price,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        layout: "vertical",
      },
      onApprove: (data, actions) => {
        actions.order.get().then((details) => { });
      },
      onClientAuthorization: (data) => {
        this.submitPaymentToServer(data.id);
      },
      onCancel: (data, actions) => { },
      onError: (err) => { },
      onClick: (data, actions) => { },
    };
  }

  setCustomerDetails() {
    this.name = this.userData.fullName;
    this.email = this.userData.emailAddress;
  }

  cardPayment() {
    this.isCardPayment = true;
    this.ispPayPalPayment = false;
  }

  paypalPayment() {
    this.ispPayPalPayment = true;
    this.isCardPayment = false;
  }

  createBraintreeUI() {
    this.setupPaymentUI();
  }

  setupPaypal() {
    var paypalButton = document.getElementById("paypal-container");

    braintree.client.create(
      {
        authorization: "sandbox_8hsz2qng_fp7wd3qdch4vfmjy",
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          console.error("Error creating client:", clientErr);
          return;
        }

        braintree.paypal.create(
          {
            client: clientInstance,
          },
          function (paypalErr, paypalInstance) {
            if (paypalErr) {
              console.error("Error creating PayPal:", paypalErr);
              return;
            }

            paypalButton.removeAttribute("disabled");

            // When the button is clicked, attempt to tokenize.
            paypalButton.addEventListener(
              "click",
              function (event) {
                // Because tokenization opens a popup, this has to be called as a result of
                // customer action, like clicking a button. You cannot call this at any time.

                paypalInstance.tokenize(
                  {
                    flow: "checkout", // Required
                    amount: "10.00", // Required
                    currency: "GBP", // Required
                    // For more tokenization options, see the full PayPal tokenization documentation
                    // https://braintree.github.io/braintree-web/current/PayPal.html#tokenize
                  },
                  function (tokenizeErr, payload) {
                    if (tokenizeErr) {
                      if (tokenizeErr.type !== "CUSTOMER") {
                        console.error("Error tokenizing:", tokenizeErr);
                      }
                      return;
                    } else {
                      // Tokenization succeeded
                      paypalButton.setAttribute("disabled", "true");

                      // this.submitPaymentToServer("dadasd");
                    }
                  }
                );
              },
              false
            );
          }
        );
      }
    );
  }

  setupPaymentUI() {
    braintree.client
      .create({
        authorization: "sandbox_8hsz2qng_fp7wd3qdch4vfmjy",
      })
      .then((clientInstance) => {
        braintree.dataCollector.create(
          {
            client: clientInstance,
          },
          function (err, dataCollectorInstance) {
            if (err) {
              // Handle error in creation of data collector
              return;
            }
            // At this point, you should access the dataCollectorInstance.deviceData value and provide it
            // to your server, e.g. by injecting it into your form as a hidden input.
          }
        );

        braintree.hostedFields
          .create({
            client: clientInstance,
            styles: {
              // Override styles for the hosted fields
            },

            // The hosted fields that we will be using
            // NOTE : cardholder's name field is not available in the field options
            // and a separate input field has to be used incase you need it
            fields: {
              number: {
                selector: "#card-number",
                placeholder: "1111 1111 1111 1111",
              },
              cvv: {
                selector: "#cvv",
                placeholder: "111",
              },
              expirationDate: {
                selector: "#expiration-date",
                placeholder: "MM/YY",
              },
            },
          })
          .then((hostedFieldsInstance) => {
            this.hostedFieldsInstance = hostedFieldsInstance;

            hostedFieldsInstance.on("focus", (event) => {
              const field = event.fields[event.emittedBy];
              const label = this.findLabel(field);
              label.classList.remove("filled"); // added and removed css classes
              // can add custom code for custom validations here
            });

            hostedFieldsInstance.on("blur", (event) => {
              const field = event.fields[event.emittedBy];
              const label = this.findLabel(field); // fetched label to apply custom validations
              // can add custom code for custom validations here
            });

            hostedFieldsInstance.on("empty", (event) => {
              const field = event.fields[event.emittedBy];
              // can add custom code for custom validations here
            });

            hostedFieldsInstance.on("validityChange", (event) => {
              const field = event.fields[event.emittedBy];
              const label = this.findLabel(field);
              if (field.isPotentiallyValid) {
                // applying custom css and validations
                label.classList.remove("invalid");
              } else {
                label.classList.add("invalid");
              }
              // can add custom code for custom validations here
            });
          });
      });
  }

  payPalCheckout() {
    braintree.client
      .create({
        authorization: "sandbox_8hsz2qng_fp7wd3qdch4vfmjy",
      })
      .then(function (clientInstance) {
        return braintree.paypalCheckout.create({
          client: clientInstance,
        });
      })
      .then(function (paypalCheckoutInstance) {
        // set up the PayPal JS SDK
      })
      .catch(function (err) {
        console.error("Error!", err);
      });
  }

  // Tokenize the collected details so that they can be sent to your server
  // called from the html when the 'Pay' button is clicked
  tokenizeUserDetails() {
    this.noResultReturned = true;
    this.hostedFieldsInstance
      .tokenize({ cardholderName: this.cardholdersName })
      .then((payload) => {
        // submit payload.nonce to the server from here
        this.submitPaymentToServer(payload.nonce);
      })
      .catch((error) => {
        // perform custom validation here or log errors
      });
  }

  submitPaymentToServer(nonce) {
    this.noResultReturned = true;
    if (this.userData != null) {
      var checkoutModel = {
        PaymentMethodNonce: nonce,
        ContentId: this.flowData.rowKey,
        ContentType: this.flowData.type == "Bundle" ? "2" : "1",
        CustomerModel: {
          EmailAddress: this.userData.emailAddress,
          Address: {
            AddressLine1: this.baddressline1,
            AddressLine2: this.baddressline2,
            City: this.bcity,
            PinCode: this.bpostcode,
            Country: this.billingCountryCode,
          },
        },
        Price: this.salePrice == null ? this.flowData.price : this.salePrice,
      };

      // 
      this.flowServiceService
        .flowCheckout(checkoutModel, this.userData.emailAddress)
        .subscribe((data) => {
          if (data.messageText == "Payment Successful") {
            this.toastr.success("You purchased the flow successfully");
            this.flowData.type == "Bundle"
              ? this.router.navigate(["/viewbundle/" + this.flowData.rowKey])
              : this.router.navigate(["/view/" + this.flowData.rowKey]);
            this.noResultReturned = false;
          } else {
            this.toastr.success(
              "Failed to purchase the flow. Please contact administrator!"
            );
          }
        });
    } else {
      this.router.navigate(["/login"]);
    }
  }

  // Fetches the label element for the corresponding field
  findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector(
      '.hosted-field--label[for="' + field.container.id + '"]'
    );
  }

  applyCoupon() {
    if (this.couponCode != null && this.couponCode != undefined) {
      this.flowServiceService
        .getCouponCodeDiscountValue(
          this.flowData.price,
          this.couponCode,
          this.contentType
        )
        .subscribe((data) => {
          if (data.DiscountAmount > 0) {
            this.toastr.success("Discount applied successfully");
            this.salePrice = data.discountAmount;
          } else {
            this.toastr.error("Please use a valid coupon");
          }
        });
    }
  }

  backtoflowpage() {
    if (this.flowData != null) {
      this.flowData.Type == "Bundle"
        ? this.router.navigate(["/viewbundle/" + this.flowData.rowKey])
        : this.router.navigate(["/view/" + this.flowData.rowKey]);
    } else {
      this.router.navigate(["/grid"]);
    }
  }
}
function doSomethingWithTheNonce(nonce: any) {
  throw new Error("Function not implemented.");
}
