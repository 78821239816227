import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Type,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import Chart from "chart.js/auto";

import { BundleService } from "src/app/Services/bundleservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { DataService } from "../service/data.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AnalyticsComponent } from "../analyics/analytics.component";
import { FlowQueryModel } from "src/app/models/flowquerymodel";
@Component({
  selector: "app-user-dashboard",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.scss"],
})
export class UserDashboardComponent implements OnInit {
  @ViewChild("likeCanvas") likeCanvas: ElementRef | undefined;
  @ViewChild("bookmarkCanvas") bookmarkCanvas!: { nativeElement: any };
  @ViewChild("salesCanvas") salesCanvas!: { nativeElement: any };
  @ViewChild("commentsCanvas") commentsCanvas!: { nativeElement: any };

  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;

  NoOfBookmarks: any;
  NoOfLikes: any;
  NoOfSales: any;
  NoOfComments: any;
  tabIndex: any;
  FlowCount: any;
  noApprovedFlows: any;
  noResultReturned: boolean;
  userEmail: string;
  dashboardModel: any;
  userData: any;
  private searchTerms = new Subject<string>();
  userDashboardModelDetails: any[];
  input: string = "";
  linksClick: any;
  totalLinkClicks: number;
  totalGrossSales: any;
  totalNetSales: any;
  selectedFilter:string = "";
  blue = "#ofa";

  constructor(
    private activateRouter: ActivatedRoute,
    private dataService: DataService,
    private userserviceService: UserserviceService,
    private flowServiceService: FlowServiceService,
    private bundleService: BundleService,
    // private toastr: ToastrService,
    public formbuilder: UntypedFormBuilder,
    public router: Router,

    public dialog: MatDialog,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.searchTerms
      .pipe(debounceTime(1000)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        this.performSearch();
      });
  }

  performSearch() {
    this.userDashboardModelDetails =
      this.dashboardModel.userDashboardModelDetails.filter((f) =>
        f.flowName.toLowerCase().includes(this.input.toLowerCase())
      );
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.userEmail = "";

    if (this.userData != null) {
      this.userEmail = this.userData.emailAddress;
    }
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
    });
    this.getUserDashboard(flowQueryModel);
    this.selectedFilter='All';
  }

  selectedFilterValue(value:any){
    switch(value){
      case(value == 'All'):
        this.selectedFilter = 'All'
      break;
      case(value == 'Flows'):
        this.selectedFilter = 'Flows'
      break;
      case(value == 'Bundles'):
        this.selectedFilter = 'Bundles'
      break;
      case(value == 'Paid Flows'):
        this.selectedFilter = 'Paid Flows'
      break;
      case(value == 'Paid Bundles'):
        this.selectedFilter = 'Paid Bundles'
      break;
    }
  }

  goToFlowOrBundle(flow) {
    localStorage.setItem("notificationflowId", null);
    //localStorage.setItem("flow", JSON.stringify(flow));
    localStorage.removeItem("flow"); //, JSON.stringify(flow));
    localStorage.setItem("navigateTo", "grid");
    if (flow.flowType == "Bundle") {
      //this.isItBundle = true;
      console.log(flow.flowType);
      this.router.navigate(["/viewbundle/" + flow.id]);
    } else {
      //this.isItBundle = false;
      this.router.navigate(["/view/" + flow.id]);
    }
  }
  getLinksContentCount() {
    var flowModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
    });

    this.noResultReturned = true;
    this.bundleService.getAllBundleLinkClicks(flowModel).subscribe((data) => {
      console.log(data);
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel;
      this.linksClick = data;
      this.calculateTotalLinkClicks();
    });
  }
  handleFilter(filterType: string) {
    this.selectedFilter = '';
    // Implement your logic to handle the filter selection here
    let filter: any;
    switch (filterType) {
      case "All":
        filter = "All";
        this.getAll();
        break;
      case "Flows":
        filter = "Flows";
        this.getFlows();
        break;
      case "Bundles":
        filter = "Bundles";
        this.getBundles();
        break;
      case "Paid Flows":
        filter = "Paid Flows";
        this.getPaidFlows();
        break;
      case "Paid Bundles":
        filter = "Paid Bundles";
        this.getPaidBundles();
        break;
      default:
        filter = "";
        // Handle default case or throw error
        break;
    }
    console.log('filter', filter);
    this.selectedFilter = filter;
  }
  getAll() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
    });
    this.getUserDashboard(flowQueryModel);
  }

  getFlows() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      FlowType: "Flow",
    });
    this.flowServiceService
      .getUserDashboardFlows(flowQueryModel)
      .subscribe((data) => {
        this.processDashboardData(data);
      });
  }

  getBundles() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      FlowType: "Bundles",
    });
    this.flowServiceService
      .getUserDashboardBundles(flowQueryModel)
      .subscribe((data) => {
        this.processDashboardData(data);
      });
  }

  getPaidFlows() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      FlowType: "Flow",
    });
    this.flowServiceService
      .getUserDashboardPaidFlows(flowQueryModel)
      .subscribe((data) => {
        this.processDashboardData(data);
      });
  }

  getPaidBundles() {
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      FlowType: "Bundles",
    });
    this.flowServiceService
      .getUserDashboardPaidBundles(flowQueryModel)
      .subscribe((data) => {
        this.processDashboardData(data);
      });
  }

  processDashboardData(data: any) {
    this.noResultReturned = true;
    this.dashboardModel = data;
    console.log(this.dashboardModel);
    this.userDashboardModelDetails =
      this.dashboardModel.userDashboardModelDetails;
    this.noResultReturned = false;
    this.calculateTotalSale(this.userDashboardModelDetails);
  }

  calculateTotalLinkClicks() {
    this.totalLinkClicks = 0;

    for (let content of this.linksClick) {
      this.totalLinkClicks += content.totalCount;
    }
  }
  searchFlow() {
    this.searchTerms.next(this.input);
  }
  getUserDashboard(flowQueryModel) {
    this.noResultReturned = true;
    this.flowServiceService
      .getUserDashboard(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;
        this.dashboardModel = data;
        console.log(this.dashboardModel);
        if (this.dashboardModel.totalViews == null) {
          this.dashboardModel.totalViews = 0;
        }
        this.userDashboardModelDetails =
          this.dashboardModel.userDashboardModelDetails;
        this.getLinksContentCount();
        this.calculateTotalSale(this.userDashboardModelDetails);
      });
  }
  calculateTotalSale(data) {
    this.totalGrossSales = 0;
    this.totalNetSales = 0;

    data.forEach((element) => {
      this.totalGrossSales += element.totalGrossSales;
      this.totalNetSales += element.totalNetSales;
    });

    // Round to two decimal places
    this.totalGrossSales = this.totalGrossSales.toFixed(2);
    this.totalNetSales = this.totalNetSales.toFixed(2);

    console.log(this.totalGrossSales);
    console.log(this.totalNetSales);
  }

  analyticsPage(flow) {
    localStorage.setItem("analyticsflow", JSON.stringify(flow));
    const dialogRef = this.dialog.open(AnalyticsComponent, {
      panelClass: "dialogueboxnew",
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  ngAfterViewInit(): void {
    // this.getNoLikeGraph();
    // this.getNoBookmarkGraph();
    // this.getNoSaleGraph();
    // this.getNoComments();
  }

  tabChanged(event) {}

  // getNoLikeGraph(): void {
  //   this.NoOfLikes = new Chart(this.likeCanvas?.nativeElement, {
  //     type: "bar",
  //     data: {
  //       labels: ["FlowA ", "FlowB", "FlowC", "FLowD"],
  //       datasets: [
  //         {
  //           backgroundColor: ["#2ecc71", "#3498db", "#95a5a6", "#9b59b6"],
  //           data: [172, 99, 34, 7],
  //         },
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         title: {
  //           display: true,
  //           position: "top",
  //           text: "Likes",
  //           color: "#666",
  //           font: {
  //             size: 12,
  //           },
  //         },

  //         legend: {
  //           display: true,
  //           position: "bottom",
  //           labels: {
  //             color: "#999",
  //             font: {
  //               size: 14,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });
  // }

  // getNoBookmarkGraph(): void {
  //   this.canvas = this.bookmarkCanvas.nativeElement;
  //   this.ctx = this.canvas.getContext("2d");

  //   this.NoOfBookmarks = new Chart(this.ctx, {
  //     type: "bar",
  //     data: {
  //       labels: ["FlowA ", "FlowB", "FlowC", "FLowD"],
  //       datasets: [
  //         {
  //           backgroundColor: ["#2ecc71", "#3498db", "#95a5a6", "#9b59b6"],
  //           data: [19, 89, 43, 17],
  //         },
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         title: {
  //           display: true,
  //           position: "top",
  //           text: "Bookmarks",
  //           color: "#666",
  //           font: {
  //             size: 12,
  //           },
  //         },

  //         legend: {
  //           display: true,
  //           position: "bottom",
  //           labels: {
  //             color: "#999",
  //             font: {
  //               size: 14,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });
  // }

  // getNoSaleGraph(): void {
  //   this.canvas1 = this.salesCanvas.nativeElement;
  //   this.ctx1 = this.canvas1.getContext("2d");

  //   this.NoOfSales = new Chart(this.ctx1, {
  //     type: "bar",
  //     data: {
  //       labels: ["FlowA ", "FlowB", "FlowC", "FLowD"],
  //       datasets: [
  //         {
  //           backgroundColor: ["#2ecc71", "#3498db", "#95a5a6", "#9b59b6"],
  //           data: [52, 19, 36, 77],
  //         },
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         title: {
  //           display: true,
  //           position: "top",
  //           text: "Sales",
  //           color: "#666",
  //           font: {
  //             size: 12,
  //           },
  //         },

  //         legend: {
  //           display: true,
  //           position: "bottom",
  //           labels: {
  //             color: "#999",
  //             font: {
  //               size: 14,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });
  // }

  // getNoComments(): void {
  //   this.canvas1 = this.commentsCanvas.nativeElement;
  //   this.ctx1 = this.canvas1.getContext("2d");

  //   this.NoOfComments = new Chart(this.ctx1, {
  //     type: "bar",
  //     data: {
  //       labels: ["FlowA ", "FlowB", "FlowC", "FLowD"],
  //       datasets: [
  //         {
  //           backgroundColor: ["#2ecc71", "#3498db", "#95a5a6", "#9b59b6"],
  //           data: [42, 9, 73, 87],
  //         },
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         title: {
  //           display: true,
  //           position: "top",
  //           text: "Comments",
  //           color: "#666",
  //           font: {
  //             size: 12,
  //           },
  //         },

  //         legend: {
  //           display: true,
  //           position: "bottom",
  //           labels: {
  //             color: "#999",
  //             font: {
  //               size: 14,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });
  // }
}
