import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { DataService } from "../service/data.service";
import { UserProfileQrCodeComponent } from "../user-profile-qr-code/user-profile-qr-code.component";
import { UserQueryModel } from "src/app/models/flowquerymodel";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-view-user-profile-qr-code",
  templateUrl: "./view-user-profile-qr-code.component.html",
  styleUrls: ["./view-user-profile-qr-code.component.scss"],
})
export class ViewUserProfileQrCodeComponent implements OnInit,AfterViewInit{
  qrcodename: string;
  title = "generate-qrcode";
  elementType: "url" | "canvas" | "img" = "url";
  value: string;
  display = false;
  href: string;
  showNotificationCount: boolean;
  notificationCount: number;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  UserName: any;
  flowData: any;
  showvideo: boolean;
  showimage: boolean;
  imageURI: any;
  videoURI: any;
  displayedColumns: string[] = ["position", "name"];
  dataSource = [];
  dataSourceSteps = [];
  imageSource: any;
  description: any;
  nextCount: number;
  itemsRequired: number;
  likecolor: any;
  viewCount: any;
  viewText: any;
  followButtonText: any;
  followingcolor: any;
  followingbgcolor: any;
  showFollow: any;
  isFirstSlide: any;
  isLastSlide: any;
  progressPercentage: number;
  progressBy: number;
  stepCount: number;
  bookmarkcolor: any;
  stepNumber: number;
  isMobileView: boolean;
  carouselOptions: any;
  showVideoOrImage: boolean;
  facebookBtn: any;
  twitterBtn: any;
  pinterestBtn: any;
  linkedinBtn: any;
  whatsappBtn: any;
  flowCoverImageUrl: any;
  userprofile: any;
  ProfilePicUrl: any;
  emailAddress: any;
  otherUseruname: any;
  @ViewChild('qrCodeImage') qrCodeImage: ElementRef;
  constructor(
    private userserviceService: UserserviceService,
    private router: Router,
    
    private dataService: DataService,
    private flowServiceService: FlowServiceService,
    private cdref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ViewUserProfileQrCodeComponent>
  ) { }

  ngOnInit(): void {
    this.flowCoverImageUrl = "url(" + '"' + this.ProfilePicUrl + '"' + ")";
    this.emailAddress = localStorage.getItem("otherUserEmailAddress");
    this.otherUseruname = localStorage.getItem("otherUseruname");
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

     
    if (this.emailAddress != null && this.otherUseruname != null) {
      this.uname = environment.webBaseUrl + "/view-profile";
      
      this.display = true;
      this.qrcodename = this.uname;
      this.cdref.detectChanges();

      this.generateQRCode();
      this.getProfileUser();
    }

    var qrcodeelem = document.getElementById("qrCodeImage")?.children[0]; 
    var imag = <HTMLImageElement>qrcodeelem.children[0];
    this.href = imag.src;

    let postUrl = encodeURI("http://localhost:4200/grid");
    let postTitle = encodeURI("Hi everyone, please check QR code ");
    let postImg = encodeURI(this.href);
    this.facebookBtn.setAttribute(
      "href",
      `https://www.facebook.com/sharer.php?u=${postImg}`
    );

    this.twitterBtn.setAttribute(
      "href",
      `https://twitter.com/share?url=${postImg}&text=${postTitle}`
    );

    this.pinterestBtn.setAttribute(
      "href",
      `https://pinterest.com/pin/create/bookmarklet/?media=${postImg}&url=${postUrl}&description=${postTitle}`
    );

    this.linkedinBtn.setAttribute(
      "href",
      `https://www.linkedin.com/shareArticle?url=${postImg}&title=${postTitle}`
    );

    this.whatsappBtn.setAttribute("href", `https://wa.me/share?url=${postImg}`);
  }
  ngAfterViewInit(): void {
    this.waitForQRCodeRender();
  }

  private waitForQRCodeRender() {
    const interval = setInterval(() => {
      const canvas = document.querySelector('#qrCodeImage canvas');
      if (canvas) {
        clearInterval(interval);
        console.log("clearinterval");
      }
    }, 100);
  }
  navigateBack() {
    var navigateBackUrl = localStorage.getItem("userprofileqrCode");
    if (navigateBackUrl == "followers") {
      localStorage.removeItem("userprofileqrCode");

      this.router.navigate(["/followers"]);
    } else if (navigateBackUrl == "user-profile") {
      localStorage.removeItem("userprofileqrCode");

      this.router.navigate(["/user-profile"]);
    }
  }

  createFlow() {
    this.router.navigate(["/create-flow"]);
  }
  loadSteps(steps) {
    steps.forEach((element) => {
      this.dataSourceSteps.push({
        title: element.title,
        url: element.url,
        description: element.description,
        id: element.rowKey,
        ShowImage: this.showimage,
      });
    });
  }
  getProfileUser(): void {
    if (this.emailAddress != null) {
      
      this.showNotificationCount = true;
      
      const flowQueryModel = new UserQueryModel();
      flowQueryModel.otherUserEmail = this.emailAddress 

      this.userserviceService
        .viewUserProfile(flowQueryModel)
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;

          this.userDisplayName = this.name;
        });
    }
  }

  generateQRCode() {
    if (this.qrcodename == "") {
      this.display = false;
      return;
    } else {
      this.value = this.qrcodename;
    }
  }

  onDownloadQRCode() {
    this.downloadQRCode();
  }

  downloadQRCode() {
    const canvas = document.querySelector('#qrCodeImage canvas') as HTMLCanvasElement;
    console.log(canvas);
    if (!canvas) {
      console.error("QR code canvas not found!");
      return;
    }

    const imageUrl = canvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'qr_code.png';
    link.click();
  }
  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }
  close() {
    this.dialogRef.close();
  }
}
