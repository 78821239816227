import { Component, Inject, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { DataService } from "src/app/Services/dataservice.service";
import { EventEmitterService } from "src/app/Services/event-emitter.service";
import { UserserviceService } from "src/app/Services/userservice.service";

import { DOCUMENT } from "@angular/common";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { ContactUsService } from "src/app/Services/contactus.service";
import { prodcmsconfig } from "src/app/shared/cms/cmsconfig";
import { ContentQueryModel } from "src/app/shared/cms/contentquerymodel";
import { ContentTypes } from "src/app/shared/enums/content.types";
import { GetContentService } from "src/app/Services/getcontent.service";


@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
  public ownerForm: UntypedFormGroup;
  messaage: string;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  SlideOptions: any;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  isLogin = false;
  activeSection = 1;
  checkbox: boolean;
  noResultReturned: boolean;
  isLoading: boolean;
  whatPageObj: any;
  whyPageObj: any;
  howPageObj: any;
  headingPageObj: any;
  extraHeadingPageObj: any;
  extraPageObj: any;
  whyparaPageObj: any;
  communityPageObj: any;

  constructor(
    private flowServiceService: FlowServiceService,
    private dataService: DataService,
    private router: Router,
    public route: Router,
    private data: DataService,
    private userserviceService: UserserviceService,
    private getcontentService: GetContentService,
    private eventEmitterService: EventEmitterService,
    private toastr: ToastrService,
    private contactusService: ContactUsService,
    @Inject(DOCUMENT) private document: any
  ) { }

  fullPageScroll(e: HTMLElement, i) {
    // this.pageScrollService.scroll({
    //   scrollTarget: e,
    //   document: this.document,
    // });
    console.log(e);
    //this.scrollToSection()
    this.scrollsectionToSection(e);
    this.activeSection = i;
  }

  scrollsectionToSection(section: HTMLElement): void {
    if (section) {
      //const element = document.getElementById(section);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  scrollToSection(section: string | null): void {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  onSrollChangeColor() {
    if (
      document.body.scrollTop > 400 ||
      document.documentElement.scrollTop > 400
    ) {
      document.getElementById("myP").className = "step-section nav-items";
    } else {
      document.getElementById("myP").className = "";
    }
  }

  linkClicked() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment) {
          var element = document.querySelector("#" + tree.fragment);
          if (element.className == "section-1") {
            element.className = "section-1 activesection1";
          }
          if (element.className == "section-2") {
            element.className = "section-2 activesection2";
          }
          // element.className="section-5 active";
          if (element) {
            element.scrollIntoView(true);
          }
        }
      }
    });
  }

  openPlayStoreLink() {
    window.open("https://play.google.com/store/apps/details?id=com.shnewframework.Flowchest", '_blank');
  }
  openAppleStoreLink() {
    window.open("https://apps.apple.com/gb/app/flowchest/id1606597068", '_blank');
  }

  ngOnInit(): void {
    this.flowServiceService.setScrollTop();
    this.ownerForm = new UntypedFormGroup({
      name: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(60),
      ]),
      message: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(100),
      ]),
      checkbox: new UntypedFormControl(false, Validators.requiredTrue),
      email: new UntypedFormControl("", [Validators.required, Validators.email]),
    });

    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }

    if (this.route.url !== "/" || localStorage.getItem("isLogin") === "Yes") {
      this.isLogin = true;
    } else {
      this.data.currentMessagebool.subscribe((res) => (this.isLogin = res));
    }

    this.eventEmitterService.callEvent();
    this.eventEmitterService.setHomePageIconColorEvent("home");

    if (this.eventEmitterService.setProfilePictureCompleted === undefined) {
      this.eventEmitterService.setProfilePictureCompleted =
        this.eventEmitterService.setProfilePictureFunction.subscribe(
          (profilePicture: string) => {
            this.showDefaultImage = false;
            this.profilePicture = profilePicture;
          }
        );
    }

    this.getWhatPageDetails();
    this.getHowPageDetails();
    this.getWhyPageDetails();
    this.getExtraPageDetails();
    this.getCommunityPageDetails();
  }

  currentSection = "section1";

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }
  async getWhatPageDetails() {
    this.noResultReturned = true;
    this.isLoading = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.What,
      recaptchaValue: prodcmsconfig.environmentKey,
    });
    (await this.getcontentService.getContentByContentQueryModel(
      contentQueryModel
    )).subscribe((data) => {

      this.whatPageObj = data;
      this.headingPageObj = data[0].headingModels[0];
      this.noResultReturned = false;
    });

  }
  async getExtraPageDetails() {
    this.noResultReturned = true;
    this.isLoading = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.ExtraSection,
      recaptchaValue: prodcmsconfig.environmentKey,
    });
    (await this.getcontentService.getContentByContentQueryModel(
      contentQueryModel
    )).subscribe((data) => {

      this.extraPageObj = data;
      this.extraHeadingPageObj = data[0].headingModels[0];
      this.noResultReturned = false;
    });

  }
  async getWhyPageDetails() {
    this.noResultReturned = true;
    this.isLoading = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.Why,
      recaptchaValue: prodcmsconfig.environmentKey,
    });
    (await this.getcontentService.getContentByContentQueryModel(
      contentQueryModel
    )).subscribe((data) => {
      this.whyPageObj = data;
      this.whyparaPageObj = data[1].multipleObjectParentModels[0].tabsWithParagraphModels;
      this.noResultReturned = false;
    });

  }


  async getHowPageDetails() {
    this.noResultReturned = true;
    this.isLoading = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.How,
      recaptchaValue: prodcmsconfig.environmentKey,
    });
    (await this.getcontentService.getContentByContentQueryModel(
      contentQueryModel
    )).subscribe((data) => {
      this.howPageObj = data[0];
      this.noResultReturned = false;
    });

  }

  async getCommunityPageDetails() {
    this.noResultReturned = true;
    this.isLoading = true;
    var contentQueryModel = new ContentQueryModel({
      applicationKey: prodcmsconfig.applicationKey,
      environmentKey: prodcmsconfig.environmentKey,
      contentType: ContentTypes.Community,
      recaptchaValue: prodcmsconfig.environmentKey,
    });
    (await this.getcontentService.getContentByContentQueryModel(
      contentQueryModel
    )).subscribe((data) => {
      this.communityPageObj = data[0];
      this.noResultReturned = false;
    });

  }
  scrollTo(section) {
    document.querySelector("#" + section).scrollIntoView();
  }
  submit() {
    var contactus = {
      Message: this.messaage,
      Name: this.name,
      Email: this.email,
    };

    this.contactusService.postContactUs(contactus).subscribe((data) => {

      this.toastr.show("Your contact us request is submitted. Thank you");
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.ownerForm.controls[controlName].hasError(errorName);
  };

  getProfileUser(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;

      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.UserData.profilePicUrl === "" ||
            data.UserData.profilePicUrl == null
          ) {

            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.UserData.profilePicUrl;
            this.eventEmitterService.setProfilePictureEvent(
              this.profilePicture
            );
          }
          this.email = data.UserData.emailAddress;
          this.phone = data.UserData.phoneNumber;
          this.info = data.UserData.info;
          this.website = data.UserData.website;
          this.userDisplayName = this.uname = data.UserData.userName;
          this.name = data.UserData.firstName;
        });
    }
  }
  instaLink() { }
}
