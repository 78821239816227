import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-welcomedialog",
  templateUrl: "./welcomedialog.component.html",
  styleUrls: ["./welcomedialog.component.scss"],
})
export class WelcomedialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  dontshowagain() {
    localStorage.setItem("dontshowagain", "dontshowagain");
  }
}
