import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogData } from "../yesnodialog/yesnodialog.component";

@Component({
  selector: "app-cancal-flow-dialog",
  templateUrl: "./cancal-flow-dialog.component.html",
  styleUrls: ["./cancal-flow-dialog.component.scss"],
})
export class CancalFlowDialogComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  leave() {
    this.router.navigate(["/grid"]);
  }

  onNoClick() {
    this.router.navigate(["/create-flow"]);
    location.reload();
  }

  savedraft() {
    localStorage.setItem("create-flow-savedraft", "create-flow-call-savedraft");
    this.router.navigate(["/create-flow"]);
    location.reload();
  }
}
