export class FlowQueryModel {
    category: string = '';
    searchString: string = '';
    userEmail: string = '';
    pageIndex: number = 0;
    partitionKey: string = '';
    rowKey: string = '';
    FlowId: string = '';
    customerId: string = '';
    BundleId: string = '';
    FlowType:string='';
    constructor(data?: Partial<FlowQueryModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export class UserQueryModel {
    category: string = '';
    searchString: string = '';
    userEmail: string = '';
    otherUserEmail: string = '';
    pageIndex: number = 0;
    FlowId: string = '';
    customerId: string = '';
    BundleId: string = '';
    constructor(data?: Partial<UserQueryModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}