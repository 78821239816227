import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-show-flow-image-dialog",
  templateUrl: "./show-flow-image-dialog.component.html",
  styleUrls: ["./show-flow-image-dialog.component.scss"],
})
export class ShowFlowImageDialogComponent implements OnInit {
  imageSource: any;
  itemsRequired: number;
  showVideoOrImage: boolean;
  comment: string;
  comments = [];
  name: string;
  showNotificationCount: boolean;
  notificationCount: number;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  profilePicture: string;
  stepText: string;
  userDisplayName: any;
  userData: any;
  flowData: any;
  showvideo: boolean;
  showimage: boolean;
  imageURI: any;
  videoURI: any;
  displayedColumns: string[] = ["position", "name"];
  dataSource = [];
  dataSourceSteps = [];
  showDefaultImage: boolean;
  description: any;
  nextCount: number;
  currentImage: any;
  previousIndex: any;
  likecolor: any;
  viewCount: any;
  viewText: any;
  followButtonText: any;
  followingcolor: any;
  followingbgcolor: any;
  showFollow: any;
  isFirstSlide: any;
  isLastSlide: any;
  progressPercentage: number;
  progressBy: number;
  stepCount: number;
  bookmarkcolor: any;
  stepNumber: number;
  isMobileView: boolean;
  carouselOptions: any;
  showMenu: boolean;
  IsSteps: boolean;
  categories: any[] = [];
  videoPlayCounter: any = [];
  viewUnloaded: boolean;
  _steps: any = [];
  stepId: number;
  coverImageUri: string;
  title: string;
  inames: any[];
  flowId: any;
  CategoryName: string;
  flowImage: any;
  stepImage: any;
  flowImageToShow: any;
  coverImageToShow: any;
  itemsRequiredImageToShow: string;

  constructor(
    private activateRouter: ActivatedRoute,
    private dataService: DataService,
    private userserviceService: UserserviceService,
    private flowServiceService: FlowServiceService,
    private toastr: ToastrService,
    public formbuilder: UntypedFormBuilder,
    public router: Router,
    
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.flowImageToShow = localStorage.getItem("flowImageToShow");
    this.itemsRequiredImageToShow = localStorage.getItem(
      "itemsRequiredImageToShow"
    );
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.showVideoFromFile(this.flowImageToShow);
  }

  setFlowDetails(flowData) {
    this.imageSource = this.flowData.url;
    this.flowId = this.flowData.rowKey;
    this.description = this.flowData.description;
    this.nextCount = 0;
    this.itemsRequired = 0;
    this.stepCount = 0;
    var stepIncrementCount = 0;

    if (this.flowData === "default message") {
      this.flowData = JSON.parse(localStorage.getItem("recentflow"));
    }

    if (this.flowData.components.length > 0) {
      this.dataSource = this.flowData.components;
      stepIncrementCount = 2;
    } else {
      stepIncrementCount = 1;
    }

    const steps = this.flowData.steps.length + stepIncrementCount;
    this.progressBy = 100 / steps;
    this.progressPercentage = 100 / steps;

    if (this.flowData.steps.length > 0) {
      this.IsSteps = true;
      this.loadSteps(this.flowData.steps);
    }

    if (
      this.userData != null &&
      this.userData.emailAddress != this.flowData.customerEmail
    ) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
  }

  loadSteps(steps) {
    steps.forEach((element) => {
      this.dataSourceSteps.push({
        title: element.title,
        url: element.url,
        description: element.description,
        id: element.rowKey,
        ShowImage: this.showimage,
      });
    });
  }

  showVideoFromFile(filePath: string) {
    if (filePath.includes(".mp4")) {
      this.showVideoOrImage = true;
    } else {
      this.showVideoOrImage = false;
    }
  }

  getFlowDetails(flowId) {
    
    var email = "";

    if (this.userData != null) {
      email = this.userData.emailAddress;
    }
    
    var flowQueryModel = new FlowQueryModel({
      FlowId: flowId,
      userEmail: email
    });

    this.flowServiceService.getFlowById(flowQueryModel).subscribe((data) => {
      
      this.flowData = data.flows;
      this.setFlowDetails(this.flowData);
    });
  }
}
