import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { ToastrService } from "ngx-toastr";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-vouchercode",
  templateUrl: "./vouchercode.component.html",
  styleUrls: ["./vouchercode.component.scss"],
})
export class VouchercodeComponent implements OnInit {
  userData: any;
  dateRange: UntypedFormGroup;
  percentage: any;
  detailsMissing: boolean;
  voucherCode: any;
  contentType: any;
  isVoucherCodeTaken: boolean;

  constructor(
    private flowService: FlowServiceService,
    public router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VouchercodeComponent>
  ) {
    this.isVoucherCodeTaken = false;
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    this.dateRange = new UntypedFormGroup({
      start: new UntypedFormControl("", Validators.required),
      end: new UntypedFormControl("", Validators.required),
      percentage: new UntypedFormControl("", Validators.required),
      voucherCode: new UntypedFormControl("", Validators.required),
    });


  }



  generate() {
    if (this.userData != null) {
      if (
        this.dateRange.value.start != null &&
        this.dateRange.value.end != null &&
        this.percentage > 0 &&
        this.percentage < 100 &&
        this.contentType != null &&
        this.contentType != "" &&
        this.voucherCode != null &&
        this.voucherCode != ""
      ) {
        var voucherCodeModel = {
          UserEmail: this.userData.emailAddress,
          FromDate: new Date(this.dateRange.value.start).toISOString(),
          ToDate: new Date(this.dateRange.value.end).toISOString(),
          Percentage: this.percentage,
          ContentType: this.contentType,
          Code: this.voucherCode,
        };

        this.detailsMissing = false;
        this.flowService.getVoucherCode(voucherCodeModel).subscribe((data) => {
          this.voucherCode = data.code;
          this.dialogRef.close();
          if (data.code != null) {
            this.toastr.success("Discount code created successful");
          } else {
            this.isVoucherCodeTaken = true;
            this.delay(1000).then(() => {
              this.isVoucherCodeTaken = false;
            });
          }
        });
      } else {
        this.detailsMissing = true;
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  close() {
    this.dialogRef.close();
  }
}
