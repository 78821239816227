// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textcolor {
  color: white;
}

.textbodycolor {
  color: #A6A8AA;
}

.tp-card {
  width: 100%;
}

table {
  width: 100%;
}

.pagebackground {
  background-color: #434244;
}

.item {
  border: solid 1px;
  margin: 5px;
}

.item .img {
  height: 200px !important;
  background-color: lightgray;
}

.mat-badge-content {
  background: #5ea1d8;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/viewbyid/viewbyid.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEE;EACI,cAAA;AACN;;AAGE;EACI,WAAA;AAAN;;AAGE;EACE,WAAA;AAAJ;;AAGE;EACE,yBAAA;AAAJ;;AAGE;EAGE,iBAAA;EACA,WAAA;AAFJ;;AAKE;EACG,wBAAA;EACD,2BAAA;AAFJ;;AAOE;EACE,mBAAA;AAJJ","sourcesContent":[".textcolor {\n    color: white;\n  }\n\n  .textbodycolor {\n      color: #A6A8AA;\n    }\n\n\n  .tp-card {\n      width: 100%;\n   }\n\n  table {\n    width: 100%;\n  }\n\n  .pagebackground {\n    background-color: #434244;\n  }\n\n  .item {\n    // height: 220px !important;\n    // background-color: lightgray;\n    border: solid 1px;\n    margin: 5px;\n  }\n\n  .item .img {\n     height: 200px !important;\n    background-color: lightgray;\n    // margin: 5px;\n\n  }\n\n  .mat-badge-content {\n    background:#5ea1d8;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
