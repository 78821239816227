import { Component, OnInit, ViewChild } from "@angular/core";
import { QrScannerComponent } from "angular2-qrscanner";

@Component({
  selector: "app-capture-image",
  templateUrl: "./capture-image.component.html",
  styleUrls: ["./capture-image.component.scss"],
})
export class CaptureImageComponent implements OnInit {
  qrCodeResult: any;
  @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.qrScannerComponent.getMediaDevices().then((devices) => {
      const videoDevices: MediaDeviceInfo[] = [];
      for (const device of devices) {
        if (device.kind.toString() === "videoinput") {
          videoDevices.push(device);
        }
      }
      if (videoDevices.length > 0) {
        let choosenDev;
        for (const dev of videoDevices) {
          if (dev.label.includes("front") || dev.label.includes("rear")) {
            choosenDev = dev;
            break;
          }
        }
        if (choosenDev) {
          this.qrScannerComponent.chooseCamera.next(choosenDev);
        } else {
          this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
        }
      }
    });

    this.qrScannerComponent.capturedQr.subscribe((result) => {
      this.qrCodeResult = result;
    });
  }
}
