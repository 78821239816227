// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textcolor {
  color: white;
}

#page-container {
  scroll-behavior: smooth;
  padding: 2rem 3rem;
}
#page-container a {
  color: #5ea1d8;
}

ul {
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
  margin-bottom: 20px;
}
ul li {
  font-family: "Raleway";
  font-weight: 500 !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  color: #212427 !important;
  line-height: 16px;
  margin: 10px 0px !important;
  line-height: 26px !important;
  padding-left: 0px;
}

ul.counter {
  padding-left: 0px;
  list-style: none;
}
ul.counter li:before {
  counter-increment: my-sec-counter;
  content: "" counter(my-sec-counter) ". ";
}

ul.counter1 {
  padding-left: 30px;
  list-style: none;
  counter-reset: my-sec-counter;
}
ul.counter1 li:before {
  counter-increment: my-sec-counter;
  content: "" counter(my-sec-counter) ". ";
  margin-left: -15px;
}

@media (max-width: 767px) {
  #page-container {
    padding: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/termsandconditions/termsandconditions.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,uBAAA;EACA,kBAAA;AAEF;AADE;EACE,cAAA;AAGJ;;AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;AAGF;AAFE;EACE,sBAAA;EACF,2BAAA;EACA,0BAAA;EACA,qCAAA;EACA,yBAAA;EACA,iBAAA;EACA,2BAAA;EACA,4BAAA;EACA,iBAAA;AAIF;;AADA;EACE,iBAAA;EACA,gBAAA;AAIF;AAFI;EACE,iCAAA;EACN,wCAAA;AAIA;;AAAA;EACE,kBAAA;EACA,gBAAA;EACA,6BAAA;AAGF;AAAI;EACE,iCAAA;EACN,wCAAA;EACA,kBAAA;AAEA;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".textcolor {\n  color: white;\n}\n#page-container{\n  scroll-behavior: smooth;\n  padding:2rem 3rem;\n  a{\n    color:#5ea1d8;\n  }\n}\nul{\n  margin:0px;\n  padding:0px;\n  padding-left:15px;\n  margin-bottom:20px;\n  li{\n    font-family: \"Raleway\";\n  font-weight: 500 !important;\n  font-size: 14px !important;\n  color: rgba(0, 0, 0, 0.54) !important;\n  color:#212427 !important;\n  line-height: 16px;\n  margin: 10px 0px !important;\n  line-height: 26px !important;\n  padding-left:0px;\n  }\n}\nul.counter{\n  padding-left:0px;\n  list-style:none;\n  li{\n    &:before{\n      counter-increment: my-sec-counter;\ncontent: \"\" counter(my-sec-counter) \". \";\n    }\n  }\n}\nul.counter1{\n  padding-left:30px;\n  list-style:none;\n  counter-reset: my-sec-counter;\n  li{\n    // list-style: upper-roman;\n    &:before{\n      counter-increment: my-sec-counter;\ncontent: \"\" counter(my-sec-counter) \". \";\nmargin-left:-15px;\n    }\n  }\n}\n@media(max-width:767px){\n  #page-container{\n    padding:15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
