// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-popup {
  background: #fff;
  padding: 20px 0px;
}
.new-popup .btn {
  font-family: Raleway;
  background-color: #5ea1d8;
  font-size: 15px;
  cursor: pointer;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
}
.new-popup .close-ico {
  display: none;
}
.new-popup button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
}
.new-popup h6 {
  font-size: 22px;
  margin: 10px 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/vouchercode/vouchercode.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;AAAE;EACE,oBAAA;EACA,yBAAA;EACA,eAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,UAAA;EACA,6BAAA;EACA,SAAA;EACA,wBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,SAAA;AAEJ;AAAE;EACE,eAAA;EACA,yBAAA;AAEJ","sourcesContent":[".new-popup {\n  background: #fff;\n  padding: 20px 0px;\n  .btn {\n    font-family: Raleway;\n    background-color: #5ea1d8;\n    font-size: 15px;\n    cursor: pointer;\n    color: #fff;\n    padding: 10px;\n    border-radius: 10px;\n  }\n  .close-ico {\n    display: none;\n  }\n  button.close {\n    padding: 0;\n    background-color: transparent;\n    border: 0;\n    -webkit-appearance: none;\n    appearance: none;\n    position: absolute;\n    z-index: 10;\n    right: 10px;\n    top: 10px;\n  }\n  h6 {\n    font-size: 22px;\n    margin: 10px 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
