import { Component, OnInit } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";

@Component({
  selector: "app-validation",
  templateUrl: "./validation.component.html",
  styleUrls: ["./validation.component.scss"],
})
export class ValidationComponent implements OnInit {
  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(" ") >= 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }
}
