import { Component, Input, OnInit } from '@angular/core';

// Helpers

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
})
export class PreviewImagesComponent implements OnInit {
  loadMore: any;
  noResultReturned: any;
  blog: any;
  imageUrl: any;
  @Input() rowKey!: string;

  constructor() {}
  ngOnInit(): void {
    this.blog = JSON.parse(localStorage.getItem('selectedBlogContent'));
    this.imageUrl = this.blog.coverImageUrl;
  }
  onRightClick($event: any) {}
  loadMoreblogs() {}
}
