import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DataService } from "src/app/Services/dataservice.service";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";
import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-restricted-access",
  templateUrl: "./restricted-access.component.html",
  styleUrls: ["./restricted-access.component.scss"],
})
export class RestrictedAccessComponent implements OnInit {
  allUsers: any;
  tempUsers: any;
  userData: any;
  profilePicture: string;
  name: string;
  email: string;
  website: string;
  phone: string;
  info: string;
  uname: string;
  showDefaultImage: boolean;
  input: string = "";
  isdisabled: boolean;
  flowModel: any;
  _steps: any[];
  coverImageUri: string;
  stepsImageUri: string;
  stepDescription: string;
  title: string;
  CategoryName: string;
  inames: any[];
  accessType: string;
  description: string;
  userDisplayName: any;
  showUsers: boolean;
  noResultReturned: boolean;
  flowData: any;
  flowCoverImageUrl: any;
  private searchTerms = new Subject<string>();

  constructor(
    private userserviceService: UserserviceService,
    private router: Router,

    private flowServiceService: FlowServiceService,
    private dataService: DataService,
    public dialogRef: MatDialogRef<RestrictedAccessComponent>
  ) {
    this.flowModel = JSON.parse(localStorage.getItem("flowModel"));
    this.searchTerms
      .pipe(debounceTime(2000)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        this.performUsersSearch(0);
      });
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.flowData = JSON.parse(localStorage.getItem("flowModel"));
    this.flowCoverImageUrl = "url(" + '"' + this.flowData.url + '"' + ")";
    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
    }
    localStorage.getItem("flowmodel");
    // 
    this.allUsers = [];
    this.tempUsers = [];
    localStorage.removeItem("searchuserpageIndex");
    localStorage.setItem("searchuserpageIndex", "0");

    this.getPaginatedUsers(undefined, "0");
  }
  performUsersSearch(pageIndex) {
    this.noResultReturned = true;
    this.allUsers = [];
    this.tempUsers = [];
    var email = "";
    if (this.userData != null && this.userData != undefined) {
      this.showUsers = false;
      email = this.userData.emailAddress;
    }
    this.showUsers = true;
    var flowQueryModel = new FlowQueryModel({
      category: "Users",
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex,
    });

    this.userserviceService
      .searchInUsers(flowQueryModel)
      .subscribe((data) => {
        this.noResultReturned = false;

        if (data.allUsers.length > 0) {
          this.tempUsers = [];
          this.allUsers = [];
          data.allUsers.forEach((element) => {
            console.log(element);
            const user = {
              user: element,
              showFollow: false,
              followButtonText: element.followingText,
              EmailAddress: element.emailAddress
            };
            this.allUsers.push(user);
          });

          this.tempUsers = this.allUsers;
          console.log(this.tempUsers);
          this.tempUsers.forEach((element) => {
            if (element.followButtonText === "Following") {
              element.followButtonText = "Following";
              element.followingcolor = "black";
              element.followingbgcolor = "white";
            } else {
              element.followButtonText = "Follow";
              element.followingcolor = "white";
              element.followingbgcolor = "#5ea1d8";
            }
          });
        }
      });
  }
  getPaginatedUsers(infiniteScroll, pageIndex) {
    var email = "";

    if (this.userData != null && this.userData != undefined) {
      email = this.userData.emailAddress;
    }

    var flowQueryModel = new FlowQueryModel({
      category: "Users",
      searchString: this.input,
      userEmail: this.email,
      pageIndex: pageIndex,
    });

    if (this.input !== '' && this.input !== undefined) {
      this.performUsersSearch(pageIndex);
    }
    else {
      this.userserviceService
        .getAllUsers(flowQueryModel)
        .subscribe((data) => {

          if (data.allUsers.length > 0) {
            this.tempUsers = [];
            data.allUsers.forEach((element) => {
              const user = {
                FlowId: element.rowKey,
                user: element,
                UserName: element.userName,
                ProfilePicUrl: element.profilePicUrl,
                FirstName: element.firstName,
                EmailAddress: element.emailAddress,
                showFollow: false,
                followButtonText: element.followingText,
              };
              this.allUsers.push(user);
            });
            this.tempUsers = this.allUsers;
            console.log(this.tempUsers);
            this.tempUsers.forEach((element) => {
              if (element.followButtonText === "Following") {
                element.followButtonText = "Following";
                element.followingcolor = "black";
                element.followingbgcolor = "white";
              } else {
                element.followButtonText = "Follow";
                element.followingcolor = "white";
                element.followingbgcolor = "#5ea1d8";
              }
            });
          }
        });
      this.showUsers = true;
    }
  }

  getAllUsers() {
    localStorage.removeItem("searchuserpageIndex");
    localStorage.setItem("searchuserpageIndex", "0");

    this.allUsers = [];
    this.tempUsers = [];
    this.getPaginatedUsers(undefined, "0");
    console.log(this.tempUsers);
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }
  publish() {
    this.noResultReturned = true;
    //  
    if (this.userData != null) {
      console.log(users);
      var users = this.allUsers.filter((x) => x.user.IsAccessGiven);
      console.log(users);
      console.log(this.allUsers);
      this.flowModel.restrictedAccessFlows = users;
      this.flowServiceService
      .publishUserFlow(this.flowModel, this.userData.emailAddress)
      .subscribe((data) => {
        this.close();
        this.router.navigate(["/user-profile"]);
        this.noResultReturned = false; 
      }); 
    }
  }
  close() {
    this.dialogRef.close();
  }

  viewUserProfile(selectedUser) {
    if (this.userData != null) {
      if (
        this.userData.emailAddress === selectedUser.customerEmail ||
        this.userData.emailAddress === selectedUser.emailAddress
      ) {
        this.router.navigate(["/user-profile"]);
      } else {
        this.router.navigate(["/view-profile"]);
      }
      this.dataService.flowByCategorieschangeMessage(selectedUser);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  searchUsers() {
    this.searchTerms.next(this.input);

  }

  accessChanged() {
    var value = this.allUsers.filter((x) => x.IsAccessGiven)[0];
    if (value == null && value == undefined) {
      this.isdisabled = true;
    } else {
      this.isdisabled = false;
    }
  }

  setRestrictedUsers() {
    if (
      this.flowModel.restrictedAccessFlows != null &&
      this.flowModel.restrictedAccessFlows != undefined
    ) {
      this.isdisabled = false;
      this.flowModel.restrictedAccessFlows.forEach((element) => {
        var userAccess = this.allUsers.filter(
          (x) => x.emailAddress == element.emailAddress
        )[0];
        userAccess.isAccessGiven = true;
      });

      this.allUsers = this.allUsers.sort((u) => u.isAccessGiven).reverse();
    }
  }

  getProfileUser(): void {
    if (this.userData != null) {

      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {

            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.uname = data.userData.userName;
          this.name = data.userData.firstName;
        });
    }
  }
}
