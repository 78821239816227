import { Router } from "@angular/router";
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { FlowServiceService } from "src/app/Services/flow-service.service";
import { UserserviceService } from "src/app/Services/userservice.service";

import { DataService } from "src/app/Services/dataservice.service";
import { EventEmitterService } from "src/app/Services/event-emitter.service";
import { TitleandmetaserviceService } from "src/app/Services/titleandmetaservice.service";
import { FlowQueryModel } from "src/app/models/flowquerymodel";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit {
  isMobileView: boolean;
  flows: any = [];

  userData: any;
  profilePicture: string;
  showDefaultImage: boolean;
  categories: any[] = [];
  userDisplayName: any;
  itemCategory: any;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  flowData: any;
  userDisplayEmail: any;
  showNotificationCount: boolean;
  notificationCount: number;
  CategoryName: any;
  notificationtitle: any;
  notificationbody: any;
  notificationsarray: any = [];
  notificationscount: number;
  getNotificationUrl: string;

  constructor(
    private eventEmitterService: EventEmitterService,
    private flowServiceService: FlowServiceService,
    private dataService: DataService,
    private userserviceService: UserserviceService,
    private router: Router,
    
    private ref: ChangeDetectorRef,
    private titleandmetaserviceService: TitleandmetaserviceService
  ) {
    this.titleandmetaserviceService.setTitle("Category Page");
    this.categories = [];
    this.userDisplayEmail = "";
    this.getNotificationUrl = "v1/NotificationController/GetUserNotifications";
  }

  ngOnInit() {
    this.flowServiceService.setScrollTop();
    const width = window.innerWidth || document.body.clientWidth;

    if (width <= 1024) {
      this.isMobileView = true;
    }

    this.flows = [];
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));

    if (this.userData != null) {
      this.userDisplayName = this.userData.firstName;
      this.userDisplayEmail = this.userData.emailAddress;
      this.email = this.userData.emailAddress;
      this.phone = this.userData.phoneNumber;
      this.info = this.userData.info;
      this.website = this.userData.website;
      this.uname = this.userData.userName;
      this.name = this.userData.firstName;
      this.getProfileUser();
    }

    this.eventEmitterService.setNotificationCompleted =
      this.eventEmitterService.setNotificationFunction.subscribe(
        (notificationModelArray: any) => {
          this.notificationsarray = [];
          this.showNotificationCount = true;
          const notifications = JSON.parse(notificationModelArray);
          notifications.forEach((element) => {
            this.notificationsarray.push(element.notification);
          });
          this.notificationscount = this.notificationsarray.length;
          this.ref.detectChanges();
        }
      );
  }
  // getFlows(): void {
  //   this.flowServiceService
  //     .getFlows(this.userDisplayEmail)
  //     .subscribe((heroes) => {
  //       this.categories = heroes.Flows;
  //       
  //     });
  // }
  
  openDialog(){}

  getnotification() {
    this.notificationsarray = [];
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress,
      pageIndex: 0,
    });
    
    this.flowServiceService
      .getNotifications(flowQueryModel)
      .subscribe((data) => {
        if (data.userNotifications != null) {
          data.userNotifications.forEach((element) => {
            var notification = {
              notification: {
                ProfilePicUrl: element.profilePicUrl,
                NotifierUserName: element.notifierUserName,
                Message: element.message,
                FlowUrl: element.flowUrl,
                FlowId: element.flowId,
                NotifierUserEmail: element.notifierUserEmail,
              },
            };
            this.notificationsarray.push(notification);
          });
        }
      });
  }

  avatarClicked(notification: any, source: any) {
    if (source == "flow clicked") {
      localStorage.setItem("notificationflowId", notification.rowKey);
      localStorage.setItem("flow", null);
      this.router.navigate(["/view/" + notification.rowKey]);
    } else if (source == "profile clicked") {
      this.router.navigate(["/view-profile"]);
    }
  }

  checkLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    } else {
      this.router.navigate(["/create-flow"]);
    }
  }

  checkIsUserLoggedIn() {
    if (this.userData === null) {
      this.router.navigate(["/login"]);
    }
  }

  flowByCategory(itemCategory) {
    localStorage.setItem("selectedCategoryName", itemCategory);
    this.router.navigate(["/create-flow"]);
  }

  getProfileUser(): void {
    if (this.userData != null) {
      
      this.showNotificationCount = true;
      this.userserviceService
        .getProfileUser()
        .subscribe((data) => {
          if (
            data.userData.profilePicUrl === "" ||
            data.userData.profilePicUrl == null
          ) {
            this.showDefaultImage = true;
          } else {
            this.showDefaultImage = false;
            this.profilePicture = data.userData.profilePicUrl;
          }
          this.email = data.userData.emailAddress;
          this.phone = data.userData.phoneNumber;
          this.info = data.userData.info;
          this.website = data.userData.website;
          this.userDisplayName = this.uname = data.userData.userName;
          this.name = data.userData.firstName;

          this.getnotification();
        });
    }
    
  }

  SignOut() {
    localStorage.setItem("LoggedInUser", null);
    this.userDisplayName = null;
    this.router.navigate(["/grid"]);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.resize(event);
  }

  resize(event) {
    if (event.srcElement.outerWidth <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  onResize(event) {
    this.resize(event);
  }
}
