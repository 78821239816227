// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.disc {
  margin: 0px;
  padding: 0px;
}
ul.disc li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding-left: 20px;
  text-align: left;
  line-height: 26px;
  margin-bottom: 10px;
  font-size: 16px !important;
}
ul.disc li:before {
  content: "";
  width: 8px;
  height: 8px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 10px;
  left: 0px;
}
ul.disc li::marker {
  opacity: 0px;
}
ul.disc li:last-child {
  margin-bottom: 0px;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/blogs/blogpages/bullet/bullet.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,2BAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,0BAAA;AAEJ;AACI;EACE,WAAA;EACA,UAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;AACN;AACI;EACE,YAAA;AACN;AACI;EACE,kBAAA;AACN;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,0BAAA;AADF","sourcesContent":["ul.disc {\n  margin: 0px;\n  padding: 0px;\n  li {\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    position: relative;\n    padding-left: 20px;\n    text-align: left;\n    line-height: 26px;\n    margin-bottom: 10px;\n    font-size: 16px !important;\n    \n\n    &:before {\n      content: \"\";\n      width: 8px;\n      height: 8px;\n      aspect-ratio: 1/1;\n      border-radius: 50%;\n      background: #000;\n      position: absolute;\n      top: 10px;\n      left: 0px;\n    }\n    &::marker {\n      opacity: 0px;\n    }\n    &:last-child {\n      margin-bottom: 0px;\n    }\n  }\n}\n\n.bold {\n  font-weight: bold;\n}\n\n.italic {\n  font-style: italic;\n}\n\n.underline {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
