// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-add-page {
  width: 75%;
  display: block;
  margin: 5px auto;
}

.square_btn {
  text-align: center;
  padding: 7px 20px;
  border-radius: 25px;
  border: 1px solid #5ea1d8;
  text-decoration: none;
  color: #5ea1d8;
  background-image: linear-gradient(45deg, white 0%, white 100%);
  transition: 0.4s;
  font-family: "Raleway";
}

.square_btn:hover {
  color: white;
  background-image: linear-gradient(45deg, #5ea1d8 0%, #5ea1d8 100%);
}

.square_btn {
  font-family: "Raleway";
  color: #5ea1d8;
}
.square_btn:hover {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/bundledialog/bundledialog.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,qBAAA;EACA,cAAA;EAEA,8DAAA;EAEA,gBAAA;EACA,sBAAA;AAAF;;AAIA;EACE,YAAA;EAEA,kEAAA;AADF;;AAKA;EAEE,sBAAA;EACA,cAAA;AAHF;AAIE;EACE,WAAA;AAFJ","sourcesContent":[".btn-add-page {\n  width: 75%;\n  display: block;\n  margin: 5px auto;\n}\n\n.square_btn {\n  text-align: center;\n  padding: 7px 20px;\n  border-radius: 25px;\n  border: 1px solid #5ea1d8;\n  text-decoration: none;\n  color: #5ea1d8;\n  background-image: -webkit-linear-gradient(45deg, white 0%, white 100%);\n  background-image: linear-gradient(45deg, white 0%, white 100%);\n\n  transition: 0.4s;\n  font-family: \"Raleway\";\n}\n\n\n.square_btn:hover {\n  color: white;\n  background-image: -webkit-linear-gradient(45deg, #5ea1d8 0%, #5ea1d8 100%);\n  background-image: linear-gradient(45deg, #5ea1d8 0%, #5ea1d8 100%);\n}\n\n\n.square_btn {\n \n  font-family: \"Raleway\";\n  color: #5ea1d8;\n  &:hover {\n    color: #fff;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
