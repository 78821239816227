import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-formatted-component',
  templateUrl: './formatted-component.component.html',
  styleUrls: ['./formatted-component.component.scss'],
})
export class PreviewFormattedComponentComponent implements OnInit {
  @Input() inputTextToFormat!: string;
  formattedTextParts: FormattedTextPart[] = [];

  ngOnInit(): void {
    this.formattedTextParts = this.parseFormattedText(this.inputTextToFormat);
  }

  parseFormattedText(inputText: string): FormattedTextPart[] {
    if (inputText == null || inputText.length == 0) {
    }
    const formattedText: FormattedTextPart[] = [];

    let currentText = '';
    let tag = '';

    for (let i = 0; i < inputText.length; i++) {
      if (inputText[i] === '<') {
        if (currentText.trim().length > 0) {
          formattedText.push({ tag: tag, text: currentText });
          currentText = '';
        }

        const endIndex = inputText.indexOf('>', i);
        if (endIndex > 0) {
          const tagContent = inputText.substring(i + 1, endIndex);
          if (tagContent.startsWith('/')) {
            tag = '';
          } else {
            tag = tagContent;
          }
          i = endIndex;
        }
      } else {
        currentText += inputText[i];
      }
    }

    if (currentText.trim().length > 0) {
      formattedText.push({ tag: tag, text: currentText });
    }

    return formattedText;
  }

  isAnchorTag(tag: string): boolean {
    return tag.includes('a');
  }

  getHref(text: string): string {
    // Extract href value from the anchor tag's text
    const hrefMatch = text.match(/href=['"]([^'"]*)['"]/);
    return hrefMatch ? hrefMatch[1] : '';
  }
}

interface FormattedTextPart {
  tag: string;
  text: string;
}
