// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tp-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.tp-full-width {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/employee/employee.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AACC;EACG,WAAA;AAEJ","sourcesContent":[".tp-form {\n    min-width: 150px;\n    max-width: 500px;\n    width: 100%;\n }\n .tp-full-width {\n    width: 100%;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
