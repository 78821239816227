import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Chart, ChartOptions, ChartType } from 'chart.js';
import { FlowServiceService } from 'src/app/Services/flow-service.service';
import { AnalyticsComponent } from '../analyics/analytics.component';
import { FlowQueryModel } from 'src/app/models/flowquerymodel';
import { BundleService } from 'src/app/Services/bundleservice.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-content-analytics',
  templateUrl: './content-analytics.component.html',
  styleUrls: ['./content-analytics.component.scss']
})
export class ContentAnalyticsComponent implements OnInit {
  userData: any;
  userEmail: string;
  noResultReturned: boolean;
  linksClicked: any = [];
  bundleLinksClicked: any = [];
  pageViews: any = [];
  flowViews: any = [];
  bundleViews: any = [];
  linkClickViews: any = [];
  dashboardModel: any;
  flowData: any;
  totalLinkClicks: number = 0;

  @ViewChild('pieChartCanvas', { static: true }) pieChartCanvas: ElementRef<HTMLCanvasElement>;
  public pieChartOptions: any = {
    responsive: true,
  };
  @ViewChild('lineChartCanvas', { static: true }) lineChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('bundlelineChartCanvas', { static: true }) bundlelineChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('flowViewslineChartCanvas', { static: true }) flowViewslineChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('bundleViewslineChartCanvas', { static: true }) bundleViewslineChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('flowLinkClicklineChartCanvas', { static: true }) flowLinkClicklineChartCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('bundleLinkClicklineChartCanvas', { static: true }) bundleLinkClicklineChartCanvas: ElementRef<HTMLCanvasElement>;
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day'
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };

  public lineChartLabels: string[] = [];
  public bundlelineChartLabels: string[] = [];
  public flowviewslineChartLabels: string[] = [];
  public bundleViewslineChartLabels: string[] = [];
  public flowLinkClicklineChartLabels: string[] = [];
  public bundleLinkClicklineChartLabels: string[] = []; 
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;
  public lineChartData: number[] = [];
  public bundlelineChartData: number[] = [];
  public lineFlowViewChartData: number[] = [];
  public lineBundleViewlineChartData: number[] = [];
  public flowlinkClicklineChartData: number[] = [];
  public bundlelinkClicklineChartData: number[] = [];
  returningUsersCount: number = 0;
  newUsersCount: number = 0;

  public pieChartLabels: string[] = ['Label 1', 'Label 2', 'Label 3'];
  public pieChartData: number[] = [300, 500, 200];
  public pieChartType: string = 'pie';
  public pieChartLegend: boolean = true;
  linkClicks: any[] = [];
  totalFlowLinkClicks: number;
  pageViewsBundles: any = [];
  flowLinksClick: any;
  bundleLinksClick: any;
  /**
   *
   */
  constructor(private flowservice: FlowServiceService, private bundleservice: BundleService) {

  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.flowData = JSON.parse(localStorage.getItem("analyticsflow"));
    this.userData = JSON.parse(localStorage.getItem("LoggedInUser"));
    this.userEmail = "";

    if (this.userData != null) {
      this.userEmail = this.userData.emailAddress;
    }
    var flowQueryModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress
    });
    this.getUserAllLinksAnalytics(flowQueryModel);
    this.getUserAllBundleLinksAnalytics(flowQueryModel);
    // this.getPageViews();  
    this.getFlowContentCount();
    this.getBundleContentCount();
    this.getFlowViewsContentCount();
    this.getBundleViewsContentCount();
    this.getFlowLinksContentCount(); 
    this.getBundleLinksContentCount(); 

  }
  calculateTotalLinkClicks() {
    this.totalLinkClicks = 0;
    this.totalFlowLinkClicks = 0;

    for (let content of this.linksClicked) {
      this.totalFlowLinkClicks += content.totalCount;
    }
    for (let content of this.bundleLinksClicked) {
      this.totalLinkClicks += content.totalCount;
    }
    console.log(this.totalLinkClicks);
    console.log(this.totalFlowLinkClicks);
  }
  getUserDashboard(flowQueryModel) {

    var flowModel = new FlowQueryModel({
      FlowType: this.flowData.flowType,
      FlowId: this.flowData.id
    });
    this.noResultReturned = true;
    this.flowservice.getLinkClickDetails(flowModel).subscribe((data) => {
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.linksClicked = data;
    });
  }

  getUserAllLinksAnalytics(flowQueryModel) {

    var flowModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress
    });

    this.noResultReturned = true;
    this.flowservice.getAllLinkClickDetails(flowModel).subscribe((data) => {
      console.log(data);
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.linksClicked = data;
      this.calculateTotalLinkClicks(); 
    });
  }
  getUserAllBundleLinksAnalytics(flowQueryModel) {

    var flowModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress
    });

    this.noResultReturned = true;
    this.bundleservice.getAllBundleLinkClicks(flowModel).subscribe((data) => {
      console.log(data);
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.bundleLinksClicked = data;
      this.calculateTotalLinkClicks(); 
    });

  }

  getFlowContentCount() {
    this.noResultReturned = true;
    var getPageViewDetails = {
      userEmail: this.userData.emailAddress
    };
    this.flowservice.getContentDetails(getPageViewDetails).subscribe((data) => {
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.pageViews = data;
      // this.setNewandReturningUsers();
      this.flowChartData();
    });
  }

  getBundleContentCount() {
    this.noResultReturned = true;
    var getPageViewDetails = {
      userEmail: this.userData.emailAddress
    };
    this.flowservice.getBundleContentDetails(getPageViewDetails).subscribe((data: any) => {
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.pageViewsBundles = data;
      console.log(this.pageViewsBundles);
      // this.setNewandReturningUsers();
      this.bundleChartData();
    });
  }
  getFlowViewsContentCount() {
    this.noResultReturned = true;
    var getPageViewDetails = {
      userEmail: this.userData.emailAddress
    };
    this.flowservice.getContentDetails(getPageViewDetails).subscribe((data) => {
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.flowViews = data;
      // this.setNewandReturningUsers();
      this.flowViewsChartData();
    });
  } 
  getBundleViewsContentCount() {
    this.noResultReturned = true;
    var getPageViewDetails = {
      userEmail: this.userData.emailAddress
    };
    this.flowservice.getBundleContentDetails(getPageViewDetails).subscribe((data: any) => {
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.bundleViews = data;
      console.log(this.bundleViews);
      // this.setNewandReturningUsers();
      this.bundleViewsChartData();
    });
  }
  getFlowLinksContentCount() {

    var flowModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress
    });

    this.noResultReturned = true;
    this.flowservice.getAllLinkClickDetails(flowModel).subscribe((data) => {
      console.log(data);
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.flowLinksClick = data;
      this.calculateTotalLinkClicks(); 
      this.flowLinkClickChartData();
    });
  }
  getBundleLinksContentCount() {

    var flowModel = new FlowQueryModel({
      userEmail: this.userData.emailAddress
    });

    this.noResultReturned = true;
    this.bundleservice.getAllBundleLinkClicks(flowModel).subscribe((data) => {
      console.log(data);
      this.noResultReturned = false;
      // this.dashboardModel = data.DashboardModel; 
      this.bundleLinksClick = data;
      this.calculateTotalLinkClicks(); 
      this.bundleLinkClickChartData();
    });

  }
  flowChartData() {
    this.extractDateRange();
    this.updateChartData();
    this.destroyChart(this.lineChartCanvas);
    this.initLineChart();
  }

  flowViewsChartData() {
    this.extractFlowViewDateRange();
    this.updateFlowViewChartData();
    this.destroyChart(this.flowViewslineChartCanvas);
    this.initFlowViewLineChart();
  }
  flowLinkClickChartData() {
    this.extractFlowLinkClickDateRange();
    this.updateFlowLinkClickChartData();
    this.destroyChart(this.flowLinkClicklineChartCanvas);
    this.initFlowLinkClickLineChart();
  }
  bundleChartData() {
    this.extractBundleDateRange();
    this.updateBundleChartData();
    this.destroyChart(this.bundlelineChartCanvas);
    this.initBundleLineChart();
  }
  bundleViewsChartData() {
    this.extractBundleViewDateRange();
    this.updateBundleViewChartData();
    this.destroyChart(this.bundleViewslineChartCanvas);
    this.initBundleViewLineChart();
  }
  bundleLinkClickChartData() {
    this.extractBundleLinkClickDateRange();
    this.updateBundleLinkClickChartData();
    this.destroyChart(this.bundleLinkClicklineChartCanvas);
    this.initBundleLinkClickLineChart();
  }
  destroyChart(canvas: ElementRef<HTMLCanvasElement>) {
    const ctx = canvas.nativeElement.getContext('2d');
    Chart.getChart(ctx)?.destroy(); // Destroy existing chart instance if it exists
  }

  initLineChart() {
    // Your line chart configuration here
    console.log(this.lineChartLabels);
    const lineChartCtx = this.lineChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.lineChartLabels,
        datasets: [{
          data: this.lineChartData,
          label: 'Flows Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }

  private updateChartData() {
    let totalViews = 0;
    console.log(this.pageViews);
    this.pageViews.forEach(element => {
      totalViews += element.numberOfViews;
      console.log("Updated totalViews:", totalViews);
      this.lineChartData.push(totalViews);
      console.log("Updated lineChartData:", this.lineChartData);
    });
  }
  extractDateRange() {
    if (this.pageViews && this.pageViews.length > 0) {
      // Extract and format dates
      this.pageViews.forEach(element => {
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');
        const formattedDate = datePipe.transform(myDate, 'shortDate');
        this.lineChartLabels.push(formattedDate);
      });

      // Sort dates in ascending order
      this.lineChartLabels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    }
  }

  initBundleLineChart() {
    // Your line chart configuration here
    console.log(this.bundlelineChartData);
    const lineChartCtx = this.bundlelineChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.bundlelineChartLabels,
        datasets: [{
          data: this.bundlelineChartData,
          label: 'Bundles Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }
  private updateBundleChartData() {
    let totalBundleViews = 0;
    console.log(this.pageViewsBundles);
    this.pageViewsBundles.forEach((element: any) => {
      totalBundleViews += element.numberOfViews;
      console.log("Updated totalViews:", totalBundleViews);
      this.bundlelineChartData.push(totalBundleViews);
      console.log("Updated lineChartData:", this.bundlelineChartData);
    });
  }
  extractBundleDateRange() {
    if (this.pageViewsBundles && this.pageViewsBundles.length > 0) {

      this.pageViewsBundles.forEach(element => {
        // Assuming element.CreatedDateTime is a Date object
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');

        // Format the date using the desired format (e.g., 'short')
        const formattedDate = datePipe.transform(myDate, 'shortDate');
        this.bundlelineChartLabels.push(formattedDate);
      });
      // Sort dates in ascending order
      this.bundlelineChartLabels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    }
  }

  initFlowViewLineChart() {
    // Your line chart configuration here
    console.log(this.lineFlowViewChartData);
    const lineChartCtx = this.flowViewslineChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.flowviewslineChartLabels,
        datasets: [{
          data: this.lineFlowViewChartData,
          label: 'Total FlowViews Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }
  private updateFlowViewChartData() {
    this.lineFlowViewChartData = [];
    let totalFlowViews = 0;
    this.flowViews.forEach((element: any) => {
      const flowViewsCount = parseInt(element.totalFlowViews, 10);
      if (!isNaN(flowViewsCount)) {
        totalFlowViews += flowViewsCount;
        this.lineFlowViewChartData.push(totalFlowViews);
      }
    });
  }
  extractFlowViewDateRange() {
    if (this.flowViews && this.flowViews.length > 0) {
      // Extract and format dates
      this.flowViews.forEach(element => {
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');
        const formattedDate = datePipe.transform(myDate, 'shortDate');
        this.flowviewslineChartLabels.push(formattedDate);
      });

      // Sort dates in ascending order
      this.flowviewslineChartLabels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    }
  }

  initBundleViewLineChart() {
    // Your line chart configuration here
    console.log(this.lineBundleViewlineChartData);
    const lineChartCtx = this.bundleViewslineChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.bundleViewslineChartLabels,
        datasets: [{
          data: this.lineBundleViewlineChartData,
          label: 'Total BundleViews Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }
  private updateBundleViewChartData() {
    this.lineBundleViewlineChartData = [];
    let totalBundleViews = 0;
    this.bundleViews.forEach((element: any) => {
      const bundleViewsCount = parseInt(element.totalBundleViews, 10);
      if (!isNaN(bundleViewsCount)) {
        totalBundleViews += bundleViewsCount;
        this.lineBundleViewlineChartData.push(totalBundleViews);
      }
    });
  }
  extractBundleViewDateRange() {
    if (this.bundleViews && this.bundleViews.length > 0) {

      this.bundleViews.forEach(element => {
        // Assuming element.CreatedDateTime is a Date object
        const myDate = element.createdDateTime;
        const datePipe = new DatePipe('en-US');

        // Format the date using the desired format (e.g., 'short')
        const formattedDate = datePipe.transform(myDate, 'shortDate');
        this.bundleViewslineChartLabels.push(formattedDate);
      });
      // Sort dates in ascending order
      this.bundleViewslineChartLabels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    }
  }

  initFlowLinkClickLineChart() {
    // Your line chart configuration here
    console.log(this.flowLinkClicklineChartLabels);
    const lineChartCtx = this.flowLinkClicklineChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.flowLinkClicklineChartLabels,
        datasets: [{
          data: this.flowlinkClicklineChartData,
          label: 'Flow Link Click Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }

  private updateFlowLinkClickChartData() { 
    let totalViews = 0;
    console.log(this.flowLinksClick);
    this.flowLinksClick.forEach(element => {
      totalViews += element.totalCount;
      console.log("Updated totalViews:", totalViews);
      this.flowlinkClicklineChartData.push(totalViews);
      console.log("Updated lineChartData:", this.flowlinkClicklineChartData);
    });
  }
  extractFlowLinkClickDateRange() {
    if (this.flowLinksClick && this.flowLinksClick.length > 0) {
      // Extract and format dates
      this.flowLinksClick.forEach(element => {
        const myDate = element.linkClickModels[0].createdDateTime;
        const datePipe = new DatePipe('en-US');
        const formattedDate = datePipe.transform(myDate, 'shortDate');
        this.flowLinkClicklineChartLabels.push(formattedDate);
      });

      // Sort dates in ascending order
      this.flowLinkClicklineChartLabels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    }
  }

  initBundleLinkClickLineChart() {
    // Your line chart configuration here
    console.log(this.bundlelinkClicklineChartData);
    const lineChartCtx = this.bundleLinkClicklineChartCanvas.nativeElement.getContext('2d');
    const lineChart = new Chart(lineChartCtx, {
      type: 'line',
      data: {
        labels: this.bundleLinkClicklineChartLabels,
        datasets: [{
          data: this.bundlelinkClicklineChartData,
          label: 'Bundle Link Click Over Time',
          borderColor: 'orange',
          fill: false
        }]
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1 // Set the step size to 1 to display integers on the y-axis
            }
          }
        }
      }
    });
  }
  private updateBundleLinkClickChartData() {
    let totalBundleViews = 0;
    console.log(this.bundleLinksClick);
    this.bundleLinksClick.forEach((element: any) => {
      totalBundleViews += element.totalCount;
      console.log("Updated totalViews:", totalBundleViews);
      this.bundlelinkClicklineChartData.push(totalBundleViews);
      console.log("Updated lineChartData:", this.bundlelinkClicklineChartData);
    });
  }
  extractBundleLinkClickDateRange() {
    if (this.bundleLinksClick && this.bundleLinksClick.length > 0) {

      this.bundleLinksClick.forEach(element => { 
          const myDate = element.linkClickModels[0].createdDateTime;
          const datePipe = new DatePipe('en-US'); 
          const formattedDate = datePipe.transform(myDate, 'shortDate');
          this.bundleLinkClicklineChartLabels.push(formattedDate); 
       
      });
      // Sort dates in ascending order
      this.bundleLinkClicklineChartLabels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    }
  }

  setNewandReturningUsers() {
    const uniqueEmails = new Set<string>();

    this.pageViews.forEach(element => {
      // Check if the email is already in the set
      if (element.userEmail != "" && uniqueEmails.has(element.userEmail)) {
        // User is a returning user
        this.returningUsersCount++;
      } else {
        // User is a new user
        // Add the email to the set
        uniqueEmails.add(element.userEmail);
        this.newUsersCount++;
      }
    });

    const ctx = this.pieChartCanvas.nativeElement.getContext('2d');
    var arr = ['New Visitor', 'Returning Visitor'];
    const pieChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: arr,
        datasets: [
          {
            data: [this.newUsersCount, this.returningUsersCount],
            backgroundColor: ['green', 'blue'],
          },
        ],
      },
    });
  }

  // getPageViews() {
  //   this.noResultReturned = true;
  //   var flowModel = new FlowQueryModel({
  //     userEmail: this.userData.emailAddress,
  //   });
  //   this.flowservice.GetAllPageViews(flowModel).subscribe((data) => {
  //     this.noResultReturned = false;
  //     // this.dashboardModel = data.DashboardModel; 
  //     this.pageViews = data;
  //     const uniqueEmails = new Set<string>();

  //     this.pageViews.forEach(element => {
  //       // Check if the email is already in the set
  //       if (element.userEmail != "" && uniqueEmails.has(element.userEmail)) {
  //         // User is a returning user
  //         this.returningUsersCount++;
  //       } else {
  //         // User is a new user
  //         // Add the email to the set
  //         uniqueEmails.add(element.userEmail);
  //         this.newUsersCount++;
  //       }
  //     });

  //     const ctx = this.pieChartCanvas.nativeElement.getContext('2d');

  //     const pieChart = new Chart(ctx, {
  //       type: 'pie',
  //       data: {
  //         labels: ['New Visitor', 'Returning Visitor'],
  //         datasets: [
  //           {
  //             data: [this.newUsersCount, this.returningUsersCount],
  //             backgroundColor: ['green', 'blue'],
  //           },
  //         ],
  //       },
  //     });

  //   });
  // }
}