import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PageTimeTrackerService {
  private currentPageStartTime: number;
  private totalTimeSpent: number = 0;
  private pageStartTime: number;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.currentPageStartTime) {
          const pageTime = new Date().getTime() - this.currentPageStartTime;
          this.totalTimeSpent += pageTime;
        }
        this.currentPageStartTime = new Date().getTime();
      }
    });
  }

  getPageTime(): number {
    if (this.pageStartTime) {
      return new Date().getTime() - this.pageStartTime;
    }
    return 0; // Page time is zero if not started or already tracked
  } 
  
  startTracking() {
    this.pageStartTime = new Date().getTime();
  }

  getTotalTimeSpent() {
    return this.totalTimeSpent;
  }
}
